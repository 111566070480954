import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolMonetariaTraslados } from "../../../../core/models/tareas/tareaSolMonetariaTrasladosModel";
import { tareaSolMonetariaTrasladosListService, tareaSolMonetariaTrasladosSaveService } from "../../../../core/services/tareas/tareaSolMonetariaTrasladosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad3: FC<IFormEntidad3> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaTraslados|null>(null);
const obtenerData = async () => {
    const data = await tareaSolMonetariaTrasladosListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tipoorigen: data?.tipoorigen || '',
      numeroorigen: data?.numeroorigen || '',
      tipodestino: data?.tipodestino || '',
      numerodestino: data?.numerodestino || '',
      valortraslado: data?.valortraslado || '',
      monedatraslado: data?.monedatraslado || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tipoorigen: Yup.string().required(FormValidation.required),
      numeroorigen: Yup.string(),
      tipodestino: Yup.string(),
      numerodestino: Yup.string(),
      valortraslado: Yup.string(),
      monedatraslado: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaTrasladosSaveService((values as ITareaSolMonetariaTraslados), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad3" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoorigen">{t('Tipo de origen')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoorigen"
                  name="tipoorigen"
                  invalid={errors.tipoorigen && touched.tipoorigen}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_ORIGEN).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroorigen">{t('Número (Cuenta / Encargo) Origen')}</Label>
                <Field
                  as={NumberField}
                  id="numeroorigen"
                  name="numeroorigen"
                  invalid={errors.numeroorigen && touched.numeroorigen}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipodestino">{t('Tipo de Destino')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipodestino"
                  name="tipodestino"
                  invalid={errors.tipodestino && touched.tipodestino}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_ORIGEN).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipodestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerodestino">{t('Número (Cuenta / Encargo) Destino')}</Label>
                <Field
                  as={NumberField}
                  id="numerodestino"
                  name="numerodestino"
                  invalid={errors.numerodestino && touched.numerodestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerodestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valortraslado">{t('Valor del traslado')}</Label>
                <Field
                  as={NumberField}
                  id="valortraslado"
                  name="valortraslado"
                  invalid={errors.valortraslado && touched.valortraslado}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valortraslado as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedatraslado">{t('Moneda del traslado')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedatraslado"
                  name="monedatraslado"
                  invalid={errors.monedatraslado && touched.monedatraslado}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedatraslado as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad3"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad3 {
  habilitar: boolean;
}

export default FormEntidad3;