import React, { FC, useState } from "react";
import {
  Row,
  Col,
  Collapse,
  Button,
} from 'reactstrap';
import TareasDashboard from './tareas/TareasDashboard';
import { useTranslation } from 'react-i18next';

const HomeComponent: FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <Row>
      <Col md={12}>
        {!isOpen && (
          <Row>
            <Col sm={8}>
              <img
                src="/static/mail/images/comforce.png"
                alt="Logo Comforce"
                className="mt-5"
              />
              <h3 className="text-primary mb-2 mt-2">{t('Sistema de Gestión de Contratos y Proveedores')}</h3>
              <p style={{ fontSize: '18px' }} className="mb-5 mt-5">
                {t('Apreciado Usuario, nuestra labor es agilizar sus procesos de gestión de contratos, dar total visibilidad y tranquilidad y ser una herramienta que genere valor.')}
              </p>
              <Button
                color="primary"
                onClick={toggle}
                className="mb-4"
                disabled={isOpen}
                block
                size="lg"
              >
                <i className="uil-sidebar uil-home-alt"></i>
                {t('Ver Dashboard')}
              </Button>
            </Col>
            <Col sm={4} style={{ textAlign: 'center' }}>
              <img
                src="/static/mail/images/mobile.png"
                alt="App Comforce"
                style={{ maxWidth: '70%' }}
              />
            </Col>
          </Row>
        )}
        <Collapse isOpen={isOpen} style={{ padding: 0 }}>
          {isOpen && (
            <TareasDashboard />
          )}
        </Collapse>
      </Col>
    </Row>
  )
};
export default HomeComponent;
