import React, { createContext, useReducer, FC, useState, useMemo } from 'react';
import mainReducer, { mainInitialState } from './reducers/mainReducer';
import { IMainContext, mainTypeMsj } from './interfaces/mainInterfaces';
import { Modal, ModalHeader } from 'reactstrap';
import { changeLanguage, closeModal } from './actions/mainActions';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserContext } from './userContext';
import { IListasItau } from '../../core/models/administracion/listasItauModels';
import { listasDesplegablesListService } from '../../core/services/administracion/listasItauService';
import { getTokenUrl } from '../utilities';
import { isUserAuthenticated } from '../helpers/authUtils';

const initialState: IMainContext = {
  mainState: mainInitialState(),
  mainDispatch: () => { },
  handleNotification: () => { },
  listasDesplegables: []
};
export const mainContext = createContext<IMainContext>(initialState);

export const MainProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userState } = useUserContext();
  const [state, dispatch] = useReducer(mainReducer, mainInitialState());
  const { i18n } = useTranslation();
  const { language } = state;
  const [listasDesplegables, setListasDesplegables] = useState<IListasItau[]>([]);

  /**
   * Permite mostrar un mensaje en la aplicación
   * @param msj mensaje que se quiere mostrar
   * @param type tipo de mensaje
   */
  const handleNotification = (
    msj: string,
    type: mainTypeMsj) => {
    enqueueSnackbar(msj, {
      variant: type,
    });
  };

  const obtenerListas = async () => {
    setListasDesplegables(await listasDesplegablesListService() || []);
  }

  const tokenurl = useMemo(() => getTokenUrl(), [window.location.pathname])

  useEffect(() => {
    if (userState?.data?.locale) {
      const language = userState?.data?.locale;
      dispatch(changeLanguage({
        language
      }))
    }
  }, [userState]);

  useEffect(() => {
    i18n.changeLanguage(language || 'es');
  }, [language]);

  const user = isUserAuthenticated(true);

  useEffect(() => {
    obtenerListas();
  }, [tokenurl, user?.data?.token]);

  return (
    <mainContext.Provider
      value={{
        mainState: state,
        mainDispatch: dispatch,
        handleNotification,
        listasDesplegables
      }}
    >
      {children}
    </mainContext.Provider>
  );
};


export const useMainContext = () => {
  const context = React.useContext(mainContext)
  if (context === undefined) {
    throw new Error('useMainContext must be used within a MainProvider')
  }
  return context
};

