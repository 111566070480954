import { Field, FormikProvider, useFormik } from 'formik';
import React, { FC, useState } from 'react'
import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { closeModal, falseLoading, trueLoading } from '../../../shared/contexts/actions/mainActions';
import { IRecuperarToken } from '../../../core/models/procesos/tareasModel';
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';
import ButtonCustom from '../../../shared/components/ButtonCustom';
import { recuperarSaveService } from '../../../core/services/usuarios/tokensService';

const FormRecuperar: FC<IFormRecuperar> = ({ type }) => {
  const [msj, setMsj] = useState<string|null>(null);

  const { mainDispatch, mainState, handleNotification } = useMainContext();
  const { t } = useTranslation();

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type,
      correoasociado: type === 1 ? '' : undefined,
      codigonegocio: type === 2 ? '' : undefined,
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      correoasociado: type === 1 ? Yup.string().required(FormValidation.required) : Yup.mixed(),
      codigonegocio:  type === 2 ? Yup.string().required(FormValidation.required) : Yup.mixed(),
    })),
    onSubmit: async (values: IRecuperarToken, { resetForm }) => {
      mainDispatch(trueLoading());
      const resp = await recuperarSaveService(values, handleNotification);
      if (resp.code === 200) {
        mainDispatch(closeModal());
      }
      mainDispatch(falseLoading());
    }
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    !!msj ?
      <Alert color="success" className="mb-0">{msj}</Alert>
    :
    <>
      <Form id="FormRecuperar" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            {type === 1 &&
              <Col sm={12} md={12} >
                <FormGroup>
                  <Label for="correoasociado">{t('Correo electrónico')}</Label>
                  <Field
                    as={Input}
                    name="correoasociado"
                    invalid={errors.correoasociado && touched.correoasociado}
                    disabled={false}
                  />
                  <FormFeedback>{t(errors.correoasociado as string)}</FormFeedback>
                </FormGroup>
              </Col>
            }
            {type === 2 &&
              <Col sm={12} md={12}>
                <FormGroup>
                  <Label for="codigonegocio">{t('Código del negocio')}</Label>
                  <Field
                    as={Input}
                    name="codigonegocio"
                    invalid={errors.codigonegocio && touched.codigonegocio}
                    disabled={false}
                  />
                  <FormFeedback>{t(errors.codigonegocio as string)}</FormFeedback>
                </FormGroup>
              </Col>
            }
          </Row>
          <hr />
          <ButtonCustom
            form="FormRecuperar"
            type="submit"
            color="primary"
            className="float-right"
            loading={mainState.loading}
            disabled={mainState.loading}
          >
            {t('Continuar')}
          </ButtonCustom>
        </FormikProvider>
      </Form>
    </>
  )
}

interface IFormRecuperar {
  type: number;
}

export default FormRecuperar