import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaCertificadosSaldos } from "../../../../core/models/tareas/tareaSolNoMonetariaCertificadosSaldosModel";
import { tareaSolNoMonetariaCertificadosSaldosListService, tareaSolNoMonetariaCertificadosSaldosSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaCertificadosSaldosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { formatStringtoDateString } from '../../../../shared/helpers/Fecha';

const FormEntidad13: FC<IFormEntidad13> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaCertificadosSaldos|null>(null);
const obtenerData = async () => {
    const data = await tareaSolNoMonetariaCertificadosSaldosListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tipocertificado: data?.tipocertificado || '',
      generarcertificadocorte:  data?.generarcertificadocorte ? formatStringtoDateString(data.generarcertificadocorte) : '',
      dirigido: data?.dirigido || '',
      numerocuentaoencargo: data?.numerocuentaoencargo || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tipocertificado: Yup.string().required(FormValidation.required),
      generarcertificadocorte: Yup.string(),
      dirigido: Yup.string(),
      numerocuentaoencargo: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaCertificadosSaldosSaveService((values as ITareaSolNoMonetariaCertificadosSaldos), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad13" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocertificado">{t('Tipo de certificado')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocertificado"
                  name="tipocertificado"
                  invalid={errors.tipocertificado && touched.tipocertificado}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_CERTIFICADO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocertificado as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="generarcertificadocorte">{t('Generar certificado con corte a')}</Label>
                <Field
                  as={DateField}
                  id="generarcertificadocorte"
                  name="generarcertificadocorte"
                  invalid={errors.generarcertificadocorte && touched.generarcertificadocorte}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.generarcertificadocorte as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="dirigido">{t('A quien va dirigido')}</Label>
                <Field
                  as={Input}
                  id="dirigido"
                  name="dirigido"
                  invalid={errors.dirigido && touched.dirigido}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.dirigido as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentaoencargo">{t('Número de cuenta y/o Encargo')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentaoencargo"
                  name="numerocuentaoencargo"
                  invalid={errors.numerocuentaoencargo && touched.numerocuentaoencargo}
                  disabled={!habilitar}
                  type="textarea"
                />
                <FormFeedback>{t(errors.numerocuentaoencargo as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad13"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad13 {
  habilitar: boolean;
}

export default FormEntidad13;