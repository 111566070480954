import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { updateItem, getRequest, postRequest, deleteItem } from "../peticionesService";
import { IFideicominentesBeneficiarios } from '../../models/postcontractual/fideicomitentesModel';



/**guardar o crear fideicomitentes */
export const handleSaveCreateFideicomitentesService = async (
    values: IFideicominentesBeneficiarios,
    notification: (
        msj: string,
        type: mainTypeMsj
    ) => void,
    file = false,
    sendNotification = true
): Promise<IFideicominentesBeneficiarios> => {
    const url = (values?.id) ? `${urlApi.EDITAR_FIDEICOMITENTE}${values.id}` : urlApi.CREATE_FIDEICOMITENTE;
    const resp = await updateItem(url, values, notification, file, sendNotification, true);
    if (resp?.data?.records) return resp?.data?.records;
    else return resp;
}

/**
 * Permite obtener el registro por id de fideicomitente en la bd
 */
export const showFideicomitenteService = async (id: number): Promise<IFideicominentesBeneficiarios | null> => {
    let data: IFideicominentesBeneficiarios | null;
    try {
        const resp = await getRequest(`${urlApi.SHOW_FIDEICOMITENTES}${id}`);
        data = resp.data.records;
        console.log(resp)
    } catch (error) {
        console.error(error);
        data = null;
    }
    return data;
};

/**listar fidecomitentes */
export const handleListFideiComitentes = async () => {
    let data: IFideicominentesBeneficiarios[] = [];
    try {
        const resp = await getRequest(urlApi.LISTAR_FIDEICOMITENTES);
        data = resp.data.records;
    } catch (error) {
        console.error(error);
    }
    return data;
}
/**listar fideicomitentes por d de proceso proceso */
export const handleListFideiComitentesByProceso = async (idpostcontractual: number | string) => {
    const params = { _busqueda: { idpostcontractual } }
    let data: IFideicominentesBeneficiarios[] = [];
    try {
        const resp = await postRequest(urlApi.LISTAR_FIDEICOMITENTES, params, true);
        data = resp.data.records;
    } catch (error) {
        console.error(error);
    }
    return data;
}

/**
 * Permite borrar un registro
 */
export const deleteFideicomitenteService = async (
    id: number,
    notification: (
        msj: string,
        type: mainTypeMsj
    ) => void
): Promise<boolean> => {
    const url = `${urlApi.DELETE_FIDEICOMITENTES}${id}`;
    return await deleteItem(url, notification);
};