import jwtDecode from 'jwt-decode';
import { routes, IRoutes } from '../../router/routes';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = (info?: boolean) => {
  const user = getLoggedInUser();
  if (!user || !user?.data) {
    return (info) ? {
      isAutenticated: false,
      data: null
    } : false;
  }
  const decoded: any = jwtDecode(user?.data?.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return (info) ? {
      isAutenticated: false,
      data: null
    } : false;
  } else {
    return (info) ? user : true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = localStorage.getItem('usuario');
  return user ? (JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser };

export interface INewArrRoutes {
  path: string | undefined,
  grupos: string[] | undefined
}
/**
 * esta funcion permite obtener todas las tutas
 * @returns objeto de tipo: {path:'la ruta', grupos: array de grupos para dicha ruta}
 */
const getRoutes = () => {
  let allRoutesNew: any[] = [];

  routes?.map((sub) => {
    let deep1 = { path: sub?.path, grupos: sub?.grupos };

    let deep2 = sub?.children?.map(sub2 => ({ path: sub2?.path, grupos: sub2?.grupos }));
    let deep3 = sub?.children?.map(sub3 => sub3?.children?.map((sub3 => ({ path: sub3?.path, grupos: sub3?.grupos }))));
    let deep4 = sub?.children?.map(sub4 => sub4?.children?.map((sub4 => sub4?.children?.map((sub4 => ({ path: sub4?.path, grupos: sub4?.grupos }))))));
    let deep5 = sub?.children?.map(sub5 => sub5?.children?.map((sub5 => sub5?.children?.map((sub5 => sub5?.children?.map(sub5 => ({ path: sub5?.path, grupos: sub5?.grupos })))))));
    allRoutesNew.push(deep1, deep2, deep3, deep4, deep5)
  })
  return allRoutesNew?.flat(10)?.filter(el => !!el);
}


/**
 * Devuelve true si el usuario tiene acceso a la página, y false si el usuario no tiene acceso a la página;
 * @param {any} params - any, listRolesUsuarioConectado: string[], history: any
 * @param {string[]} listRolesUsuarioConectado - string[] = ['admin', 'user']
 * @param {any} history - any, este es el objeto history de react-router-dom
 * @returns boolean.
 */
export const accesoUsuarioAPagina = (params: any, listRolesUsuarioConectado: string[], history: any) => {
  /** ruta actual segun localizacion */
  const rutaPathActual = (params && params?.id) ? history?.location?.pathname?.replace(params?.id, ':id') : history?.location?.pathname;
  const rolesAutorizados: string[] = getRoutes()?.find(el => el?.path === rutaPathActual)?.grupos;
  const rolesUnificados = !!listRolesUsuarioConectado?.length ? [...rolesAutorizados, ...listRolesUsuarioConectado] : [];
  
  return new Set(rolesUnificados).size !== rolesUnificados?.length;
}

