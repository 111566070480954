import React, { FC } from 'react';
// Contextos
import { userLogin } from '../../../shared/contexts';
//FORM
import { FormValidation } from '../../../shared/utilities';
import * as Yup from 'yup';
// Componentes
import SignInComponent from './SignInComponent';
import { postRequest } from '../../../core/services/peticionesService';
import urlApi from '../../../core/api';
import { changeLoading, closeModal } from '../../../shared/contexts/actions/mainActions';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { Redirect, useHistory } from 'react-router-dom';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { useUserContext } from '../../../shared/contexts/userContext';
import { useTranslation } from 'react-i18next';

// Esquema del formulario
export const FormSchema = Yup.object().shape({
  email: Yup.string()
    .required(FormValidation.required),
  password: Yup.string()
    .required(FormValidation.required),
});

// Estado inicial
export const initialState: ILogin = {
  email: '',
  password: '',
  getToken: true,
};

const SignIn: FC = () => {
  // Contextos
  const { userDispatch, userState: { isAutenticated } } = useUserContext();
  const { mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const history = useHistory();

  if (isAutenticated) {
    return <Redirect to='/home' />;
  }

  /**
   * Permite enviar el formulario cuando es valido
   * @param values datos del formulario
   */
  const onSubmit = async (values: ILogin) => {
    mainDispatch(changeLoading());
    const info: ILogin = {
      ...values,
      getToken: false
    };

    try {
      var url = new URL(window.location.href);
      var from = url.searchParams.get("from");
      const [{ data }, { data: dataInfo }] = await Promise.all([postRequest(urlApi.LOGIN, values, false), postRequest(urlApi.LOGIN, info, false)]);
      mainDispatch(changeLoading());
      if (!data.code) {
        userDispatch(userLogin({
          ...dataInfo,
          token: data
        }));
        if (from) window.location.href = from;
      } else {
        console.warn(data.message);
        handleNotification(data.message, mainTypeMsj.ERROR);
      }
    } catch (error) {
      mainDispatch(changeLoading());
      console.error(error);
      handleNotification(t('Error intentando ingresar, intente más tarde'), mainTypeMsj.ERROR);
    }
  };

  /**
   * Permite enviar el formulario para recuperación de contraseña
   * @param values datos del formulario
   */
  const onSubmitRecuperarContrasena = async (values: any) => {
    mainDispatch(changeLoading());
    // console.log('Estos son los valores', values);
    const resp = await postRequest(urlApi.RECOVERY_PASSWORD, values, false);
    if (resp.data.code === 200) {
      handleNotification(resp.data.message, mainTypeMsj.SUCCESS);
    }
    mainDispatch(closeModal());
  };

  return (
    <SignInComponent
      onSubmit={onSubmit}
      onRecovery={onSubmitRecuperarContrasena}
    />
  );
}
export interface ILogin {
  email: string;
  password: string;
  getToken: boolean;
};
export default SignIn;
