import React, { FC } from 'react'
import { Card, CardTitle } from 'reactstrap';
import logoBig from '../../../assets/img/logos/itau_logo.png'
import { useHistory } from 'react-router-dom';
import ButtonCustom from '../ButtonCustom';
import { useTranslation } from 'react-i18next';

const Page_403: FC = () => {

    const { push } = useHistory();
    const { t } = useTranslation(); 


    return (
        <>
            <Card style={{ padding: "1rem" }}>
                <div className='container_pageError'>
                    <img src={logoBig} alt="comforce" style={{ marginBottom: '2rem', width: '60%' }} />
                    <div className="lock"></div>
                    <CardTitle style={{ fontWeight: 'bold', fontSize: '3rem', color: '#333' }}>403</CardTitle>
                    <div className="message">
                        <h1 className='tittleAcces'>{t('El acceso a esta página es restringido')}</h1>
                        <p className='text_message'>{t('Por favor consulte con el administrador del sitio si cree que se trata de un error')}...</p>
                    </div>
                    <ButtonCustom
                        onClick={() => {
                            push('/login')
                        }}
                    >{t('Regresar')}</ButtonCustom>
                </div>
            </Card>
        </>
    )
}


export default Page_403