import React, { useState, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { NavLink, useHistory } from "react-router-dom";
import classNames from "classnames";
import logo from '../../assets/img/logos/logo-blanco.png';
import logoItau from "../../assets/img/logos/itau_logo.png"
import { Collapse } from "reactstrap";
// Rutas para el aside
import { dashboardRoutes as routes, IRoutes } from '../../router/routes';
import { useTranslation } from "react-i18next";
import { useUserContext } from "../contexts/userContext";

const pathPostcontractual = require('../../assets/manuales/ADM-ITAU-Manual-Modulo-Postcontractual-05-01.pdf');
const pathTareasCliente = require('../../assets/manuales/ADM-ITAU-Manual-Modulo-Tareas-Cliente-05-01.pdf');
const pathTareasPredeterminadas = require('../../assets/manuales/ADM-ITAU-Manual-Modulo-Tareas-Predeterminadas-05-01.pdf');


const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;
  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    _routes = {
      ..._routes,
      [index]: isActive
    };
  });

  return _routes;
};

const initOpenRoutesChildren = (location, children) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;
  let _routes = {};
  children.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    _routes = {
      ..._routes,
      [index]: isActive
    };
  });

  return _routes;
};

const SidebarCategory: FC<ISidebaCategory> = ({
  to,
  name,
  icon,
  isOpen,
  onClick,
  route,
  first = false,
}) => {

  // Contextos
  const history = useHistory();
  const { t } = useTranslation();
  const { userState } = useUserContext();
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutesChildren(history.location, route?.children || []));

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [item]: false }))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  return (
    <li className={"sidebar-item "}>
      <span
        data-toggle="collapse"
        className={"sidebar-link " + (!isOpen ? "collapsed " : " ") + (first ? "first" : "")}
        onClick={onClick}
        aria-expanded={isOpen ? "true" : "false"}
      >
        {icon ? (
          <>
            <i className={`uil-sidebar ${icon}`}></i>
            <span className="align-middle">{t(name)}</span>
          </>
        ) : (
          <span className="align-middle">{t(name)}</span>
        )}
      </span>
      <Collapse isOpen={isOpen}>
        <ul className={"sidebar-dropdown list-unstyled"}>
          {
            route.children?.map((rout, index) => {
              const viewRoute = rout.grupos?.find(grupo => grupo === userState.data?.roles.find(rol => rol === grupo));
              return (
                viewRoute && (
                  <React.Fragment key={uuidv4()}>
                    {
                      rout.children ? (
                        <SidebarCategory
                          name={rout.name}
                          to={rout.path ? `${rout.path}` : ''}
                          isOpen={openRoutes[index]}
                          onClick={() => toggle(index)}
                          route={rout}
                        />
                      ) : (
                        <SidebarItem
                          key={uuidv4()}
                          name={rout.name}
                          to={`${rout.path}`}
                        />
                      )
                    }
                  </React.Fragment>
                )
              );
            })
          }
        </ul>
      </Collapse>
    </li>
  );
};
interface ISidebaCategory {
  name: string;
  to: string;
  route: IRoutes;
  icon?: string | undefined;
  isOpen?: boolean;
  onClick?: () => void;
  first?: boolean;
}

const SidebarItem: FC<ISidebarItem> = ({ name, icon, to }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const getSidebarItemClass = (path: string) => {
    return history.location.pathname === path ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <NavLink
        to={to}
        className="sidebar-link"
        activeClassName="active"
      >
        {
          icon ?
            (
              <React.Fragment>
                <i className={`uil-sidebar ${icon}`}></i>
                <span className="align-middle">{t(name)}</span>
              </React.Fragment>
            ) : (name)
        }
      </NavLink>
    </li>
  );
};
interface ISidebarItem {
  name: string;
  to: string;
  icon?: string | undefined;
};

const Sidebar: FC<ISidebar> = ({ isOpen }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(history.location));
  const { userState } = useUserContext();
  const { data } = userState;

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [item]: false }))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const cambioContrasena = data?.cambiocontrasena && data?.cambiocontrasena == 1;
  return (
    <nav
      className={classNames(
        "sidebar",
        isOpen || "toggled"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand text-center my-2"
          )}
          href="#"
        >
          <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:'-20px'}}>
            <img
              src={logoItau}
              alt="Comforce"
              className="img-fluid"
              style={{
                height: '100%',
                width: 80
              }}
            />
            <img
              src={logo}
              className="img-fluid"
              alt="Comforce"
              width="150"
            />
          </div>
        </a>
        <ul className="sidebar-nav">
          {routes.map((route, index) => {
            const viewRoute = route?.grupos?.find(grupo => grupo === userState.data?.roles.find(rol => rol === grupo));
            return (
              viewRoute && <React.Fragment key={uuidv4()}>
                {route.header && (
                  <li className="sidebar-header">{t(route.header)}</li>
                )}
                {route.children ? (
                  <SidebarCategory
                    name={route.name}
                    icon={route.icon}
                    to={route.path ? route.path : ''}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                    route={route}
                    first
                  />
                ) : (
                  <SidebarItem
                    name={route.name}
                    to={route.path ? route.path : ''}
                    icon={route.icon}
                  />
                )}
              </React.Fragment>
            );
          })}
          <li className="sidebar-header">Manuales</li>
              <li className={"sidebar-item "}>
                <a
                  target="_blank"
                  href={pathPostcontractual}
                  className="sidebar-link"
                >
                  <i className={`uil-sidebar uil-file-question-alt`}></i>
                  <span className="align-middle">PostContractual</span>
                </a>
              </li>
              <li className={"sidebar-item "}>
                <a
                  target="_blank"
                  href={pathTareasPredeterminadas}
                  className="sidebar-link"
                >
                  <i className={`uil-sidebar uil-file-question-alt`}></i>
                  <span className="align-middle">TareasPredeterminadas</span>
                </a>
              </li>
              <li className={"sidebar-item "}>
                <a
                  target="_blank"
                  href={pathTareasCliente}
                  className="sidebar-link"
                >
                  <i className={`uil-sidebar uil-file-question-alt`}></i> 
                  <span className="align-middle">TareasCliente</span>
                </a>
              </li>
        </ul>
      </div>
    </nav>
  );
};
interface ISidebar {
  isOpen: boolean;
};
export default Sidebar;
