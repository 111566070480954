import React, { FC, useMemo } from 'react';
import { ITareas } from '../../../core/models/procesos/tareasModel';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { useOptionsTables } from '../../../shared/hooks/useOptionsTables';
import { IPostcontractual } from '../../../core/models/postcontractual/postcontractualModel';
import { useAllListas } from '../../../shared/hooks/useAllListas';
import { formatStringtoDateString } from '../../../shared/helpers/Fecha';

const PendientesNegocios: FC<IPendientesNegocios> = ({ records, onChangePage, onSearchChange, total }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { optionsDataTable } = useOptionsTables();
  const { obtenerLabel } = useAllListas();

  const handleEdit = (id) => {
    history.push(`/postcontractual/edit/${id}`);
  }

  const columns: MUIDataTableColumn[] = [
    {
      name: t('Código del contrato'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Código del contrato')}</h5>
        ),
      }
    },
    {
      name: t('Tipo de fiducia'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Tipo de fiducia')}</h5>
        ),
      }
    },
    {
      name: t('Subtipo de fiducia'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Subtipo de fiducia')}</h5>
        ),
      }
    },
    {
      name: t('Fecha inicio fideicomiso'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha inicio fideicomiso')}</h5>
        ),
      }
    },
    {
      name: t('Fecha fin fideicomiso'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Fecha fin fideicomiso')}</h5>
        ),
      }
    },
    {
      name: t('Estado flujo'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado flujo')}</h5>
        ),
      }
    },
    {
      name: t('Link de acceso'),
      options: {
        filter: false,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Link de acceso')}</h5>
        ),
        customBodyRender: (id) => {
          return (
            <>
              <Button
                color="primary"
                className="mr-1 mb-1 btn-sm"
                onClick={() => handleEdit(id)}
              >
                <i className="uil-eye"></i>
              </Button>
            </>
          );
        }
      }
    },
  ];

  return (
    <>
      <MUIDataTable
        title={(<h5>{t('Pendientes negocios')}</h5>)}
        data={records.map(record => ([
          record.codigocontrato,
          obtenerLabel(record.tipofiducia || ''),
          obtenerLabel(record.subtipofiducia || ''),
          formatStringtoDateString(record.iniciofideicomiso),
          formatStringtoDateString(record.finfideicomiso),
          record.idestados?.nombre,
          record.id,
        ]))}
        columns={columns}
        options={optionsDataTable({
          sort: false,
          filter: false,
          search: true,
          serverSide: true,
          onChangePage,
          onSearchChange,
          count: total,
          rowsPerPage: 10,
          rowsPerPageOptions: [],
        })}
      />
    </>
  )
}

interface IPendientesNegocios{
  records: IPostcontractual[];
  onChangePage: (page: number) => void;
  onSearchChange: (searchText: string) => void;
  total: number;
}

export default PendientesNegocios;
