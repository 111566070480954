import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { IFideicominentesBeneficiarios } from "../../../../core/models/postcontractual/fideicomitentesModel";
import { IPostcontractual } from "../../../../core/models/postcontractual/postcontractualModel";
import { ITareas } from "../../../../core/models/procesos/tareasModel";
import { IUsuarios } from "../../../../core/models/usuarios/usuariosModel";
import { handleListFideiComitentesByProceso } from "../../../../core/services/postcontractual/fideicomitentesService";
import { obtenerUsuarios } from "../../../../core/services/usuarios/usuariosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { changeHeader, falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { mainTypeHeader } from "../../../../shared/contexts/interfaces/mainInterfaces";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { usePostcontractualContext } from "../../../../shared/contexts/postcontractualContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useUserContext } from "../../../../shared/contexts/userContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { EListaDesplegables } from '../../../../core/models/administracion/listasItauModels';
import { useHistory } from "react-router-dom";
import { formatStringtoDateString } from '../../../../shared/helpers/Fecha';
import { SyntheticInputEvent } from 'react-number-format';
import BooleanField from '../../../../shared/components/BooleanField';
import MultiSelectField from '../../../../shared/components/MultiSelectField';
import { IFirmasAutorizadas } from '../../../../core/models/postcontractual/firmasAutorizadasModel';
import { handleListFirmasAutorizadasByProceso } from '../../../../core/services/postcontractual/firmasAutorizadasService';

const FormTareasPredeterminadas: FC<IFormTareasPredeterminadas> = ({ fideicomitente, inComforceView = true }) => {
  // Context
  const { tarea, guardarTarea, obtenerTarea, cambiarEstado } = useTareasContext();
  const { mainState, mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista, obtenerSublista } = useAllListas();
  const { postcontractualState } = usePostcontractualContext();
  const { userState } = useUserContext();
  const { proceso, usuariosProceso } = postcontractualState || {};
  // hooks
  const history = useHistory();

  // State
  const [usuarios, setUsuarios] = useState<IUsuarios[]>([]);
  const [fideicomitentes, setFideicomitentes] = useState<IFideicominentesBeneficiarios[]>([]);
  const [firmasAutorizadas, setFirmasAutorizadas] = useState<IFirmasAutorizadas[]>([]);

  const habilitar = false;

  /**
   * Obtiene los terceros guardados en la bd
   */
  const listarUsuarios = async () => {
    if (userState.isAutenticated) {
      setUsuarios(await obtenerUsuarios());
      setFideicomitentes(await handleListFideiComitentesByProceso(proceso?.id || 0));
    }
  };

  const listarFirmasAutorizadas = async () => {
    const id = (fideicomitente?.idpostcontractual as IPostcontractual)?.id || proceso?.id;
    const data = await handleListFirmasAutorizadasByProceso(id || 0);
    setFirmasAutorizadas(data);
  }

  const handleCambiarEstado = async () => {
    mainDispatch(trueLoading());
    await cambiarEstado(tarea?.id || 0);
    await obtenerTarea(tarea?.id || 0);
    mainDispatch(falseLoading());
  }

  useEffect(() => {
    listarUsuarios()
    listarFirmasAutorizadas()
  }, []);

  useEffect(() => {
    if (!proceso?.id) {
      if (tarea) {
        mainDispatch(changeHeader({
          header: {
            title:`${t('Solicitud')} #${tarea.id} - ${tarea?.idestados?.nombre}`,
            subtitle: [],
            type: mainTypeHeader.BREADCRUMB
          }
        }));
      } else {
        mainDispatch(changeHeader({
          header: {
            title: t(`Realizar solicitud`),
            subtitle: [],
            type: mainTypeHeader.BREADCRUMB
          }
        }));
      }
    }
  }, [tarea, proceso]);

  // Formik
const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: tarea?.id || undefined,
      nombre: tarea?.nombre || '',
      clasificacionoperativa: tarea?.clasificacionoperativa || '',
      clasificacionnivelriesgo: tarea?.clasificacionnivelriesgo || '',
      periodicidad: tarea?.periodicidad || '',
      descripcion: tarea?.descripcion || '',
    },
    validationSchema: Yup.lazy((values: ITareas): Yup.Lazy => Yup.object().shape({
      nombre: Yup.string(),
      clasificacionoperativa: Yup.string(),
      clasificacionnivelriesgo: Yup.string(),
      periodicidad: Yup.string(),
      descripcion: Yup.string().required(FormValidation.required),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await guardarTarea((values as ITareas));
      if (!!fideicomitente && !!data?.tokenedicionfideicomitentecreador) {
        const { id, tokenacceso } = data?.tokenedicionfideicomitentecreador;
        history.push(`/token/${id}/${tokenacceso}`);
        mainDispatch(falseLoading());
        return;
      }
      await obtenerTarea(data?.id || 0);
      await cambiarEstado(data?.id || 0);
      await obtenerTarea(data?.id || 0);
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      {tarea?.idestados?.codigofabrica === 'PreValidacionSolicitud' &&
        <Alert color="success">La información fue enviada correctamente. Puede cerrar esta ventana.</Alert>
      }
      <Form id="FormTareasPredeterminadas" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombre">{t('Nombre de la solicitud')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="nombre"
                  name="nombre"
                  invalid={errors.nombre && touched.nombre}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.NOMBRE_DE_TAREA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.nombre as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="clasificacionoperativa">{t('Clasificación operativa')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="clasificacionoperativa"
                  name="clasificacionoperativa"
                  invalid={errors.clasificacionoperativa && touched.clasificacionoperativa}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.CLASIFICACION_OPERATIVA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.clasificacionoperativa as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="clasificacionnivelriesgo">{t('Clasificación según nivel de riesgo')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="clasificacionnivelriesgo"
                  name="clasificacionnivelriesgo"
                  invalid={errors.clasificacionnivelriesgo && touched.clasificacionnivelriesgo}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.CLASIFICACION_SEGUN_NIVEL_RIESGO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.clasificacionnivelriesgo as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="periodicidad">{t('Periodicidad')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="periodicidad"
                  name="periodicidad"
                  invalid={errors.periodicidad && touched.periodicidad}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.PERIODICIDAD_2).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.periodicidad as string)}</FormFeedback>
              </FormGroup>
            </Col>            
            <Col sm={12} md={12}>
              <FormGroup>
                <Label for="descripcion">{t('Observaciones de la solicitud')}</Label>
                <Field
                  as={Input}
                  type="textarea"
                  id="descripcion"
                  name="descripcion"
                  invalid={errors.descripcion && touched.descripcion}
                  disabled={!habilitar || !!tarea}
                />
                <FormFeedback>{t(errors.descripcion as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                color="primary"
                className="float-right"
                type="button"
                onClick={handleCambiarEstado}
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Continuar')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormTareasPredeterminadas {
  fideicomitente?: IFideicominentesBeneficiarios;
  inComforceView?: boolean;
  habilitar: boolean;
}

export default FormTareasPredeterminadas;