import React, { FC, useEffect, useState, Suspense, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { IRoutes, routes as routesList } from './routes';
import { isUserAuthenticated } from '../shared/helpers/authUtils';
import { useUserContext } from '../shared/contexts/userContext';
import Auth from '../shared/layouts/Auth';
import SignIn from '../pages/auth/SignIn';
import { PrivateRoute } from './PrivateRoute';
import FirmaTercero from '../pages/firmas/FirmaTercero';
import Third from '../shared/layouts/Third';
import Token from '../pages/terceros/tokens';
import TareasClientes from '../pages/terceros/tareasClientes';
import TareasClientesFilter from '../pages/terceros/tareasClientesView';
import TareasClientesView from '../pages/terceros/tareasClientesView/View';
import SucuriTest from '../shared/SucuriTest';

const renderRoutes: FC<any> = ({ routes }) => {
  return (
    routes.map(({ layout: Layout, component: Component, ...route }) => (
      <React.Fragment key={uuidv4()}>
        {
          route?.children && route.children.length > 0 ? (
            renderRoutes({ routes: route?.children })
          ) : (
            <PrivateRoute
              path={route.path}
              exact
              component={() => (
                <Layout>
                  <Component />
                </Layout>
              )}
            />
          )
        }
      </React.Fragment>
    ))
  )
}

const Router: FC = () => {
  const [routes, setRoutes] = useState<IRoutes[]>([]);
  const { userState } = useUserContext();
  const { isAutenticated } = userState;
  const logged = useMemo(() => isUserAuthenticated(true), [isAutenticated]);

  useEffect(() => {
    setRoutes(routesList);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={
          <div className="splash active">
            <div className="splash-icon"></div>
          </div>
        }>
          <Route exact path="/" render={() => (
            <Redirect exact from="/" to={logged?.isAutenticated ? '/home' : '/login'} />
          )} />
          <Route
            path="/login"
            exact
            render={() => (
              <Auth>
                <SignIn />
              </Auth>
            )}
          />
          <Route
            path="/sucuri-test"
            exact
            render={() => (
              <SucuriTest />
            )}
          />
          {/* <Route
            path="/tercero/firma/:modulo/:token"
            exact
            render={() => (
              <Third>
                <FirmaTercero />
              </Third>
            )}
          /> */}
          <Route
            path="/token/:id/:tokenurl"
            exact
            render={() => (
              <Third>
                <Token />
              </Third>
            )}
          />
          <Route
            path="/tareas/clientes/create"
            exact
            render={() => (
              <Third>
                <TareasClientes />
              </Third>
            )}
          /> 
          <Route
            path="/tareas/clientes/list"
            exact
            render={() => (
              <Third>
                <TareasClientesFilter />
              </Third>
            )}
          /> 
          <Route
            path="/tareas/clientes/filter"
            exact
            render={() => (
              <Third>
                <TareasClientesView />
              </Third>
            )}
          />       
          {/* <Route
            path="/clientes/validacion/:token"
            exact
            render={() => (
              <Third>
                <>To Do ....</>
              </Third>
            )}
          /> */}
          {routes.map(({ layout: Layout, component: Component, ...route }) => {
            return (
              <React.Fragment key={uuidv4()}>
                {
                  route?.children && route.children.length > 0 ? (
                    renderRoutes({ routes: route?.children })
                  ) : (
                    <PrivateRoute
                      path={route.path}
                      exact
                      component={() => (
                        <Layout>
                          <Component />
                        </Layout>
                      )}
                    />
                  )
                }
              </React.Fragment>
            );
          })}
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
}
export default Router;
