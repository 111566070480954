import React from 'react';
import { ITareas } from '../../../../core/models/procesos/tareasModel';
import FormEntidad2 from './FormEntidad2';
import FormEntidad3 from './FormEntidad3';
import FormEntidad4 from './FormEntidad4';
import FormEntidad5 from './FormEntidad5';
import FormEntidad6 from './FormEntidad6';
import FormEntidad7 from './FormEntidad7';
import FormEntidad8 from './FormEntidad8';
import FormEntidad9 from './FormEntidad9';
import FormEntidad10 from './FormEntidad10';
import FormEntidad11 from './FormEntidad11';
import FormEntidad12 from './FormEntidad12';
import FormEntidad13 from './FormEntidad13';
import FormEntidad14 from './FormEntidad14';
import FormEntidad15 from './FormEntidad15';
import FormEntidad16 from './FormEntidad16';
import FormEntidad17 from './FormEntidad17';
import FormEntidad18 from './FormEntidad18';
import FormEntidad19 from './FormEntidad19';
import FormEntidad20 from './FormEntidad20';
import FormEntidad21 from './FormEntidad21';
import FormEntidad22 from './FormEntidad22';
import FormEntidad23 from './FormEntidad23';
import FormEntidad24 from './FormEntidad24';
import FormEntidad25 from './FormEntidad25';

const InfoAdicional = (tarea: ITareas | null, habilitar: boolean) => {
  let content: JSX.Element | null = null;
  let show = false;
  // MONETARIA
  if (tarea?.tiposolicitud === 'monetaria') {
    if (tarea?.subtiposolicitud === 'pagosaterceros' && tarea?.clasificacionsubtipologia === 'unicoregistro') {
      content = <FormEntidad2 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'traslados' && tarea?.clasificacionsubtipologia === 'unicoregistro') {
      content = <FormEntidad3 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'giros' && tarea?.clasificacionsubtipologia === 'unicoregistro') {
      content = <FormEntidad4 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'giros' && tarea?.clasificacionsubtipologia === 'masdeunregistroarchivoplano') {
      content = <FormEntidad5 habilitar={habilitar} />
      show = true
    }
    if ((tarea?.subtiposolicitud === 'giros' || tarea?.subtiposolicitud === 'pagosaterceros') && tarea?.clasificacionsubtipologia === 'masdeunregistroplanilla') {
      content = <FormEntidad6 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'restitucionaportes' && tarea?.clasificacionsubtipologia === 'unicoregistro') {
      content = <FormEntidad7 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'causacion' && tarea?.clasificacionsubtipologia === 'unicoregistro') {
      content = <FormEntidad8 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'aclaracion') {
      content = <FormEntidad9 habilitar={habilitar} />
      show = true
    }
  }
  // NO MONETARIA
  if (tarea?.tiposolicitud === 'nomonetaria') {
    if (tarea?.subtiposolicitud === 'certificados' && tarea?.clasificacionsubtipologia === 'tributario') {
      content = <FormEntidad10 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'certificados' && tarea?.clasificacionsubtipologia === 'degarantia') {
      content = <FormEntidad11 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'certificados' && [
      'delaexistenciadelnegocio',
      'defideicomitenteobeneficiario',
      'declausulasespeciales',
      'deventastotales',
      'deaportes',
      'debienes',
      'dederechosfiduciarios',
      'decoadyuvancias',
    ].includes(tarea?.clasificacionsubtipologia || '')) {
      content = <FormEntidad12 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'certificados' && tarea?.clasificacionsubtipologia === 'desaldos') {
      content = <FormEntidad13 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'estadosfinancieros') {
      content = <FormEntidad14 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'extracto') {
      content = <FormEntidad15 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'solicitudesagentescredgarantia') {
      content = <FormEntidad16 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'creaciontercero') {
      content = <FormEntidad17 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'poder') {
      content = <FormEntidad18 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'escrituraspublicas') {
      content = <FormEntidad19 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'cesionespreventa') {
      content = <FormEntidad20 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'promesacompraventa') {
      content = <FormEntidad21 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'agendamientoreunion') {
      content = <FormEntidad22 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'vinculacionproyectoinmobiliario') {
      content = <FormEntidad23 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'desistimientosproyectoinmobiliario') {
      content = <FormEntidad24 habilitar={habilitar} />
      show = true
    }
    if (tarea?.subtiposolicitud === 'soportes') {
      content = <FormEntidad25 habilitar={habilitar} />
      show = true
    }
  }
  return { content, show };
};

export default InfoAdicional;