import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { changeHeader } from '../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';
import { useMainContext } from '../../../shared/contexts/mainContext';
import FormTareasClientesFilter from './FormTareasClientesFilter';
import i18n from '../../../i18n';


const TareasClientesFilter: FC = (props) => {
  // Context
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Vista de solicitudes'),
        subtitle: [],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, [language])

  return (
    <Card style={{margin: 'auto', maxWidth: '100%', 'width': 500}}>
      <CardBody>
        <FormTareasClientesFilter/>
      </CardBody>
    </Card>
  )
}

export default TareasClientesFilter;