import { IUsuarios, IUsuariosData } from '../../core/models/usuarios/usuariosModel';
import { IComisionFiduciaria } from '../../core/models/postcontractual/comisionFiduciariaModel';
import { IItemsLista } from '../../core/models/administracion/listasItauModels';
import { IProductsAperturar } from '../../core/models/postcontractual/productosAperturarModel';
import dev from '../../core/enviroments';
import urlApi from '../../core/api';
import {fileRequest} from "../../core/services/peticionesService";
export const downloadFile = async (url: string, name: string) => {
  try {
    const respuesta = await fileRequest(`${urlApi.ARCHIVO_PROXY}?url=${url}`);
    const href = URL.createObjectURL(respuesta.data);

    // Obtener el MIME type del archivo
    const mimeType = respuesta.data.type;

    // Determinar la extensión del archivo basada en el MIME type
    const extensionMap = {
      'application/pdf': 'pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/msword': 'doc',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/webp': 'webp',
      'image/gif': 'gif',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'text/plain': 'txt',
      // Agrega más MIME types según sea necesario
    };

    const extension = extensionMap[mimeType] || 'bin'; // Usa 'bin' como predeterminado si no se encuentra el MIME type
    const fileName = `${name}.${extension}`;

    // Crear un elemento <a> y simular un clic para descargar el archivo
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); // Nombre del archivo
    document.body.appendChild(link);
    link.click();

    // Limpiar
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  }
};

export const deleteAddeds = (dataArray1: IItemsLista[], dataArray2: IComisionFiduciaria[] | IProductsAperturar[]) => {
  const list: IItemsLista[] = []
  for (let i = 0; i < dataArray1.length; i++) {
    let igual = false;
    for (let j = 0; j < dataArray2.length && !igual; j++) {
      if (dataArray1[i]?.itemlista == dataArray2[j]?.tipo)
        igual = true;
    }
    if (!igual) list.push(dataArray1[i]);
  }  
  return list
}
export const getNamesUsuario = (usuariosList: IUsuariosData[], idUsuario: number) => {
  const userData = usuariosList?.find((user) => user?.idusuarios?.id === idUsuario);
  const usuarioLocal = localStorage.getItem('usuario');
  const userLocalname = (usuarioLocal) && `${JSON.parse(usuarioLocal).data.firstname} ${JSON.parse(usuarioLocal).data.lastname}`;
  if (userData) return `${userData?.idusuarios?.nombres || ""} ${userData?.idusuarios?.apellidos}`
  else return userLocalname
}

/**
 * setear valor de values custom select option
 * Object.fromEntries(Object.entries(values)?.map(([key, val]) => [key, (val as object as any)?.value || val]))
 */
interface ISubs {
  id: number,
  subtarea: string
}

export const getFileUrl = (path: string) => {
  return `${path}`;
  //return `${dev.URL_SERVER}${urlApi.ARCHIVO_PROXY}?url=${path}`;
}
