import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { ITareaSolMonetariaPagoTerceros } from "../../../../core/models/tareas/tareaSolMonetariaPagoTercerosModel";
import { tareaSolMonetariaPagoTercerosListService, tareaSolMonetariaPagoTercerosSaveService } from "../../../../core/services/tareas/tareaSolMonetariaPagoTercerosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { EListaDesplegables } from '../../../../core/models/administracion/listasItauModels';

const FormEntidad2: FC<IFormEntidad2> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaPagoTerceros|null>(null);

  const obtenerData = async () => {
    const data = await tareaSolMonetariaPagoTercerosListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id:                       data?.id || undefined,
      tarea:                    tarea?.id || 0,
      nombreproveedor:          data?.nombreproveedor || '',
      tipoidproveedor:          data?.tipoidproveedor || '',
      numeroidproveedor:        data?.numeroidproveedor || '',
      tipodocumentopagar:       data?.tipodocumentopagar || '',
      numerodocumentopagar:     data?.numerodocumentopagar || '',
      valordocumentopagar:      data?.valordocumentopagar || '',
      monedadocumentopagar:     data?.monedadocumentopagar || '',
      conceptodocumentopagar:   data?.conceptodocumentopagar || '',
      mediopago:                data?.mediopago || '',
      tipocuentadestino:        data?.tipocuentadestino || '',
      entidadfinancieradestino: data?.entidadfinancieradestino || '',
      numerocuentadestino:      data?.numerocuentadestino || '',
      numerocuentacontable:     data?.numerocuentacontable || '',
      tipoorigen:               data?.tipoorigen || '',
      numeroorigen:             data?.numeroorigen || '',

    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      nombreproveedor: Yup.string().required(FormValidation.required),
      tipoidproveedor: Yup.string(),
      numeroidproveedor: Yup.string(),
      tipodocumentopagar: Yup.string(),
      numerodocumentopagar: Yup.string(),
      valordocumentopagar: Yup.string(),
      monedadocumentopagar: Yup.string(),
      conceptodocumentopagar: Yup.string(),
      mediopago: Yup.string(),
      tipocuentadestino: Yup.string(),
      entidadfinancieradestino: Yup.string(),
      numerocuentadestino: Yup.string(),
      numerocuentacontable: Yup.string(),
      tipoorigen: Yup.string(),
      numeroorigen: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaPagoTercerosSaveService((values as ITareaSolMonetariaPagoTerceros), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad2" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombreproveedor">{t('Nombre del proveedor')}</Label>
                <Field
                  as={Input}
                  id="nombreproveedor"
                  name="nombreproveedor"
                  invalid={errors.nombreproveedor && touched.nombreproveedor}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombreproveedor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidproveedor">{t('Tipo identificación del proveedor')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidproveedor"
                  name="tipoidproveedor"
                  invalid={errors.tipoidproveedor && touched.tipoidproveedor}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidproveedor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidproveedor">{t('Número identificación del proveedor')}</Label>
                <Field
                  as={Input}
                  id="numeroidproveedor"
                  name="numeroidproveedor"
                  invalid={errors.numeroidproveedor && touched.numeroidproveedor}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidproveedor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipodocumentopagar">{t('Tipo de documento a pagar')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipodocumentopagar"
                  name="tipodocumentopagar"
                  invalid={errors.tipodocumentopagar && touched.tipodocumentopagar}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DOCUMENTO_A_PAGAR).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipodocumentopagar as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerodocumentopagar">{t('Número de documento a pagar')}</Label>
                <Field
                  as={NumberField}
                  id="numerodocumentopagar"
                  name="numerodocumentopagar"
                  invalid={errors.numerodocumentopagar && touched.numerodocumentopagar}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerodocumentopagar as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valordocumentopagar">{t('Valor de documento a pagar')}</Label>
                <Field
                  as={NumberField}
                  id="valordocumentopagar"
                  name="valordocumentopagar"
                  invalid={errors.valordocumentopagar && touched.valordocumentopagar}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valordocumentopagar as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedadocumentopagar">{t('Moneda de documento a pagar')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedadocumentopagar"
                  name="monedadocumentopagar"
                  invalid={errors.monedadocumentopagar && touched.monedadocumentopagar}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedadocumentopagar as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="conceptodocumentopagar">{t('Concepto del documento a pagar')}</Label>
                <Field
                  as={Input}
                  id="conceptodocumentopagar"
                  name="conceptodocumentopagar"
                  invalid={errors.conceptodocumentopagar && touched.conceptodocumentopagar}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.conceptodocumentopagar as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="mediopago">{t('Medio de pago')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="mediopago"
                  name="mediopago"
                  invalid={errors.mediopago && touched.mediopago}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.MEDIO_DE_PAGO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.mediopago as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocuentadestino">{t('Tipo de cuenta destino')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocuentadestino"
                  name="tipocuentadestino"
                  invalid={errors.tipocuentadestino && touched.tipocuentadestino}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_CUENTA_DESTINO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="entidadfinancieradestino">{t('Entidad financiera destino')}</Label>
                <Field
                  as={Input}
                  id="entidadfinancieradestino"
                  name="entidadfinancieradestino"
                  invalid={errors.entidadfinancieradestino && touched.entidadfinancieradestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.entidadfinancieradestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentadestino">{t('Número de cuenta destino')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentadestino"
                  name="numerocuentadestino"
                  invalid={errors.numerocuentadestino && touched.numerocuentadestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentadestino">{t('Número de cuenta contable')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentacontable"
                  name="numerocuentacontable"
                  invalid={errors.numerocuentacontable && touched.numerocuentacontable}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerocuentacontable as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoorigen">{t('Tipo de origen')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoorigen"
                  name="tipoorigen"
                  invalid={errors.tipoorigen && touched.tipoorigen}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_ORIGEN).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroorigen">{t('Número (Cuenta / Encargo) Origen')}</Label>
                <Field
                  as={NumberField}
                  id="numeroorigen"
                  name="numeroorigen"
                  invalid={errors.numeroorigen && touched.numeroorigen}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad2"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad2 {
  habilitar: boolean;
}

export default FormEntidad2;