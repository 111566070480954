import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { ICargueMasivoContext } from "./interfaces/cargueMasivoInterface";
import cargueMasivoReducer, { cargueMasivoInitialState } from "./reducers/cargueMasivoReducer";
import { useGetGeneric } from "../hooks/useGet";
import urlApi from "../../core/api";
import { mainContext } from "./mainContext";
import { mainTypeMsj } from "./interfaces/mainInterfaces";
import { updateProceso } from "./actions/cargueMasivoActions";

export const cargueMasivoContext = createContext<ICargueMasivoContext | undefined>(undefined);

export const CargueMasivoProvider = ({ children }) => {

    const {handleNotification} = useContext(mainContext);
    const [state, dispatch] = useReducer(cargueMasivoReducer, cargueMasivoInitialState);
    const [procesoExiste, setProcesoExiste] = useState<boolean>(true);
    const [requestProceso, errProceso, obtenerProceso] = useGetGeneric({
        url: (state?.id) ? `${urlApi.SHOW_CARGUE_MASIVO}${state?.id}` : null
    });

    useEffect(() => {
      if (errProceso) {
        handleNotification(errProceso?.data?.message, mainTypeMsj.ERROR);
      } else if (requestProceso && requestProceso?.data?.code === 400) {
        setProcesoExiste(false)
      } else if (requestProceso && requestProceso?.data?.code === 200) {
        dispatch(updateProceso({
          ...requestProceso?.data?.records
        }));
      }
    }, [errProceso, requestProceso]);

    return (
        <cargueMasivoContext.Provider
          value={{
            procesoExiste,
            cargueMasivoDispatch: dispatch,
            cargueMasivoState   : state,
            obtenerDataProceso  : obtenerProceso
          }}
        >
          {children}
        </cargueMasivoContext.Provider>
    );
}

export const useCargueMasivoContext = () => {
    const context = React.useContext(cargueMasivoContext)
    if (context === undefined) {
      throw new Error('useCargueMasivoContext must be used within a CargueMasivoProvider')
    }
    return context
};

