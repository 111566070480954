import { getRequest, updateItem, deleteItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ITokens } from '../../models/usuarios/tokensModel';
import { IRecuperarToken } from '../../models/procesos/tareasModel';

/**
 * Permite actualizar / crear un registro en la bd
 */
export const tokensSaveService = async (
  values: ITokens,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<ITokens> => {
  const url = values.id ? `${urlApi.EDIT_TOKENS}${values.id}` : `${urlApi.CREATE_TOKENS}`;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data?.record) return resp?.data?.record;
  else return resp;
};

/**
 * Permite obtener los registros en la bd
 */
export const tokensListService = async (params = {}): Promise<ITokens[]> => {
  let data: ITokens[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_TOKENS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const tokensShowService = async (token: string): Promise<ITokens | null> => {
  let data: ITokens | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TOKENS}${token}`);
    data = resp.data.records; // data = resp.data.record;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite borrar un registro
 */
export const tokensDeleteService = async (
  id: number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.DELETE_TOKENS}${id}`;
  return await deleteItem(url, notification);
};

/**
 * Permite actualizar / crear un registro en la bd
 */
export const recuperarSaveService = async (
  values: IRecuperarToken,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<{code: number}> => {
  const url = values.type === 1 ? urlApi.RECUPERAR_TOKENS1 : urlApi.RECUPERAR_TOKENS2;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data) return resp?.data;
  else return resp;
};