import React, { FC } from 'react';
import Iframe from 'react-iframe';
// Reactstrap
import {
  Row,
  Col,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';

const ReporteTareasClientes: FC =  () => {

  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <h1>{t('Reporte de Tareas de Clientes')}</h1>
        <Iframe
          url={'https://lookerstudio.google.com/embed/reporting/144be332-de05-4a94-a816-e44af289e6db/page/tEnnC'}
          width={'100%'}
          height={'900'}
          frameBorder={0}
          allowFullScreen={true}
        >
          Debe habilitar los Iframes para poder ver éste contenido</Iframe>
        </Col>
    </Row>
  )
}

export default ReporteTareasClientes
