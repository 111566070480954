import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { IFirmasAutorizadas } from "../../models/postcontractual/firmasAutorizadasModel";
import { updateItem, getRequest, postRequest, deleteItem } from "../peticionesService";



/**guardar o crear firmas autorizada */
export const handleSaveCreateFirmasAutorizadassService = async (
    values: IFirmasAutorizadas,
    notification: (
        msj: string,
        type: mainTypeMsj
    ) => void,
    file = false,
    sendNotification = true
): Promise<IFirmasAutorizadas> => {
    const url = (values?.id) ? `${urlApi.EDITAR_POST_FIRMAS_AUTORIZADAS}${values.id}` : urlApi.CREATE_POST_FIRMAS_AUTORIZADAS;
    const resp = await updateItem(url, values, notification, file, sendNotification, true);
    if (resp?.data?.records) return resp?.data?.records;
    else return resp;
}

/**
 * Permite obtener el registro por id de firmas autorizadas en la bd
 */
export const showFirmasAutorizadasService = async (id: number): Promise<IFirmasAutorizadas | null> => {
    let data: IFirmasAutorizadas | null;
    try {
        const resp = await getRequest(`${urlApi.SHOW_POST_FIRMAS_AUTORIZADAS}${id}`);
        data = resp.data.records;
        console.log(resp)
    } catch (error) {
        console.error(error);
        data = null;
    }
    return data;
};

/**listar  firmas autorizadas */
export const handleListFirmasAutorizadas = async () => {
    let data: IFirmasAutorizadas[] = [];
    try {
        const resp = await getRequest(urlApi.LISTAR_POST_FIRMAS_AUTORIZADAS);
        data = resp.data.records;
    } catch (error) {
        console.error(error);
    }
    return data;
}
/**listar firmas autorizadas por id de proceso */
export const handleListFirmasAutorizadasByProceso = async (idpostcontractual: number | string) => {
    const params = { _busqueda: { idpostcontractual } }
    let data: IFirmasAutorizadas[] = [];
    try {
        const resp = await postRequest(urlApi.LISTAR_POST_FIRMAS_AUTORIZADAS, params, true);
        data = resp.data.records;
    } catch (error) {
        console.error(error);
    }
    return data;
}

/**
 * Permite borrar un registro
 */
export const deleteFirmasAutorizadasService = async (
    id: number,
    notification: (
        msj: string,
        type: mainTypeMsj
    ) => void
): Promise<boolean> => {
    const url = `${urlApi.DELETE_POST_FIRMAS_AUTORIZADAS}${id}`;
    return await deleteItem(url, notification);
};