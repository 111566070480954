import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Collapse, Row } from 'reactstrap';
import { IFideicominentesBeneficiarios } from '../../../../core/models/postcontractual/fideicomitentesModel';
import { useTareasContext } from '../../../../shared/contexts/tareasContext';
import { useUserContext } from '../../../../shared/contexts/userContext';
import FormEntidad1 from './FormEntidad1';
import { ITareas } from '../../../../core/models/procesos/tareasModel';
import InfoAdicional from './InfoAdicional';
import { ECodeFabrica } from '../../../../shared/utilities';
import TareasDocumentos from '../../../../shared/components/TareasDocumentos';
import TareasComentarios from '../../../../shared/components/TareasComentarios';
import { falseLoading, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import ButtonCustom from '../../../../shared/components/ButtonCustom';

const TokenPostFideicomitente: FC<ITokenPostFideicomitente> = ({ fideicomitente, idtarea }) => {
  // Context
  const { mainDispatch, mainState } = useMainContext();
  const { tarea, obtenerTarea, cambiarEstado } = useTareasContext();
  const { userState } = useUserContext();
  const { t } = useTranslation();

  // State
  const [showAdicional, setShowAdicional] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  // Memo
  const habilitar = useMemo(() => {
    if (!tarea) return true;
    else if (tarea?.idestados?.codigofabrica === 'CliRadicacionSolicitud') return true;
    return false;
  }, [tarea, userState]);

  const habilitarValidacionCliente = useMemo(() => {
    return (tarea?.idestados?.codigofabrica === ECodeFabrica.TR_PRE_VALIDACION_CLIENTE);
  }, [tarea]);

  const infoadicional = useMemo(() => {
    const { content, show } = InfoAdicional(tarea, habilitar);
    setShowAdicional(show);
    return content;
  }, [tarea]);

  useEffect(() => {
    if (idtarea?.id) {
      obtenerTarea(idtarea.id);
    }
  }, [idtarea])

  const handleCambiarEstado = async () => {
    mainDispatch(trueLoading());
    await cambiarEstado(tarea?.id || 0);
    await obtenerTarea(tarea?.id || 0);
    mainDispatch(falseLoading());
  }
  console.log("tareaaa=>", tarea, "fideicomitente=>", fideicomitente)
  return (
    <>
      {fideicomitente.inactivar ? (
        <>
          <Alert color="info">{t('Este token se encuentra inhabilitado')}</Alert>
        </>
      ) : (
        <>
          <FormEntidad1
            fideicomitente={fideicomitente}
            habilitar={!tarea}
            inComforceView={false}
            infoAdicional={showAdicional}
            tokenFlag={true}
          />
          {showAdicional &&
            <>
              <div className="callout">
                <Button onClick={() => setIsOpen(!isOpen)} color="link">
                  {t('Información adicional').toUpperCase()}
                </Button>
              </div>
              <Collapse isOpen={isOpen}>
                {habilitar &&
                  <Alert color="info">{t('Debe diligenciar la siguiente información antes de enviar la solicitud')}</Alert>
                }
                {infoadicional}
              </Collapse>
            </>
          }
          {habilitarValidacionCliente &&
            <>
              <div className="callout">
                <Button onClick={() => setIsOpen2(!isOpen2)} color="link">
                  {t('Documentos y comentarios')?.toUpperCase()}
                </Button>
              </div>
              <Collapse isOpen={isOpen2}>
                <Row>
                  <Col sm={12} md={6}>
                    <TareasDocumentos
                      idCargadoPor={1}
                      disabled={!habilitarValidacionCliente}
                      textButton={t('Documentos evidencia de la realización de la solicitud')}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <TareasComentarios disabled={!habilitarValidacionCliente} />
                  </Col>
                </Row>
                {
                  tarea?.comentariosinformacioncompleta &&
                  <Row>
                    <Col md={12} sm={12} className='mt-4'>
                      <hr />
                      <h4 className='text-center'>{t("Comentario de devolución")}</h4>
                    </Col>
                    <Col>
                      <div className="flex-shrink-1 bg-dark rounded py-2 px-3 my-2">
                        {tarea?.comentariosinformacioncompleta || ""}
                      </div>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col sm={{ size: 8, offset: 2 }}>
                    <ButtonCustom
                      block
                      color="secondary"
                      className="float-right mt-5"
                      type="button"
                      onClick={handleCambiarEstado}
                      loading={mainState.loading}
                      disabled={mainState.loading}
                    >
                      {t('Guardar')}
                    </ButtonCustom>
                  </Col>
                </Row>
              </Collapse>
            </>
          }
        </>
      )}
    </>
  );
}

interface ITokenPostFideicomitente {
  fideicomitente: IFideicominentesBeneficiarios;
  idtarea: ITareas;
}

export default TokenPostFideicomitente;