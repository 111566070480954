import React, { FC, useEffect } from 'react'

import ReporteProcesosPostcontractuales from "./components/ReporteProcesosPostcontractuales";
import { useTranslation } from 'react-i18next';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';
import { changeHeader, changeLoading } from '../../../shared/contexts/actions/mainActions';
import {useMainContext} from "../../../shared/contexts/mainContext";

const ReporteBIProcesosPostcontractuales: FC = () => {
  // contextos
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();

  useEffect(() => {
    mainDispatch(
      changeHeader({
        header: {
          title: t("Comforce® Reports"),
          subtitle: [
            { name: t("Inicio"), route: "/home" },
            { name: t("Comforce® Reports") },
            { name: t("Reporte Procesos Postcontractuales") },
          ],
          type: mainTypeHeader.BREADCRUMB,
        },
      })
    );
  }, []);

  return <ReporteProcesosPostcontractuales/>;
};

export default ReporteBIProcesosPostcontractuales;
