import { getRequest, updateItem, deleteItem, postRequest } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ITareaSolNoMonetariaCertificadosGarantia } from '../../models/tareas/tareaSolNoMonetariaCertificadosGarantiaModel';

/**
 * Permite actualizar / crear un registro en la bd
 */
export const tareaSolNoMonetariaCertificadosGarantiaSaveService = async (
  values: ITareaSolNoMonetariaCertificadosGarantia,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<ITareaSolNoMonetariaCertificadosGarantia | undefined> => {
  const url = values.id ? `${urlApi.EDIT_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA}${values.id}` : `${urlApi.CREATE_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA}`;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data?.record) return resp?.data?.record;
  else return resp;
};

/**
 * Permite obtener los registros en la bd
 */
export const tareaSolNoMonetariaCertificadosGarantiaListService = async (params: any = {}): Promise<ITareaSolNoMonetariaCertificadosGarantia[]> => {
  let data: ITareaSolNoMonetariaCertificadosGarantia[] = [];
  try {
    const resp = !params._busqueda ? await getRequest(urlApi.LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA, params) 
      : await postRequest(urlApi.LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const tareaSolNoMonetariaCertificadosGarantiaShowService = async (id: number): Promise<ITareaSolNoMonetariaCertificadosGarantia | null> => {
  let data: ITareaSolNoMonetariaCertificadosGarantia | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};