import { getRequest, updateItem } from '../peticionesService';
import urlApi from '../../api';
import { IAdenda } from '../../models/postcontractual/adendasModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerAdendas = async (): Promise<IAdenda[]> => {
  let data: IAdenda[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POST_ADENDAS);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const adendaCreateSaveService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<boolean> => {
  const url = !(values?.id) ? urlApi.CREAR_POST_ADENDA : `${urlApi.EDIT_POST_ADENDA}${values?.id}`;
  return await updateItem(url, values, notification, file, sendNotification);
};
