import React, { createContext, useReducer, useEffect, useContext, useState, useMemo } from 'react';
import { IPostcontractualContext } from './interfaces/postcontractualInterface';
import postcontractualReducer, { postcontractualInitialState } from './reducers/postcontractualReducer';
import { useGet, useGetGeneric } from '../hooks/useGet';
import { updateAprobaciones, updateDocumentos, updatePostUsuario, updateProceso, updateRequerimientos, updateRoles, updateState, updateTareas, updateUsuarios } from './actions/postcontractualActions';
import moment from 'moment';
import { ILiquidacion } from '../../core/models/postcontractual/liquidacionModel';
import { ISuspension } from '../../core/models/postcontractual/suspensionModel';
import { IUsuarios } from '../../core/models/usuarios/usuariosModel';
import { mainContext } from './mainContext';
import { mainTypeMsj } from './interfaces/mainInterfaces';
import { userContext } from './userContext';
import urlApi from '../../core/api';
import { aprobarProceso, continuarProcesoPostcontractual, editarProcesoPoscontractual, guardarPostcontractualUsuarios, obtenerListaUsuariosAprob, obtenerPostcontractualUsuarios, obtenerProcesoPostcontractual } from '../../core/services/postcontractual/postcontractualService';
import { obtenerPolizaGarantia, obtenerRequerimientos } from '../../core/services/procesos/polizaGarantiasService';
import { crearDocumento, crearPostDocumento, obtenerDocumentos } from '../../core/services/procesos/documentoService';
import { EModulos, ERolsProceso, ETipoGuardadoProceso, EGroupsNames, ETipoPostProceso } from '../utilities';
import { obtenerTareas } from '../../core/services/procesos/tareasService';
import { changeLoading, falseLoading, trueLoading } from './actions/mainActions';
import { formatStringtoDateString, newDate } from '../helpers/Fecha';
import { IListasItau } from '../../core/models/administracion/listasItauModels';
import { getRequest, putRequest } from '../../core/services/peticionesService';
import { IRoles, IRolesPost } from './interfaces/contractualInterfaces';
import { IPostcontractual } from '../../core/models/postcontractual/postcontractualModel';
import { adendaCreateSaveService } from '../../core/services/postcontractual/adendasService';
import { liquidacionSaveCreateService } from '../../core/services/postcontractual/liquidacionService';
import { suspensionSaveCreateService } from '../../core/services/postcontractual/suspensionService';
import { IModulos } from '../../core/models/administracion/estadosModel';
import { reinicioSaveCreateService } from '../../core/services/postcontractual/reinicioService';
import { IReinicioProceso } from '../../core/models/postcontractual/reiniciosModel';
import { tareasClienteListService } from '../../core/services/tareas/tareasClienteService';

export const postcontractualContext = createContext<IPostcontractualContext | undefined>(undefined);

export const PostcontractualProvider = ({ children }) => {
  // Contexts
  const { handleNotification, mainDispatch, mainState } = useContext(mainContext);
  const { userState } = useContext(userContext);

  // States
  const [state, dispatch] = useReducer(postcontractualReducer, postcontractualInitialState);
  const [polizaGarantia, setPolizaGarantia] = useState<any | null>(null);
  const [procesoExiste, setProcesoExiste] = useState<boolean>(true);
  const [requestProceso, errProceso, obtenerProceso] = useGetGeneric({
    url: (state?.id) ? `${urlApi.SHOW_POSTCONTRACTUAL}${state?.id}` : null
  });

  const obtenerDataProceso = () => {
    obtenerUsuarioPostcontractual();
    listarPolizaGarantia();
    listarUsuarios();
    listarAprobaciones();
    listarDocumentos();
    listarTareas();
  };

  const obtenerSoloProceso = async () => {
    const id = Number(state?.id ?? 0)
    const record = await obtenerProcesoPostcontractual(id);
    dispatch(updateProceso({ ...record }));
  }

  const listarAprobaciones = async () => {
    const usuarios = await obtenerListaUsuariosAprob((state.id as string));
    dispatch(updateRoles(usuarios));
    dispatch(updateAprobaciones(usuarios.reduce((prev: any[], current) => {
      let exist: any = null;

      if (prev?.length > 0) {
        exist = prev?.find(usuario => usuario?.id === current?.usuario?.idusuarios?.id && usuario?.tipo === 1);
        if (!exist) {
          exist = prev?.find(usuario => usuario?.id === current?.proveedor?.id && usuario?.tipo === 2);
        }
      }

      if (exist) {
        return prev.map(usuario => {
          if (usuario?.id === current?.usuario?.idusuarios?.id) {
            return {
              ...usuario,
              roles: (usuario?.roles?.find(rol => rol === current?.usuario?.rol)) ? exist?.roles : [
                ...usuario.roles,
                {
                  id: current?.usuario?.id,
                  rol: current?.usuario?.rol,
                  activo: current?.usuario?.activo
                }
              ],
            };
          } else {
            return usuario;
          }
        })
      } else {
        return [
          ...prev,
          (current?.usuario) && {
            id: current?.usuario?.idusuarios?.id,
            tipo: 1,
            roles: [{
              id: current?.usuario?.id,
              rol: current?.usuario?.rol,
              activo: current?.usuario?.activo
            }],
            nombre: `${current?.usuario?.idusuarios?.nombres} ${current?.usuario?.idusuarios?.apellidos}`,
            creado: current?.usuario?.creado,
            aprobaciones: current?.aprobaciones
          }
          // : {
          //   id: current?.proveedor?.id,
          //   tipo: 2,
          //   roles: [{
          //     id: current?.proveedor?.id,
          //     rol: 'Tercero',
          //     activo: current?.proveedor?.activo
          //   }],
          //   nombre: `${current?.proveedor?.razonsocial}`,
          //   creado: current?.proveedor?.creado,
          //   aprobaciones: current?.aprobaciones
          // }
        ];
      }
    }, [])));
  }

  // MEmo
  const postRoles = useMemo(() => ({
    experiencia_cliente_estructuracion: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.EXPERIENCIA_CLIENTE_ESTRUCTURACION
      && user?.activo === 1
    ),
    experiencia_cliente_gestion: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.EXPERIENCIA_CLIENTE_GESTION
      && user?.activo === 1
    ),
    solicitante: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.SOLICITANTE
      && user?.activo === 1
    ),
    jefe_de_area: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.JEFE_AREA
      && user?.activo === 1
    ),
    aprobador_areas: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.APROBADOR_AREAS
      && user?.activo === 1
    ),
    abogado: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.ABOGADO
      && user?.activo === 1
    ),
    experiencia_cliente_aseguramiento_y_control: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.EXPERIENCIA_CLIENTE_ASEGURAMIETO_Y_CONTROL
      && user?.activo === 1
    ),
    formalizador_aseguramiento_y_control: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL
      && user?.activo === 1
    ),
    formalizador_gestion: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.FORMALIZADOR_GESTION
      && user?.activo === 1
    ),
    cumplimiento_aseguramiento_y_control: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.CUMPLIMIENTO_ASEGURAMIENTO_Y_CONTROL
      && user?.activo === 1
    ),
    cumplimiento_gestion: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.CUMPLIMIENTO_GESTION
      && user?.activo === 1
    ),
    cumplimiento_estructuracion: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.CUMPLIMIENTO_ESTRUCTURACION
      && user?.activo === 1
    ),
    abogado_externo: state?.postUsuario?.find(user =>
      user?.idusuarios?.id === userState?.data?.sub
      && user.rol === ERolsProceso.ABOGADO_EXTERNO
      && user?.activo === 1
    ),
    super_admin: userState?.data?.roles?.some(rol => rol?.includes(EGroupsNames.SUPER_USUARIO))
  }), [state, userState]);

  const codigoFabrica = useMemo(() => (state?.proceso?.idestados?.codigofabrica), [state]);
  const idEstado = useMemo(() => (Number(state?.proceso?.idestados?.id)), [state]);
  const idModulo = useMemo(() => ((state?.proceso?.idestados?.idmodulos as IModulos)?.id), [state]);

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarDocumentos = async () => {
    const params = {
      filter: 'idpostcontractual',
      valuefilter: state.id
    };
    dispatch(updateDocumentos(await obtenerDocumentos(EModulos.POSTCONTRACTUAL.nombre, params)));
  };

  /**
   * Obtiene las documentos guardadas en la bd
   */
  const listarTareas = async () => {
    const params = {
      _busqueda: {idpostcontractual: state?.id}
    };
    dispatch(updateTareas(await tareasClienteListService(params)));
  };

  /**
   * Obtiene los datos del usuario logueado (roles)
   */
  const obtenerUsuarioPostcontractual = async () => {
    dispatch(updatePostUsuario(await obtenerPostcontractualUsuarios('idpostcontractual,idusuarios', `${state?.id},${userState?.data?.sub}`)));
  };

  /**
   * Obtiene la poliza garantía del proceso
   */
  const listarPolizaGarantia = async () => {
    const params = {
      filter: 'idpostcontractual',
      valuefilter: state.id
    };

    const resp = await obtenerPolizaGarantia(params);
    if (resp.length > 0) {
      listarRequerimientos(resp[0]?.id);
      setPolizaGarantia(resp[0]);
    } else {
      setPolizaGarantia(null);
      updateRequerimientos([]);
    }
  };

  /**
  * Obtiene los requerimientos guardados del proceso guardadas en la bd
  */
  const listarRequerimientos = async (id) => {
    const params = {
      filter: 'idpolizagarantia',
      valuefilter: id
    };
    dispatch(updateRequerimientos(await obtenerRequerimientos(params)));
  };

  /**
     * Obtiene las usuarios contractuales registrados en la bd
     */
  const listarUsuarios = async () => {
    dispatch(updateUsuarios(await obtenerPostcontractualUsuarios('idpostcontractual', (state?.id as string))));
  };

  /**
   * Permite aprobar en el proceso
   * @param values 
   * @param tipo 
   */
  const handleAprueba = async (values: any, tipo: number) => {
    mainDispatch(changeLoading());
    const dataSend = {
      ...values,
      idpostcontractual: state?.proceso?.id,
      activo: 1,
    }
    if (await aprobarProceso(dataSend, handleNotification)) {
      const dataSend = {
        id: state?.proceso?.id,
        usuarioid: userState.data?.sub,
        tipo,
        crear: false,
      };
      if (await editarProcesoPoscontractual(dataSend as IPostcontractual, handleNotification)) {
        obtenerProceso();
      }
    }
    mainDispatch(changeLoading());
  };

  const cargarDocumento = async (e: any, categoria: string | number, verify = true) => {
    mainDispatch(trueLoading());

    const exist = (verify) ? state?.documentos?.find(file => file.categoria === e.target.name) : undefined;
    const json = {
      id: exist ? exist?.id : undefined,
      idpostcontractual: state?.proceso?.id,
      idcargadopor: userState.data?.sub,
    };
    const dataSend = {
      ...json,
      anexos: {
        [categoria]: [e.target.files[0]]
      }
    };

    if (await crearDocumento(dataSend, handleNotification, EModulos.POSTCONTRACTUAL?.nombre)) {
      listarDocumentos();
    }
    mainDispatch(changeLoading());
  };

  const guardarAvanzarProcesoPost = async (
    values: any,
    file: boolean = false,
    changeState: boolean = true,
    notif: boolean = true
  ): Promise<boolean> => {
    const data = { ...values, id: state?.id, _tipo_edicion: changeState ? "guardar_y_avanzar" : "guardar_datos" };
    const resp = (await editarProcesoPoscontractual(data, handleNotification, file, notif));
    resp && await obtenerProceso();
    return resp
  };

  const handleCrearSubproceso = async (tipoprocesopostcontractual: number) => {
    mainDispatch(trueLoading());
    let success: boolean = false
    if (tipoprocesopostcontractual === ETipoPostProceso.OTROSI) {
      success = await adendaCreateSaveService({ idpostcontractual: state?.id }, handleNotification);
    }
    if (tipoprocesopostcontractual === ETipoPostProceso.LIQUIDACION) {
      success = await liquidacionSaveCreateService({ idpostcontractual: state?.id } as any, handleNotification);
    }
    if (tipoprocesopostcontractual === ETipoPostProceso.SUSPENSON) {
      success = await suspensionSaveCreateService({ idpostcontractual: state?.id }, handleNotification);
    }
    if (success) {
      const change = await guardarAvanzarProcesoPost({ tipoprocesopostcontractual }, false, true, false);
      change && setTimeout(() => mainDispatch(falseLoading()), 1000);
    }
  }

  const crearPostUsuario = async (idusuario: number, rol: number, notificar: boolean = true) => {
    const data = { idusuarios: idusuario, idpostcontractual: state?.id, rol: rol, activo: 1 };
    return (await guardarPostcontractualUsuarios(data, handleNotification, notificar));
  }



  useEffect(() => {
    if (errProceso) {
      handleNotification(errProceso?.data?.message, mainTypeMsj.ERROR);
    } else if (requestProceso && requestProceso?.data?.code === 400) {
      setProcesoExiste(false)
    } else if (requestProceso && requestProceso?.data?.code === 200) {
      dispatch(updateProceso({
        ...requestProceso?.data?.record,
        liquidaciones: requestProceso?.data?.liquidacion?.map((liquidacion: ILiquidacion) => ({
          ...liquidacion,
          fecha: liquidacion?.fecha,
        })),
        adendas: requestProceso.data?.adendas,
        suspensiones: requestProceso.data?.suspension?.map((suspension: ISuspension): ISuspension => ({
          ...suspension,
          inicio: suspension?.inicio,
          final: suspension?.final,
          solicitud: suspension?.solicitud,
        })),
        renovaciones: requestProceso.data?.renovacion,
        polizas: requestProceso?.data?.polizas || [],
        reinicios: requestProceso?.data?.reinicio?.map((reinicio: IReinicioProceso) => ({
          ...reinicio
        })),
        // reinicios: requestProceso?.data?.reinicio || [],
        pathadenda: requestProceso?.data?.pathadenda ? `${requestProceso?.data?.pathadenda}?${moment().unix()}` : null || null,
        pathacta: `${requestProceso?.data?.pathacta}?${moment().unix()}` || null,
        pathliquidacion: `${requestProceso?.data?.pathliquidacion}?${moment().unix()}` || null
      }));
      obtenerDataProceso();
    }
  }, [errProceso, requestProceso]);

  useEffect(() => {
    if (!userState?.isAutenticated) {
      dispatch(updateState(undefined));
    }
  }, [userState])

  return (
    <postcontractualContext.Provider
      value={{
        polizaGarantia,
        postcontractualState: state,
        procesoExiste,
        postRoles,
        codigoFabrica,
        idEstado,
        idModulo,
        postcontractualDispatch: dispatch,
        cargarDocumento,
        obtenerProceso,
        obtenerSoloProceso,
        listarDocumentos,
        listarTareas,
        listarUsuarios,
        obtenerUsuarioPostcontractual,
        listarAprobaciones,
        handleAprueba,
        listarPolizaGarantia,
        listarRequerimientos,
        guardarAvanzarProcesoPost,
        obtenerDataProceso,
        handleCrearSubproceso,
        crearPostUsuario
      }}
    >
      {children}
    </postcontractualContext.Provider>
  );
};

export const usePostcontractualContext = () => {
  const context = React.useContext(postcontractualContext)
  if (context === undefined) {
    throw new Error('usePostcontractualContext must be used within a PostcontractualProvider')
  }
  return context
};
