import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { IFideicominentesBeneficiarios } from "../../../../core/models/postcontractual/fideicomitentesModel";
import { IPostcontractual } from "../../../../core/models/postcontractual/postcontractualModel";
import { ITareas } from "../../../../core/models/procesos/tareasModel";
import { IUsuarios } from "../../../../core/models/usuarios/usuariosModel";
import { handleListFideiComitentesByProceso } from "../../../../core/services/postcontractual/fideicomitentesService";
import { obtenerUsuarios } from "../../../../core/services/usuarios/usuariosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { changeHeader, falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { mainTypeHeader } from "../../../../shared/contexts/interfaces/mainInterfaces";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { usePostcontractualContext } from "../../../../shared/contexts/postcontractualContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useUserContext } from "../../../../shared/contexts/userContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { ECodeFabrica, FormValidation } from "../../../../shared/utilities";
import { EListaDesplegables } from '../../../../core/models/administracion/listasItauModels';
import { useHistory } from "react-router-dom";
import {formatStringtoDateString, newDateToString} from '../../../../shared/helpers/Fecha';
import { SyntheticInputEvent } from 'react-number-format';
import BooleanField from '../../../../shared/components/BooleanField';
import MultiSelectField from '../../../../shared/components/MultiSelectField';
import { IFirmasAutorizadas } from '../../../../core/models/postcontractual/firmasAutorizadasModel';
import { handleListFirmasAutorizadasByProceso } from '../../../../core/services/postcontractual/firmasAutorizadasService';
import TareasDocumentos from "../../../../shared/components/TareasDocumentos";
import TareasComentarios from "../../../../shared/components/TareasComentarios";

const FormEntidad1: FC<IFormEntidad1> = ({ fideicomitente, habilitar = false, inComforceView = true, infoAdicional = false, tokenFlag = false }) => {
  // Context
  const { tarea, guardarTarea, obtenerTarea, cambiarEstado } = useTareasContext();
  const { mainState, mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista, obtenerSublista } = useAllListas();
  const { postcontractualState } = usePostcontractualContext();
  const { userState } = useUserContext();
  const { proceso, usuariosProceso } = postcontractualState || {};
  // hooks
  const history = useHistory();

  // State
  const [usuarios, setUsuarios] = useState<IUsuarios[]>([]);
  const [fideicomitentes, setFideicomitentes] = useState<IFideicominentesBeneficiarios[]>([]);
  const [firmasAutorizadas, setFirmasAutorizadas] = useState<IFirmasAutorizadas[]>([]);

  /**
   * Obtiene los terceros guardados en la bd
   */
  const listarUsuarios = async () => {
    if (userState.isAutenticated) {
      setUsuarios(await obtenerUsuarios());
      setFideicomitentes(await handleListFideiComitentesByProceso(proceso?.id || 0));
    }
  };

  const listarFirmasAutorizadas = async () => {
    const id = (fideicomitente?.idpostcontractual as IPostcontractual)?.id || proceso?.id;
    const data = await handleListFirmasAutorizadasByProceso(id || 0);
    setFirmasAutorizadas(data.filter(item => item.id != fideicomitente?.id));
  }

  const handleCambiarEstado = async () => {
    mainDispatch(trueLoading());
    await cambiarEstado(tarea?.id || 0);
    await obtenerTarea(tarea?.id || 0);
    mainDispatch(falseLoading());
  }

  useEffect(() => {
    listarUsuarios()
    listarFirmasAutorizadas()
  }, []);

  useEffect(() => {
    if (!proceso?.id) {
      if (tarea) {
        mainDispatch(changeHeader({
          header: {
            title: `${t('Solicitud')} #${tarea.id} - ${tarea?.idestados?.nombre}`,
            subtitle: [],
            type: mainTypeHeader.BREADCRUMB
          }
        }));
      } else {
        mainDispatch(changeHeader({
          header: {
            title: t(`Realizar solicitud`),
            subtitle: [],
            type: mainTypeHeader.BREADCRUMB
          }
        }));
      }
    }
  }, [tarea, proceso]);

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: tarea?.id || undefined,
      codigonombrenegocio: tarea?.codigonombrenegocio || (fideicomitente?.idpostcontractual as IPostcontractual)?.codigocontrato || '',
      numeroidsolicitante: tarea?.numeroidsolicitante || fideicomitente?.numeroid || '',
      nombresolicitante: tarea?.nombresolicitante || fideicomitente?.nombre || '',
      tiposolicitud: tarea?.tiposolicitud || '',
      subtiposolicitud: tarea?.subtiposolicitud || '',
      clasificacionsubtipologia: tarea?.clasificacionsubtipologia || '',
      descripcion: tarea?.descripcion || '',
      inicio: tarea?.inicio ? formatStringtoDateString(tarea?.inicio) : formatStringtoDateString(newDateToString()),
      final: tarea?.final ? formatStringtoDateString(tarea?.final) : null,
      usuarioasignado: (tarea?.usuarioasignado as IUsuarios)?.id || undefined,
      fideicomitenteasignado: (tarea?.fideicomitenteasignado as IFideicominentesBeneficiarios)?.id || undefined,
      avance: tarea?.avance || '0',
      tipo: 'tareacliente',
      idpostcontractual: (fideicomitente?.idpostcontractual as IPostcontractual)?.id || proceso?.id,
      usuariocreador: !!tarea ? (tarea?.usuariocreador as IUsuarios)?.id : inComforceView ? userState?.data?.sub : undefined,
      firmaautorizadacreador: fideicomitente?.id || null,
      requiereautorizacion: tarea?.requiereautorizacion,
      firmasautorizadas: tarea?.firmasautorizadas?.map((item) => (item as any)?.id) || [],
      comentariosinformacioncompleta: tarea?.comentariosinformacioncompleta || ''
    },
    validationSchema: Yup.lazy((values: ITareas): Yup.Lazy => Yup.object().shape({
      codigonombrenegocio: Yup.string(),
      numeroidsolicitante: Yup.string(),
      nombresolicitante: Yup.string(),
      tiposolicitud: Yup.string().required(FormValidation.required),
      subtiposolicitud: Yup.string().required(FormValidation.required),
      clasificacionsubtipologia: Yup.string(),
      descripcion: Yup.string().required(FormValidation.required),
      inicio: Yup.string().required(FormValidation.required).nullable(),
      final: Yup.string().nullable(),
      usuarioasignado: userState.isAutenticated && !values.fideicomitenteasignado && !fideicomitente ? Yup.string().required(FormValidation.required) : Yup.string(),
      fideicomitenteasignado: userState.isAutenticated && !values.usuarioasignado && !fideicomitente ? Yup.string().required(FormValidation.required) : Yup.string(),
      avance: Yup.string().required(FormValidation.required),
      requiereautorizacion: values.tiposolicitud === 'monetaria' ? Yup.boolean().nullable().required(FormValidation.required) : Yup.mixed(),
      firmasautorizadas: values.tiposolicitud === 'monetaria' && values.requiereautorizacion ? Yup.array().min(1, FormValidation.required) : Yup.mixed(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const existeTarea = !!tarea;
      const data = await guardarTarea((values as ITareas));
      if (!!fideicomitente && !!data?.tokenedicionfideicomitentecreador) {
        const { id, tokenacceso } = data?.tokenedicionfideicomitentecreador;
        history.push(`/token/${id}/${tokenacceso}`);
        mainDispatch(falseLoading());
        return;
      }
      await obtenerTarea(data?.id || 0);
      if (!infoAdicional && existeTarea) {
        await cambiarEstado(data?.id || 0);
      }
      await obtenerTarea(data?.id || 0);
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  return (
    <>
      {tarea?.idestados && tarea?.idestados?.codigofabrica === 'ValidacionTarea' && !userState?.isAutenticated &&
        <Alert color="success">La solicitud fue enviada correctamente. Puede cerrar esta ventana.</Alert>
      }
      <Form id="FormEntidad1" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="codigonombrenegocio">{t('Código/Nombre del negocio')}</Label>
                <Field
                  as={Input}
                  id="codigonombrenegocio"
                  name="codigonombrenegocio"
                  invalid={errors.codigonombrenegocio && touched.codigonombrenegocio}
                  disabled={!habilitar || !!tarea}
                />
                <FormFeedback>{t(errors.codigonombrenegocio as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombresolicitante">{t('Nombre del Solicitante')}</Label>
                <Field
                  as={Input}
                  id="nombresolicitante"
                  name="nombresolicitante"
                  invalid={errors.nombresolicitante && touched.nombresolicitante}
                  disabled={!habilitar || !!tarea}
                />
                <FormFeedback>{t(errors.nombresolicitante as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidsolicitante">{t('Número de Identificación del solicitante')}</Label>
                <Field
                  as={Input}
                  id="numeroidsolicitante"
                  name="numeroidsolicitante"
                  invalid={errors.numeroidsolicitante && touched.numeroidsolicitante}
                  disabled={!habilitar || !!tarea}
                />
                <FormFeedback>{t(errors.numeroidsolicitante as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tiposolicitud">{t('Tipo de Solicitud')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tiposolicitud"
                  name="tiposolicitud"
                  invalid={errors.tiposolicitud && touched.tiposolicitud}
                  onChange={(e: SyntheticInputEvent) => {
                    setFieldValue('tiposolicitud', e.target.value);
                    setFieldValue('subtiposolicitud', '');
                  }}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_SOLICITUD).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tiposolicitud as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="subtiposolicitud">{t('Subtipo de Solicitud')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="subtiposolicitud"
                  name="subtiposolicitud"
                  invalid={errors.subtiposolicitud && touched.subtiposolicitud}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerSublista(14, values.tiposolicitud).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.subtiposolicitud as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="clasificacionsubtipologia">{t('Clasificación de la subtipología')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="clasificacionsubtipologia"
                  name="clasificacionsubtipologia"
                  invalid={errors.clasificacionsubtipologia && touched.clasificacionsubtipologia}
                  disabled={!habilitar || !!tarea}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(
                      values.tiposolicitud === 'monetaria' ?
                        EListaDesplegables.CLASIFICACIONES_SUBTIPOLOGIA_MONETARIAS :
                        EListaDesplegables.CLASIFICACIONES_SUBTIPOLOGIA_NO_MONETARIAS
                    ).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.clasificacionsubtipologia as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={12}>
              <FormGroup>
                <Label for="descripcion">{t('Descripción de la solicitud')}</Label>
                <Field
                  as={Input}
                  type="textarea"
                  id="descripcion"
                  name="descripcion"
                  invalid={errors.descripcion && touched.descripcion}
                  disabled={!habilitar || !!tarea}
                />
                <FormFeedback>{t(errors.descripcion as string)}</FormFeedback>
              </FormGroup>
            </Col>
            {values.tiposolicitud === 'monetaria' &&
              <>
                <Col sm={12} md={4}>
                  <FormGroup>
                    <Label for="requiereautorizacion">{t('Su transacción requiere más de una autorización?')}</Label>
                    <Field
                      as={BooleanField}
                      id="requiereautorizacion"
                      name="requiereautorizacion"
                      invalid={errors.requiereautorizacion && touched.requiereautorizacion}
                      disabled={!habilitar || !!tarea}
                    />
                    <FormFeedback>{t(errors.requiereautorizacion as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                {values.requiereautorizacion &&
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label for="firmasautorizadas">{t('Firmas Autorizadas')}</Label>
                      <Field
                        as={MultiSelectField}
                        id="firmasautorizadas"
                        name="firmasautorizadas"
                        invalid={errors.firmasautorizadas && touched.firmasautorizadas}
                        disabled={!habilitar || !!tarea}
                        options={firmasAutorizadas.map((item, index) => (
                          {
                            label: item.nombre,
                            value: item.id,
                          }
                        ))}
                      />
                      <FormFeedback>{t(errors.firmasautorizadas as string)}</FormFeedback>
                    </FormGroup>
                  </Col>
                }
              </>
            }
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="inicio">{t('Fecha de inicio de la solicitud')}</Label>
                <Field
                  as={DateField}
                  id="inicio"
                  name="inicio"
                  invalid={errors.inicio && touched.inicio}
                  disabled={true}
                  minDate={new Date()}
                />
                <FormFeedback>{t(errors.inicio as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="final">{t('Fecha de fin de la solicitud')}</Label>
                <Field
                  as={DateField}
                  id="final"
                  name="final"
                  invalid={errors.final && touched.final}
                  disabled
                />
                <FormFeedback>{t(errors.final as string)}</FormFeedback>
              </FormGroup>
            </Col>
            {!userState?.isAutenticated && !!values.comentariosinformacioncompleta &&
              <Col sm={12} md={4}>
                <FormGroup>
                  <Label for="comentariosinformacioncompleta">{t('Comentarios devolución')}</Label>
                  <Field
                    as={Input}
                    type="textarea"
                    id="comentariosinformacioncompleta"
                    name="comentariosinformacioncompleta"
                    disabled={true}
                  />
                  <FormFeedback>{t(errors.comentariosinformacioncompleta as string)}</FormFeedback>
                </FormGroup>
              </Col>
            }
            {userState?.isAutenticated &&
              <>
                {(inComforceView && !values.fideicomitenteasignado) &&
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label for="usuarioasignado">{t('Asignado a usuario')}</Label>
                      <Field
                        as={Input}
                        type="select"
                        id="usuarioasignado"
                        name="usuarioasignado"
                        invalid={errors.usuarioasignado && touched.usuarioasignado}
                        disabled={!habilitar || !!tarea}
                      >
                        <option value="">{t('Seleccione')}</option>
                        {
                          usuarios?.map(({ id, nombres, apellidos }) => (
                            (id) && <option key={id} value={id}>{`${nombres} ${apellidos}`}</option>
                          ))
                        }
                      </Field>
                      <FormFeedback>{t(errors.usuarioasignado as string)}</FormFeedback>
                    </FormGroup>
                  </Col>
                }
                {(inComforceView && !values.usuarioasignado) &&
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <Label for="fideicomitenteasignado">{t('Asignado a fideicomitente')}</Label>
                      <Field
                        as={Input}
                        type="select"
                        id="fideicomitenteasignado"
                        name="fideicomitenteasignado"
                        invalid={errors.fideicomitenteasignado && touched.fideicomitenteasignado}
                        disabled={!habilitar || !!tarea}
                      >
                        <option value="">{t('Seleccione')}</option>
                        {
                          fideicomitentes?.map(({ id, nombre }) => (
                            (id) && <option key={id} value={id}>{nombre}</option>
                          ))
                        }
                      </Field>
                      <FormFeedback>{t(errors.fideicomitenteasignado as string)}</FormFeedback>
                    </FormGroup>
                  </Col>
                }
              </>
            }
            {userState.isAutenticated && !tokenFlag &&
              <Col sm={12} md={4}>
                <FormGroup>
                  <Label for="avance">{t('Porcentaje de avance')}</Label>
                  <Field
                    as={NumberField}
                    id="avance"
                    name="avance"
                    invalid={errors.avance && touched.avance}
                    disabled={!(tarea?.avance !== 100)}
                  />
                  <FormFeedback>{t(errors.avance as string)}</FormFeedback>
                </FormGroup>
              </Col>
            }
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <TareasDocumentos
                textButton={t("Documentos anexos")}
                idCargadoPor={userState?.data?.sub || 1}
                disabled={!(habilitar || tarea?.idestados?.codigofabrica === ECodeFabrica.TR_CLI_RADICACION_SOLICITUD) || !tarea} />
            </Col>
            <Col sm={12} md={6}>
              <TareasComentarios
                disabled={!(habilitar || tarea?.idestados?.codigofabrica === ECodeFabrica.TR_CLI_RADICACION_SOLICITUD) || !tarea}
              />
            </Col>
          </Row>
          {((habilitar || tarea?.avance !== 100) && (userState.isAutenticated || !tarea)) &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad1"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {tarea ? t('Actualizar solicitud') : t('Realizar solicitud')}
              </ButtonCustom>
            </div>
          }
          {(tarea?.idestados?.codigofabrica === 'CliRadicacionSolicitud' && !userState.isAutenticated && !infoAdicional) &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                color="primary"
                className="float-right"
                type="button"
                onClick={handleCambiarEstado}
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Continuar')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad1 {
  fideicomitente?: IFideicominentesBeneficiarios;
  inComforceView?: boolean;
  habilitar: boolean;
  infoAdicional?: boolean;
  tokenFlag?: boolean;
}

export default FormEntidad1;
