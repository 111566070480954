import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Card, CardBody } from 'reactstrap';
import { IFideicominentesBeneficiarios } from '../../../core/models/postcontractual/fideicomitentesModel';
import { ITareas } from '../../../core/models/procesos/tareasModel';
import { ITokens } from '../../../core/models/usuarios/tokensModel';
import { tokensShowService } from '../../../core/services/usuarios/tokensService';
import DataTableSkeleton from '../../../shared/components/DataTableSkeleton';
import TokenPostFideicomitente from './TokenPostFideicomitente';
import { useMainContext } from '../../../shared/contexts/mainContext';
import FormCodigo from './FormCodigo';
import TokenTareasPredeterminadas from './TokenTareasPredeterminadas';
import { ECodeFabrica } from '../../../shared/utilities';

const Token: FC = () => {
  // Context
  const { tokenurl, id } = useParams<any>();
  const { t } = useTranslation();
  const { listasDesplegables } = useMainContext();

  // State
  const [token, setToken] = useState<ITokens | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const obtenerToken = async () => {
    setLoading(true);
    const data = await tokensShowService(tokenurl);
    if (data) {
      setToken(data);
    } else {
      setToken(null);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (tokenurl) {
      obtenerToken();
    }
  }, [tokenurl])

  const renderToken = () => {
    if (!token) return null;
    const estadoTarea = (token?.entidadasociada as ITareas)?.idestados?.codigofabrica;
    const tareasPred: string[] = [
      ECodeFabrica.TR_PRE_CREACION_TAREA,
      ECodeFabrica.TR_PRE_VALIDACION_CLIENTE,
      ECodeFabrica.TR_PRE_VALIDACION_SOLICITUD,
      ECodeFabrica.TR_PRE_REVISION_AREAS,
      ECodeFabrica.TR_PRE_REVISION_FORMALIZADOR,
      ECodeFabrica.TR_PRE_APROBACION_CUMPLIMIENTO,
      ECodeFabrica.TR_PRE_EN_PROCESO,
      ECodeFabrica.TR_PRE_CERRADA,
    ];
    if (token.tipoentidadasociada === 'App\\Entity\\TareaTareas' && tareasPred.includes(estadoTarea)) {
      /* TOKEN TAREAS PREDETERMINADAS */
      return (
        <TokenTareasPredeterminadas
          fideicomitente={token?.entidadacceso as IFideicominentesBeneficiarios}
          idtarea={token?.entidadasociada as ITareas}
        />
      )
    } else if (token.tipoentidadacceso === 'App\\Entity\\PostFirmasAutorizadas' && token.tipo === 'token_sesion') {
      /* TOKEN TAREAS CLIENTE */
      return (
        <TokenPostFideicomitente
          fideicomitente={token?.entidadacceso as IFideicominentesBeneficiarios}
          idtarea={token?.entidadasociada as ITareas}
        />
      )
    } else if (token.tipo === 'token_validacion_otp') {
      /* TOKEN VALIDACION OTP */
      return (
        <FormCodigo
          showForm={true}
          idvalidacion={token.identidadasociada}
        />
      )
    } else return null;
  }

  return (
    <Card>
      <CardBody>
        {(loading || !listasDesplegables) ?
          <DataTableSkeleton />  :
          (
            token ? 
              <>
                {renderToken()}
                {/* {token.entidad === 'App\\Entity\\TareaTareas' && 
                  <TokenTareaTareas defaulttarea={token?.proceso as ITareas} />
                } */}
              </> :
              <Alert color="info" className="mb-0">{t('El token solicitado no existe')}</Alert>
          )
        }
      </CardBody>
    </Card>
  )
}

export default Token;