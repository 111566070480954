import React, { FC, useMemo, useState } from 'react';
import enFlag from '../../assets/flags/us.jpg';
import colFlag from '../../assets/flags/col.png';
import { DropdownItem, DropdownMenu, DropdownToggle, ModalBody, ModalFooter } from 'reactstrap';
import { useMainContext } from '../contexts/mainContext';
import { closeModal, openModal, trueLoading, falseLoading } from '../contexts/actions/mainActions';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../contexts/userContext';
import { guardarUsuario } from '../../core/services/usuarios/usuariosService';
import { userLogout } from '../contexts/actions/userActions';
import ButtonCustom from './ButtonCustom';
import { useHistory } from 'react-router-dom';
import { useNotificacionContext } from '../contexts/notificationContext';
import { clearNotification } from '../contexts/actions/notificationsActions';

const Languages = [
  {
    name: 'Español',
    flag: colFlag,
    language: 'es'
  },
  {
    name: 'Inglés',
    flag: enFlag,
    language: 'en'
  },
];

const AcceptChanges = ({ setLanguage, lang, history }) => {
  const { t } = useTranslation();
  const { mainDispatch, handleNotification, mainState } = useMainContext();
  const { userState, userDispatch } = useUserContext();
  const { notificationDispatch } = useNotificacionContext();

  const applyChanges = async () => {
    mainDispatch(trueLoading());
    setLanguage(lang);
    const data: any = {
      id: userState?.data?.sub,
      locale: lang
    };

    if (await guardarUsuario(data, handleNotification)) {
      notificationDispatch(clearNotification());
      userDispatch(userLogout());
      mainDispatch(closeModal());
      history.push('/login');
    };
    mainDispatch(falseLoading());


  }

  return (
    <>
      <ModalBody>
        {t('Va a tener que INGRESAR nuevamente para aplicar los cambios del lenguaje correspondiente. ¿Esta de acuerdo?')}
      </ModalBody>
      <ModalFooter>
        <ButtonCustom
          className="d-flex align-items-center"
          type="button"
          color="primary"
          loading={mainState?.loading}
          onClick={applyChanges}
        >
          {t('Aceptar')}
        </ButtonCustom>
        <ButtonCustom
          className="d-flex align-items-center"
          type="button"
          color="danger"
          loading={mainState?.loading}
          onClick={() => mainDispatch(closeModal())}
        >
          {t('Cancelar')}
        </ButtonCustom>
      </ModalFooter>
    </>
  )
}

const LanguageDropdown: FC<any> = ({ history }) => {
  const { mainState, mainDispatch } = useMainContext();
  const [langSelected, setLanguage] = useState<any>(Languages.find(lang => lang.language === mainState?.language) || Languages[0]);
  const { t } = useTranslation();

  const ListLanguages = useMemo(() => Languages.filter(lang => langSelected?.name !== lang.name), [langSelected]);

  return (
    <>
      <DropdownToggle nav caret>
        <img src={langSelected.flag} alt={langSelected.name} className="mr-1" height="12" />
        <span className="align-middle d-none d-md-inline">{langSelected.name}</span>
        <i className="d-flex align-items-center uil-angle-down"></i>
      </DropdownToggle>
      <DropdownMenu right>
        {
          ListLanguages.map((lang, index) => (
            <DropdownItem
              key={index}
              onClick={() => mainDispatch(openModal({
                modal: {
                  show: true,
                  title: t('Confirmación'),
                  body: () => <AcceptChanges lang={lang.language} setLanguage={setLanguage} history={history} />
                }
              }))}
            >
              <img src={lang.flag} alt={lang.name} className="mr-1" height="12" />
              <span className="align-middle">{lang.name}</span>
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </>
  );
};
export default LanguageDropdown;
