import { getRequest, updateItem } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ILiquidacion } from '../../models/postcontractual/liquidacionModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerLiquidacion = async (): Promise<ILiquidacion[]> => {
  let data: ILiquidacion[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POST_LIQUIDACIONES);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const liquidacionSaveCreateService = async (
  values: ILiquidacion,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = !(values?.id) ? urlApi.CREAR_POST_LIQUIDACION : `${urlApi.EDIT_POST_LIQUIDACION}${values.id}`;
  return await updateItem(url, values, notification, sendNotification);
};
