import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from 'reactstrap';
import ModalsFooter from '../../../shared/components/ModalsFooter';
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../../shared/contexts/mainContext';
import { falseLoading, trueLoading } from '../../../shared/contexts/actions/mainActions';
import { useTareasContext } from '../../../shared/contexts/tareasContext';
import { validacionConfirmarOTP, validacionGenerarOTP } from '../../../core/services/procesos/tareasService';

const FormCodigo: FC<IFormCodigo> = ({ idtarea, showForm, idvalidacion }) => {
  // State
  const [msj, setMsj] = useState({
    show: false,
    msj: '',
    color: 'info'
  });
  const [show, setShow] = useState(showForm);

  // Hooks
  const { t } = useTranslation();
  const { mainDispatch, handleNotification } = useMainContext();
  const { obtenerTarea, cambiarEstado } = useTareasContext();

  const sendCode = async () => {
    await validacionGenerarOTP({idtarea}, handleNotification);
  }

  const handleResend = async () => {
    mainDispatch(trueLoading());
    await sendCode();
    setMsj({
      show: true,
      msj: t('Se ha reenviado el código de acceso a su correo electrónico'),
      color: 'info',
    });
    mainDispatch(falseLoading());
  }

  const handleDismissMsj = () => {
    setMsj({
      ...msj,
      show: false
    });
  }

  useEffect(() => {
    if (idtarea) {
      sendCode();      
    }
    setMsj({
      show: true,
      msj: t('Para finalizar con la creación de la solicitud, debe ingresar el código enviado a su correo electrónico'),
      color: 'info',
    })
  }, [])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      codigo: '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      codigo: Yup.string().required(FormValidation.required),
    })),
    onSubmit: async (values, { resetForm }) => {
      mainDispatch(trueLoading());
      const resp = await validacionConfirmarOTP({
        ...values,
        idvalidacion
      }, handleNotification);
      if (resp.code === 200) {
        setMsj({
          show: true,
          msj: t('La validación de identidad fue exitosa y la solicitud fue creada correctamente. Puede cerrar esta ventana.'),
          color: 'success',
        });
        setShow(false);
      } else if (resp.code === 100) {
        setMsj({
          show: true,
          msj: resp.message,
          color: 'warning',
        });
      } else {
        setMsj({
          show: true,
          msj: resp.message,
          color: 'danger',
        });
      }
      mainDispatch(falseLoading());
    }
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    <>
      <ModalBody>
        <Alert color={msj.color} isOpen={msj.show} toggle={handleDismissMsj}>
          {msj.msj}
        </Alert>
        {show &&
          <Form id="FormCodigo" onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label for="codigo">{t('Código')}</Label>
                    <Field
                      as={Input}
                      name="codigo"
                      invalid={errors.codigo && touched.codigo}
                      disabled={false}
                      placeholder="00000"
                    />
                    <FormFeedback>{t(errors.codigo as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                {/* <Col sm={12} md={12}>
                  <Button color="link" block onClick={handleResend}>
                    {t('Reenvíar código')}
                  </Button>
                </Col> */}
              </Row>
            </FormikProvider>
          </Form>
        }
      </ModalBody>
      {show &&
        <ModalsFooter
          idForm="FormCodigo"
        />
      }
    </>
  )
}

interface IFormCodigo {
  idtarea?: number;
  showForm: boolean;
  idvalidacion?: number;
}

export default FormCodigo