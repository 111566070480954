import React, {FC, useMemo} from 'react';
import {ITareas} from '../../../core/models/procesos/tareasModel';
import {Button} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import MUIDataTable, {MUIDataTableColumn} from 'mui-datatables';
import {useOptionsTables} from '../../../shared/hooks/useOptionsTables';
import {IPostcontractual} from '../../../core/models/postcontractual/postcontractualModel';
import {useAllListas} from '../../../shared/hooks/useAllListas';
import {useUserContext} from '../../../shared/contexts/userContext';
import {EGroupsNames} from '../../../shared/utilities';

const TareasCliente: FC<ITareasCliente> = ({tareas, onChangePage, onSearchChange, total}) => {
  const history = useHistory();
  const {t} = useTranslation();
  const {optionsDataTable} = useOptionsTables();
  const {obtenerLabel} = useAllListas();
  const {userState} = useUserContext();

  const handleEdit = (id) => {
    history.push(`/postcontractual/edit/${id}`);
  }

  const esCoordinador = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.COORDINADOR)
  ), [userState]);

  const esAdmin = useMemo(() => (
    !!userState?.data?.roles?.find(rol => rol === EGroupsNames.SUPER_USUARIO)
  ), [userState]);

  const columns = [
    {
      name: t('ID Tarea'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('ID Tarea')}</h5>
        ),
      }
    },
    {
      name: t('Código del contrato'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Código del contrato')}</h5>
        ),
      }
    },
    {
      name: t('Subtipo de solicitud'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Subtipo de solicitud')}</h5>
        ),
      }
    },
    {
      name: t('ANS'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('ANS')}</h5>
        ),
        customBodyRender: (ans) => (
          <span className={ans < 0 ? 'text-danger' : ''}>{ans}</span>
        ),
      }
    }
  ];


  const responsables = esAdmin || esCoordinador ? [
    {
    name: t('Formalizador gestión'),
    options: {
      filter: true,
      sort: true,
      display: esCoordinador || esAdmin,
      customHeadLabelRender: () => (
        <h5>{t('Formalizador gestión')}</h5>
      ),
    }
  },
    {
      name: t('Responsable'),
      options: {
        filter: true,
        sort: true,
        display: esCoordinador || esAdmin,
        customHeadLabelRender: () => (
          <h5>{t('Responsable')}</h5>
        ),
      }
    }
  ] : [];
  const acciones = [
    {
    name: t('Link de acceso'),
    options: {
      filter: false,
      sort: true,
      customHeadLabelRender: () => (
        <h5>{t('Acciones')}</h5>
      ),
      customBodyRender: (id) => {
        return (
          <>
            <Button
              color="primary"
              className="mr-1 mb-1 btn-sm"
              onClick={() => handleEdit(id)}
            >
              <i className="uil-eye"></i>
            </Button>
          </>
        );
      }
    }
  },
  ];
const MUIColumnas: MUIDataTableColumn[] = columns.concat(responsables).concat(acciones);
  return (
    <>
      <MUIDataTable
        title={(<h5>{t('Gestión Pendientes Demanda Clientes')}</h5>)}
        data={tareas.map(record => {
          return esAdmin || esCoordinador ? 
            ([
              record.id,
              (record.idpostcontractual as IPostcontractual)?.codigocontrato,
              obtenerLabel(record.subtiposolicitud || ''),
              record.ans,
              record.formalizador ? `${record.formalizador?.idusuarios?.nombres} ${record.formalizador?.idusuarios?.apellidos}` : '',
              record.usuariopendienteacccion,
              (record.idpostcontractual as IPostcontractual)?.id,
            ]) : ([
              record.id,
              (record.idpostcontractual as IPostcontractual)?.codigocontrato,
              obtenerLabel(record.subtiposolicitud || ''),
              record.ans,
              (record.idpostcontractual as IPostcontractual)?.id,
            ])
          }
        )}
        columns={MUIColumnas}
        options={optionsDataTable({
          sort: false,
          filter: false,
          search: true,
          serverSide: true,
          onChangePage,
          onSearchChange,
          count: total,
          rowsPerPage: 10,
          rowsPerPageOptions: [],
        })}
      />
    </>
  )
}

interface ITareasCliente {
  tareas: ITareas[];
  onChangePage: (page: number) => void;
  onSearchChange: (searchText: string) => void;
  total: number;
}

export default TareasCliente;
