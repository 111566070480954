import { cargueMasivoTypeActions } from "..";
import { ICargueMasivo } from "../../../core/models/postcontractual/carguemasivoModel";
import { ICargueMasivoActions, ICargueMasivoReducer } from "../interfaces/cargueMasivoInterface";


// Estado inicial
export const cargueMasivoInitialState: ICargueMasivoReducer = {
    id     : null,
    proceso: null,
    logs   : []
};

const cargueMasivoReducer = (state = cargueMasivoInitialState, actions: ICargueMasivoActions): ICargueMasivoReducer => {
    switch (actions.type) {
        case cargueMasivoTypeActions.ID:
            return {
                ...state,
                id: actions.payload
            };
        case cargueMasivoTypeActions.PROCESO:
            return {
                ...state,
                proceso: (actions?.payload as ICargueMasivo)
            };
        case cargueMasivoTypeActions.LOGS:
            return {
                ...state,
                logs: actions.payload
            };
        default:
            return state;
    }
};

export default cargueMasivoReducer;