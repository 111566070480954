import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { changeHeader } from '../../../shared/contexts/actions/mainActions';
import { mainTypeHeader } from '../../../shared/contexts/interfaces/mainInterfaces';
import { useMainContext } from '../../../shared/contexts/mainContext';
import FormTareasClientes from './FormTareasClientes';
import i18n from '../../../i18n';

const TareasClientes: FC = () => {
  // Context
  const { mainDispatch } = useMainContext();
  const { t } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    mainDispatch(changeHeader({
      header: {
        title: t('Radicación de solicitudes'),
        subtitle: [],
        type: mainTypeHeader.BREADCRUMB
      }
    }));
  }, [language])

  return (
    <Card style={{margin: 'auto', maxWidth: '100%', 'width': 500}}>
      <CardBody>
        <FormTareasClientes />
      </CardBody>
    </Card>
  )
}

export default TareasClientes;