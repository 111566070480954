import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaSolicitudAgentesCredito } from "../../../../core/models/tareas/tareaSolNoMonetariaSolicitudAgentesCreditoModel";
import { tareaSolNoMonetariaSolicitudAgentesCreditoListService, tareaSolNoMonetariaSolicitudAgentesCreditoSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaSolicitudAgentesCreditoService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad16: FC<IFormEntidad16> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaSolicitudAgentesCredito|null>(null);
const obtenerData = async () => {
    const data = await tareaSolNoMonetariaSolicitudAgentesCreditoListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tipotramite: data?.tipotramite || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tipotramite: Yup.string().required(FormValidation.required),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaSolicitudAgentesCreditoSaveService((values as ITareaSolNoMonetariaSolicitudAgentesCredito), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad16" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipotramite">{t('Tipo de trámite')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipotramite"
                  name="tipotramite"
                  invalid={errors.tipotramite && touched.tipotramite}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_TRAMITE).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipotramite as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad16"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad16 {
  habilitar: boolean;
}

export default FormEntidad16;