import * as Yup from 'yup';
import { FormValidation } from '../../../shared/utilities';

export interface IUsuarioAuth {
  email: string;
  exp: number;
  firstname: string;
  lastname: string;
  image: string | null;
  idvicepresidencias: string | number | null;
  idgerencias: string | number | null;
  idareas: string | number | null;
  iat: number;
  grupos: number[];
  roles: string[];
  sub: number;
  token: string;
  cambiocontrasena?: number;
  locale?: string;
};

export interface ICambiarContrasena {
  actual: string;
  password: string;
  password_repeat: string;
};

export const formSchemaCambiarContrasena = Yup.object().shape({
  actual: Yup.string().required(FormValidation.required),
  password: Yup.string().required(FormValidation.required).trim().matches(/^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{6,19}$/, FormValidation.passwordValid),
  password_repeat: Yup.string().required(FormValidation.required).oneOf([Yup.ref('password'), ''], FormValidation.passwordMatch),
});

export const initialStateCambiarContrasena = {
  actual: '',
  password: '',
  password_repeat: '',
}
