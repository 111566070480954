import React, { createContext, useState } from "react";
import { useMainContext } from "./mainContext";
import { useUserContext } from "./userContext";
import { tareasClienteSaveService, tareasClienteShowService } from "../../core/services/tareas/tareasClienteService";
import TareasClientes from "../../pages/terceros/tareasClientes";
import { ITareas } from "../../core/models/procesos/tareasModel";
import { usePostcontractualContext } from "./postcontractualContext";
import { openModal } from './actions/mainActions';
import { useTranslation } from 'react-i18next';
import FormCodigo from '../../pages/terceros/tokens/FormCodigo';

export const tareasContext = createContext<ITareasContext | undefined>(undefined);

export const TareasProvider = ({ children }) => {
  // Context
  const { handleNotification, mainDispatch } = useMainContext();
  const { listarTareas, postcontractualState } = usePostcontractualContext();
  const { t } = useTranslation();

  // State
  const [tarea, setTarea] = useState<ITareas | null>(null);

  const obtenerTarea = async (id: number) => {
    const data = await tareasClienteShowService(id);
    if (data) {
      setTarea(data);
    }
  }

  const guardarTarea = async (values: ITareas, file: boolean|undefined = false): Promise<ITareas|null> => {
    const data = await tareasClienteSaveService(values, handleNotification, file);
    if (data?.id) {
      await obtenerTarea(data.id);
      if (postcontractualState?.id) {
        await listarTareas();
      }
      return data;
    }
    return null;
  }

  const cambiarEstado = async (idtarea?: number) => {
    const values = {
      id: idtarea,
      _tipo_edicion: "guardar_y_avanzar",
    }
    const resp = await tareasClienteSaveService((values as ITareas), handleNotification, false);
    console.log('resp', resp)
    if (postcontractualState?.id) {
      await listarTareas();
    } else {
      if ((resp as any)?.codigoexcepcion === 'VALOTPFIRMAAUTORIZADA') {
        mainDispatch(openModal({
          modal: {
            show: true,
            title: t('Validación de identidad'),
            body: () => (
              <>
                <FormCodigo idtarea={idtarea || 0} showForm={false} />
              </>
            )
          }
        }))
      }
    }
    return resp;
  }

  const limpiarTarea = () => {
    setTarea(null);
  }

  return (
    <tareasContext.Provider value={{
      tarea,
      obtenerTarea,
      guardarTarea,
      cambiarEstado,
      limpiarTarea,
    }}>
      {children}
    </tareasContext.Provider>
  )
}

export const useTareasContext = () => {
  const context = React.useContext(tareasContext);
  if (context === undefined) {
    throw new Error('precontractualState must be used within a TareasProvider');
  }
  return context;
}

interface ITareasContext {
  tarea: ITareas | null,
  obtenerTarea: (id: number) => Promise<void>,
  guardarTarea: (values: ITareas, file?: boolean) => Promise<ITareas|null>,
  cambiarEstado: (idtarea?: number) => Promise<ITareas | undefined>,
  limpiarTarea: () => void,
}