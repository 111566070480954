import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { getRequest, updateItem } from "../peticionesService";

export const obtenerAsesoriaService = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_ASESORIAS}${id}`);
    const record = {
      ...resp.data.record,
      docautogestion: resp.data.docautogestion
    }
    data = record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const cambiarEstadoService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = `${urlApi.STATES_ASESORIAS}${values?.id}`;
  return await updateItem(url, values, notification, false, sendNotification, requestData);
};

export const obtenerUsuariosService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_USUARIOS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerAbogadosService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_ABOGADOS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerDocumentosService = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_ASESORIAS_DOCUMENTOS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const guardarAsesoriaService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean | any> => {
  const url = (values?.id || values?.json?.id) ? `${urlApi.EDIT_ASESORIAS}${(values?.id || values?.json?.id)}` : `${urlApi.CREATE_ASESORIAS}`;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const listarAsesoriasService = async (): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerLogsService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_LOGS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const listarPreguntasService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_PREGUNTAS, filter? {filter: filter, valuefilter: valuefilter} : {});
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const obtenerAnsService = async (filter?: string, valuefilter?: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_ASESORIAS_ANS, filter? {filter: filter, valuefilter: valuefilter} : {} );
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};