import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import NumberFormat, { SyntheticInputEvent } from 'react-number-format';

const NumberField: FC<any> = ({currency, ...props}) => {
  const name = props.name || props.field?.name;
  const { getFieldProps, setFieldValue } = useFormikContext();
  return (
    <>
      {
        currency ? (
          <NumberFormat
            type="text"
            className={props.invalid ? "form-control is-invalid" : "form-control"}
            thousandSeparator=","
            decimalSeparator="."
            allowNegative={false}
            fixedDecimalScale={true}
            decimalScale={2}
            value={getFieldProps(name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              const value: string = e.target.value.split('.').join('');
              const formatValue = value.split(',').join('.');
              setFieldValue(name, formatValue);
            }}
            {...props}
            {...props.field}
          />
        ) : (
          <NumberFormat
            type="text"
            className={props.invalid ? "form-control is-invalid" : "form-control"}
            decimalScale={0}
            allowNegative={false}
            // isNumericString
            value={getFieldProps(name)?.value}
            onChange={(e: SyntheticInputEvent) => {
              setFieldValue(name, e.target.value);
            }}
            {...props}
            {...props.field}
          />
        )
      }
    </>
  );
};
export default NumberField;
