import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaCertificadosGarantia } from "../../../../core/models/tareas/tareaSolNoMonetariaCertificadosGarantiaModel";
import { tareaSolNoMonetariaCertificadosGarantiaListService, tareaSolNoMonetariaCertificadosGarantiaSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaCertificadoGarantiaService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import BooleanField from '../../../../shared/components/BooleanField';

const FormEntidad11: FC<IFormEntidad11> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaCertificadosGarantia|null>(null);
const obtenerData = async () => {
    const data = await tareaSolNoMonetariaCertificadosGarantiaListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tipocertificado: data?.tipocertificado || '',
      nombrebeneficiario: data?.nombrebeneficiario || '',
      tipoidbeneficiario: data?.tipoidbeneficiario || '',
      numeroidbeneficiario: data?.numeroidbeneficiario || '',
      nombredeudor: data?.nombredeudor || '',
      tipoiddeudor: data?.tipoiddeudor || '',
      numeroiddeudor: data?.numeroiddeudor || '',
      valorcertificado: data?.valorcertificado || '',
      monedacertificado: data?.monedacertificado || '',
      valorbienesfideicomitidos: data?.valorbienesfideicomitidos || '',
      monedabienesfideicomitidos: data?.monedabienesfideicomitidos || '',
      fechaultimoavaluo: data?.fechaultimoavaluo || '',
      nombreavaluador: data?.nombreavaluador || '',
      acreedordiferente: data?.acreedordiferente,
      nombrerepresentantefideicomitente: data?.nombrerepresentantefideicomitente || '',
      tipoidlegalfideicomitente: data?.tipoidlegalfideicomitente || '',
      numeroidlegalfideicomitente: data?.numeroidlegalfideicomitente || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tipocertificado: Yup.string().required(FormValidation.required),
      nombrebeneficiario: Yup.string(),
      tipoidbeneficiario: Yup.string(),
      numeroidbeneficiario: Yup.string(),
      nombredeudor: Yup.string(),
      tipoiddeudor: Yup.string(),
      numeroiddeudor: Yup.string(),
      valorcertificado: Yup.string(),
      monedacertificado: Yup.string(),
      valorbienesfideicomitidos: Yup.string(),
      monedabienesfideicomitidos: Yup.string(),
      fechaultimoavaluo: Yup.string(),
      nombreavaluador: Yup.string(),
      acreedordiferente: Yup.boolean().nullable().required(FormValidation.required),
      nombrerepresentantefideicomitente: Yup.string(),
      tipoidlegalfideicomitente: Yup.string(),
      numeroidlegalfideicomitente: Yup.string(),

    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaCertificadosGarantiaSaveService((values as ITareaSolNoMonetariaCertificadosGarantia), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad11" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocertificado">{t('Tipo de certificado')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocertificado"
                  name="tipocertificado"
                  invalid={errors.tipocertificado && touched.tipocertificado}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_CERTIFICADO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocertificado as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrebeneficiario">{t('Nombre del beneficiario-Acreedor')}</Label>
                <Field
                  as={Input}
                  id="nombrebeneficiario"
                  name="nombrebeneficiario"
                  invalid={errors.nombrebeneficiario && touched.nombrebeneficiario}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrebeneficiario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidbeneficiario">{t('Tipo Identificación del beneficiario -Acreedor')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidbeneficiario"
                  name="tipoidbeneficiario"
                  invalid={errors.tipoidbeneficiario && touched.tipoidbeneficiario}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidbeneficiario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidbeneficiario">{t('Número Identificación del beneficiario -Acreedor')}</Label>
                <Field
                  as={Input}
                  id="numeroidbeneficiario"
                  name="numeroidbeneficiario"
                  invalid={errors.numeroidbeneficiario && touched.numeroidbeneficiario}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidbeneficiario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombredeudor">{t('Nombre del deudor')}</Label>
                <Field
                  as={Input}
                  id="nombredeudor"
                  name="nombredeudor"
                  invalid={errors.nombredeudor && touched.nombredeudor}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombredeudor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoiddeudor">{t('Tipo Identificación del deudor')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoiddeudor"
                  name="tipoiddeudor"
                  invalid={errors.tipoiddeudor && touched.tipoiddeudor}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoiddeudor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroiddeudor">{t('Número Identificación del deudor')}</Label>
                <Field
                  as={Input}
                  id="numeroiddeudor"
                  name="numeroiddeudor"
                  invalid={errors.numeroiddeudor && touched.numeroiddeudor}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroiddeudor as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valorcertificado">{t('Valor del certificado')}</Label>
                <Field
                  as={NumberField}
                  id="valorcertificado"
                  name="valorcertificado"
                  invalid={errors.valorcertificado && touched.valorcertificado}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valorcertificado as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedacertificado">{t('Moneda del certificado')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedacertificado"
                  name="monedacertificado"
                  invalid={errors.monedacertificado && touched.monedacertificado}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedacertificado as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valorbienesfideicomitidos">{t('Valor de los bienes fideicomitidos')}</Label>
                <Field
                  as={NumberField}
                  id="valorbienesfideicomitidos"
                  name="valorbienesfideicomitidos"
                  invalid={errors.valorbienesfideicomitidos && touched.valorbienesfideicomitidos}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valorbienesfideicomitidos as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedabienesfideicomitidos">{t('Moneda de los bienes fideicomitidos')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedabienesfideicomitidos"
                  name="monedabienesfideicomitidos"
                  invalid={errors.monedabienesfideicomitidos && touched.monedabienesfideicomitidos}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedabienesfideicomitidos as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="fechaultimoavaluo">{t('Fecha del ultimo avalúo')}</Label>
                <Field
                  as={DateField}
                  id="fechaultimoavaluo"
                  name="fechaultimoavaluo"
                  invalid={errors.fechaultimoavaluo && touched.fechaultimoavaluo}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.fechaultimoavaluo as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombreavaluador">{t('Nombre del avaluador')}</Label>
                <Field
                  as={Input}
                  id="nombreavaluador"
                  name="nombreavaluador"
                  invalid={errors.nombreavaluador && touched.nombreavaluador}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombreavaluador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="acreedordiferente">{t('¿El acreedor es diferente a Itaú?')}</Label>
                <Field
                  as={BooleanField}
                  id="acreedordiferente"
                  name="acreedordiferente"
                  invalid={errors.acreedordiferente && touched.acreedordiferente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.acreedordiferente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrerepresentantefideicomitente">{t('Nombre del Representante Legal del Fideicomitente')}</Label>
                <Field
                  as={Input}
                  id="nombrerepresentantefideicomitente"
                  name="nombrerepresentantefideicomitente"
                  invalid={errors.nombrerepresentantefideicomitente && touched.nombrerepresentantefideicomitente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrerepresentantefideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidlegalfideicomitente">{t('Tipo Identificación Legal del Fideicomitente')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidlegalfideicomitente"
                  name="tipoidlegalfideicomitente"
                  invalid={errors.tipoidlegalfideicomitente && touched.tipoidlegalfideicomitente}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidlegalfideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidlegalfideicomitente">{t('Número Identificación Legal del Fideicomitente')}</Label>
                <Field
                  as={Input}
                  id="numeroidlegalfideicomitente"
                  name="numeroidlegalfideicomitente"
                  invalid={errors.numeroidlegalfideicomitente && touched.numeroidlegalfideicomitente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidlegalfideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad11"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad11 {
  habilitar: boolean;
}

export default FormEntidad11;