import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormText, Input, Label, Row } from 'reactstrap';
import { changeLoading } from '../contexts/actions/mainActions';
import { crearDocumentoTarea, editarDocumentoTarea, obtenerDocumentosTareas } from '../../core/services/procesos/documentoService';
import dev from '../../core/enviroments';
import { downloadFile } from '../helpers/generales';
import { useMainContext } from '../contexts/mainContext';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from 'react-i18next';
import { useTareasContext } from '../contexts/tareasContext';
import { ECategDocsTareas, ECodeFabrica } from '../utilities';
import { Field, FormikProvider, useFormik } from 'formik';
import CheckboxField from './CheckboxField';
import { usePostcontractualContext } from '../contexts/postcontractualContext';
import { IUsuarios } from '../../core/models/usuarios/usuariosModel';

const TareasDocumentos: FC<ITareasDocumentos> = ({ disabled = true, idCargadoPor, idCategoria, textButton }) => {
  const [documentos, setDocumentos] = useState<any[]>([]);
  const { handleNotification, mainDispatch } = useMainContext();
  const { userState } = useUserContext();
  const { t } = useTranslation();
  const { tarea } = useTareasContext();
  const { postRoles } = usePostcontractualContext();
  const listarDocumentos = async () => {
    const params = {
      filter: 'idtareas',
      valuefilter: tarea?.id
    };
    const documentos = await obtenerDocumentosTareas(params) || [];
    setDocumentos(documentos?.filter((doc) => {
      return idCategoria ? doc?.categoria === idCategoria : doc?.categoria === ECategDocsTareas.DOCUMENTOS_ANEXOS
    }));
  };
  const initialValues = {
    title: "",
    body: "",
  };

  const habilitarAdjuntos = useMemo(() => {

    //Habilitación comentarios y archivos para estado Aprobación Cumplimiento:
    //Tarea cliente | usuario cumplimiento gestion
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_CLI_APROBACION_CUMPLIMIENTO && !!postRoles?.cumplimiento_gestion && tarea?.tipo === "tareacliente") return true;
    //Tarea predeterminada | elaborar | usuario cumplimiento aseguramiento y control
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_APROBACION_CUMPLIMIENTO &&  !!postRoles?.cumplimiento_aseguramiento_y_control && tarea?.clasificacionoperativa === "elaborar") return true;
  
    //Habilitación comentarios y archivos para estado En proceso:
    //Tarea cliente | usuario cumplimiento gestion
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_CLI_EN_PROCESO && !!postRoles?.cumplimiento_gestion && tarea?.tipo === "tareacliente") return true;
    //Tarea predeterminada | elaborar | usuario formalizador aseguramiento
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_EN_PROCESO && !!postRoles?.formalizador_aseguramiento_y_control && tarea?.clasificacionoperativa === "elaborar") return true;
    //****Tarea predeterminada solicitar no se debe habilitar En el estado en proceso por lo tanto no se ponen más habilitadores 

    //Habilitación comentarios y archivos para estado Revisión Formalizador:
    //Tarea predeterminada | solicitar | formalizador aseguramiento
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_REVISION_FORMALIZADOR && !!postRoles?.formalizador_aseguramiento_y_control && tarea?.clasificacionoperativa === "solicitar_informacion_cliente") return true;
    //Tarea predeterminada | solicitar | usuario creador
    if (tarea?.idestados.codigofabrica === ECodeFabrica.TR_PRE_REVISION_FORMALIZADOR && (tarea?.usuariocreador as IUsuarios)?.id === userState?.data?.sub && tarea?.clasificacionoperativa === "solicitar_informacion_cliente") return true;
    
    return false;
  }, [tarea, userState, documentos, postRoles]);

  const verAdjuntos = useMemo(()=>{
    if(Number(tarea?.idestados?.codigo) === 11) return true;
    if(Number(tarea?.idestados?.codigo) === 12) return true;
    if(Number(tarea?.idestados?.codigo) === 13) return true;
    if(Number(tarea?.idestados?.codigo) === 15) return true;
    if(Number(tarea?.idestados?.codigo) === 4) return true;
    if(Number(tarea?.idestados?.codigo) === 17) return true;
    if(Number(tarea?.idestados?.codigo) === 5) return true;
    return false;
  },[tarea])

  const saveDocument = async (e) => {
    mainDispatch(changeLoading());
    const json = {
      idtareas: tarea?.id,
      idcargadopor: idCargadoPor || userState?.data?.sub,
      categoria: idCategoria || ECategDocsTareas.DOCUMENTOS_ANEXOS
    };
    const file = {
      archivo: e.target.files[0],
      ...json
    };
    if (await crearDocumentoTarea(file, handleNotification)) {
      listarDocumentos();
    }
    mainDispatch(changeLoading());
  }

  useEffect(() => {
    if (tarea) {
      listarDocumentos();
    }
  }, [tarea]);

  const enableAtachmentDoc = async (iddoc: Number)=>{

    const adjunto = documentos.find((item)=>(item.id == iddoc)).adjuntocorreo;
  
    const values = {
      id: iddoc,
      idtareas: tarea?.id,
      adjuntocorreo: !adjunto,
    }
  
    documentos.map((item)=>{
      if(item.id == iddoc){
        item.adjuntocorreo = !adjunto
      }
    })
  
    await editarDocumentoTarea(values,handleNotification);
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });
  

  return (
    <>
      <h5>{t('Documentos anexos')}</h5>
      <hr />
      <div style={{
        width: '100%',
        textAlign: 'left'
      }}>
        <Label
          className={disabled ? "btn btn-dark" : "btn btn-primary"}
          title={textButton ?? t('Cargar documento de tarea')}
          htmlFor="docTarea"
          disabled={disabled}
        >
          <i className="uil-file-upload"></i> {textButton ?? t('Cargar documento de tarea')}
        </Label>
        <Input
          style={{
            display: 'none'
          }}
          type="file"
          id="docTarea"
          name="documentoAnexo"
          accept='.pdf, .doc, .docx, .xls, .xlsx, .zip'
          onChange={saveDocument}
          disabled={disabled}
        />
      </div>
      {!!documentos.length &&
        <div className="mt-2">
          <div className="">
            {
              documentos?.map(({ nombre, id, path, nombrereal, adjuntocorreo }) => (
                <Fragment key={id}>
                  <div className="mt-2 doc-task">
                    <Row style={{flex: 1}}>
                      <Col md={8}>
                        <Button
                          style={{
                            marginRight: '1rem'
                          }}
                          size="sm"
                          onClick={() => {
                            downloadFile(`${dev.URL_BUCKET}/uploads${path}/${nombre}`, nombrereal);
                          }}
                        >
                          <i className="uil-file-download"></i>
                        </Button>
                        <FormText color="muted">{nombrereal}</FormText>
                      </Col>
                      { verAdjuntos
                      &&
                      <Col md={4}>
                        <FormikProvider value={formik}>
                        <Label className='mb-0'>{t('Correo')}</Label>
                          <Field
                              as={CheckboxField}
                              id={nombre+id}
                              name={nombre}
                              onClick={()=>{enableAtachmentDoc(id)}}
                              checked={adjuntocorreo}
                              disabled={!habilitarAdjuntos}
                          />
                        </FormikProvider>
                      </Col>
                      }
                      
                    </Row>
                  </div>
                </Fragment>
              ))
            }
          </div>
        </div>
      }
    </>
  );
};
interface ITareasDocumentos {
  disabled?: boolean;
  idCategoria?: number
  idCargadoPor?: number | null
  textButton?: string
};
export default TareasDocumentos;
