import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isUserAuthenticated } from '../shared/helpers/authUtils';

export const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const logged = () => {
    const data = isUserAuthenticated(true);
    return data?.isAutenticated
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        logged() ?
          <Component {...props} /> : <Redirect to="/login" />
      )}
    />
  )
}
