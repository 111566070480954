import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { getRequest, postRequestJson, updateItem } from "../peticionesService";

export const obtenerFirmaTerceroService = async (token: string): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_FIRMA_THIRD}${token}`, false, true);
    if (resp.data?.record) {
      data = {
        ...resp.data.record,
        documento: resp.data?.documento
      };
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};

export const obtenerFirmaInternaService = async (id: number): Promise<any> => {
  let data: any = {};
  try {
    const resp = await getRequest(`${urlApi.SHOW_FIRMA}${id}`, false, true);
    if (resp.data?.record) {
      data = {
        ...resp.data.record,
        documento: resp.data?.documento
      };
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
  return data;
};

export const enviarCodigoService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
  third = false
): Promise<boolean | any> => {
  const url = third ? urlApi.CREATE_CODIGO_THIRD : urlApi.CREATE_CODIGO;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const validarCodigoService = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
  third = false
): Promise<boolean | any> => {
  const url = third ? urlApi.VALIDATE_CODIGO_THIRD : urlApi.VALIDATE_CODIGO;
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

 export const firmarDocumentoService = async (
  dataSend: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  third = false
): Promise<any> => {
  let data = null;
  try {
    const url = urlApi.SIGN_FIRMA;
    const resp = await postRequestJson(url, dataSend);
    if (resp.data.code === 200) {
      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }    
    data = resp.data;
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return data;
};

export const listarFirmasService = async (filtername: string, filtervalue: string|number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_FIRMA}?filtername=${filtername}&filtervalue=${filtervalue}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};
