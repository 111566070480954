import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Badge, Button, ButtonGroup, Col, Collapse, FormGroup, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { IFideicominentesBeneficiarios } from '../../core/models/postcontractual/fideicomitentesModel';
import { ITareas } from '../../core/models/procesos/tareasModel';
import { IUsuarios } from '../../core/models/usuarios/usuariosModel';


import { falseLoading, openModal, trueLoading } from '../contexts/actions/mainActions';
import { useMainContext } from '../contexts/mainContext';
import { usePostcontractualContext } from '../contexts/postcontractualContext';
import { useTareasContext } from '../contexts/tareasContext';
import { useUserContext } from '../contexts/userContext';
import { useAllListas } from '../hooks/useAllListas';
import { useOptionsTables } from '../hooks/useOptionsTables';
import { ECodeFabrica } from '../utilities';
import TareaFormulario from './TareaFormulario';
import TareasComentarios from './TareasComentarios';
import TareasDocumentos from './TareasDocumentos';
import InfoAdicional from '../../pages/terceros/tokens/TokenPostFideicomitente/InfoAdicional';

const TareasTableCliente: FC<ITareasTable> = ({ tareas, handleTarea, disabled = false, crearClientes = false }) => {
  // contextos
  const { mainDispatch, mainState } = useMainContext();
  const { optionsDataTable } = useOptionsTables();
  const { obtenerLabel } = useAllListas();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { tarea, obtenerTarea, limpiarTarea } = useTareasContext();
  const { userState } = useUserContext();

  // state
  const [show, setShow] = useState<boolean>(false);
  const [habilitar, setHabilitar] = useState<boolean>(false);
  const [showAdicional, setShowAdicional] = useState<boolean>(false);
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [isOpen3, setIsOpen3] = useState<boolean>(false);
  const [isOpen4, setIsOpen4] = useState<boolean>(false);
  const [isOpen5, setIsOpen5] = useState<boolean>(false);
  const [isOpen6, setIsOpen6] = useState<boolean>(false);
  const [isOpen7, setIsOpen7] = useState<boolean>(false);
  const [isOpen8, setIsOpen8] = useState<boolean>(false);
  // Memo
  const enableInfoAdicional = useMemo(() => (
    (tarea?.idestados?.codigofabrica === ECodeFabrica.TR_CLI_RADICACION_SOLICITUD)), [tarea, userState]);

  const infoadicional = useMemo(() => {
    const { content, show } = InfoAdicional(tarea, enableInfoAdicional);
    setShowAdicional(show);
    return content;
  }, [tarea, habilitar]);

  const tareasConfirmadas = useMemo(() => (
    tareas?.filter(tarea => 
      tarea?.idestados?.codigofabrica !== ECodeFabrica.TR_CLI_RADICACION_SOLICITUD ||
      tarea?.informaciontareacompleta !== null
    )
  ), [tareas]);

  const columns: MUIDataTableColumn[] = [
     {
      name: t('ID'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('ID')}</h5>
        ),
      }
    }, 
    {
      name: t('Nombre'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Nombre')}</h5>
        ),
      }
    },
/*     {
      name: t('Avance'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Avance')}</h5>
        ),
      }
    }, */
    {
      name: t('Estado'),
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: () => (
          <h5>{t('Estado')}</h5>
        ),
        customBodyRender: (estado) => {
          return (
            <>
              {estado && <Badge color={estado === "Cerrada" ? "secondary" : "warning"}>{t(estado)}</Badge>}
            </>
          );
        }
      },
    },

  ];

  const optionData = {
    ...optionsDataTable(),
    customSearch: (searchQuery, currentRow) => {
      const rexFin = language === 'es' ? /^(f)(i|$)(n|$)(a|$)(l|$)(i|$)(z|$)(a|$)(d|$)(o|$)/ : /^(f)(i|$)(n|$)(a|$)(l|$)(i|$)(z|$)(e|$)(d|$)/;
      const rexPen = language === 'es' ? /^(p)(e|$)(n|$)(d|$)(i|$)(e|$)(n|$)(t|$)(e|$)/ : /^(p)(e|$)(n|$)(d|$)(i|$)(n|$)(g|$)/;
      let isFound = false;
      currentRow.forEach(col => {
        if (col.toString().indexOf(searchQuery) >= 0) {
          isFound = true;
        }

        if (rexFin.test(searchQuery.toLowerCase()) && (currentRow[2] === 1)) {
          isFound = true;
        }

        if (rexPen.test(searchQuery.toLowerCase()) && (currentRow[2] !== 1)) {
          isFound = true;
        }
      });
      return isFound;
    },
    viewColumns: false,
  }

  return (
    <>
      <MUIDataTable
        title={''}
        data={tareasConfirmadas?.map(item => {
          return [
            item.idTarea,
            obtenerLabel(item.nombreTarea) || t('Tarea cliente'),
            //`${item.avance}%`,
            item?.estadoTarea,
          ];
        })}
        columns={columns}
        options={optionData}
      />
    </>
  );
};

interface ITareasTable {
  tareas: ITareas[],
  handleTarea: (values: any) => void
  disabled?: any;
  crearClientes?: boolean;
};
export default TareasTableCliente;
