import { FC, lazy, ReactElement } from 'react';

// Componentes
import Dashboard from '../shared/layouts/Dashboard';
import Home from '../pages/home';
import ReporteTerceros from '../pages/reportes/terceros';
import { EGroupsNames } from '../shared/utilities';
import ReportesBiTareasClientes from "../pages/reportes/reportes-bi-tareas-clientes";
import ReportesBiTareasPredeterminadas from "../pages/reportes/reportes-bi-tareas-predeterminadas";
import ReporteBiHistorialActividadesRol from "../pages/reportes/reportes-bi-historial-actividades-rol";
import ReporteBiProcesosPostcontractuales from "../pages/reportes/reportes-bi-procesos-postcontractuales";

// Lazy componentes
const Paises = lazy(() => import('../pages/administracion/paises'));
const Departamentos = lazy(() => import('../pages/administracion/departamentos'));
const Ciudades = lazy(() => import('../pages/administracion/ciudades'));
const CategoriasContrato = lazy(() => import('../pages/administracion/categoriasContrato'));
const TiposContrato = lazy(() => import('../pages/administracion/tiposContrato'));
const Filiales = lazy(() => import('../pages/administracion/filiales'));
const CondicionesPago = lazy(() => import('../pages/administracion/condicionesPago'));
const CentroCostos = lazy(() => import('../pages/administracion/centroCostos'));
const Plantillas = lazy(() => import('../pages/administracion/plantillas'));
const Estados = lazy(() => import('../pages/administracion/estados'));
const Empresas = lazy(() => import('../pages/administracion/empresas'));
const Terceros = lazy(() => import('../pages/administracion/terceros'));
const Vicepresidencias = lazy(() => import('../pages/administracion/vicepresidencias'));
const Gerencias = lazy(() => import('../pages/administracion/gerencias'));
const Areas = lazy(() => import('../pages/administracion/areas'));
const Usuarios = lazy(() => import('../pages/usuarios'));
const ListContractual = lazy(() => import('../pages/contractual/list'));
const CreateContractual = lazy(() => import('../pages/contractual/create'));
const EditContractual = lazy(() => import('../pages/contractual/edit'));
const ListPostcontractual = lazy(() => import('../pages/postcontractual/list'));
const ListCargueMasivoPostcontractual = lazy(() => import('../pages/postcontractual/carguemasivo/list'));
const ShowCargueMasivoPostcontractual = lazy(() => import('../pages/postcontractual/carguemasivo/show'));
const CreateCargueMasivoPostcontractual = lazy(() => import('../pages/postcontractual/carguemasivo/create'));
const ListCargueMasivoTareas = lazy(() => import('../pages/tareas/carguemasivo/list'));
const ShowCargueMasivoTareas = lazy(() => import('../pages/tareas/carguemasivo/show'));
const CreateCargueMasivoTareas = lazy(() => import('../pages/tareas/carguemasivo/create'));
const CreatePostcontractual = lazy(() => import('../pages/postcontractual/create'));
const EditPostcontractual = lazy(() => import('../pages/postcontractual/edit'));
const ListAsesorias = lazy(() => import('../pages/asesorias/list'));
const CreateAsesorias = lazy(() => import('../pages/asesorias/create'));
const EditAsesorias = lazy(() => import('../pages/asesorias/edit'));
const DetailTercero = lazy(() => import('../pages/administracion/terceros/detail'));
const KpisTercero = lazy(() => import('../pages/administracion/terceros/kpis'));
const ContratoTercero = lazy(() => import('../pages/administracion/terceros/contrato'));
const ReporteContractual = lazy(() => import('../pages/reportes/contractual'));
const ReportePostcontractual = lazy(() => import('../pages/reportes/postcontractual'));
const Modulos = lazy(() => import('../pages/usuarios/modulos'));
const Roles = lazy(() => import('../pages/usuarios/roles'));
const Cargos = lazy(() => import('../pages/administracion/cargos'));

// const authRoutes: IRoutes = {
//   path: '/login',
//   name: 'Login',
//   requireAuth: false,
//   layout: Auth,
//   component: SignIn,
// };

// const thirdRoutes: IRoutes = {
//   path: '/tercero/firma/:modulo/:token',
//   name: 'Aprobación',
//   requireAuth: false,
//   layout: Third,
//   component: FirmaTercero,
// };

const ListPostcontractualRoute: IRoutes = {
  path: '/postcontractual/list',
  name: 'Postcontractual',
  header: 'Procesos',
  component: ListPostcontractual,
  grupos: [
    EGroupsNames.SUPER_USUARIO,
    EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
    EGroupsNames.EXPERIENCIA_CLIENTE_ASEGURAMIENTO_y_CONTROL,
    EGroupsNames.EXPERIENCIA_CLIENTE_GESTION,
    EGroupsNames.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL,
    EGroupsNames.CUMPLIMIENTO_GESTION,
    EGroupsNames.FORMALIZADOR_GESTION_TAREAS,
    EGroupsNames.CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL,
    EGroupsNames.CUMPLIMIENTO_ESTRUCTURACION,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.ABOGADO_EXTERNO,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
  ],
  layout: Dashboard,
  icon: 'uil-file-info-alt',
  requireAuth: true,
};

const ListCargueMasivo: IRoutes = {
  path: '/carguemasivo',
  name: 'Cargue masivo',
  header: 'Cargue masivo',
  requireAuth: true,
  grupos: [
    EGroupsNames.SUPER_USUARIO
  ],
  icon: 'uil-upload',
  children: [{
    path: '/carguemasivo/postcontractual/list',
    name: 'Postcontractual',
      component: ListCargueMasivoPostcontractual,
    grupos: [
      EGroupsNames.SUPER_USUARIO
    ],
      layout: Dashboard,
    icon: 'uil-upload',
      requireAuth: true,
  }, {
    path: '/carguemasivo/tareas/list',
    name: 'Tareas',
      component: ListCargueMasivoTareas,
    grupos: [
      EGroupsNames.SUPER_USUARIO
    ],
      layout: Dashboard,
    icon: 'uil-upload',
      requireAuth: true,
  }]
};

const ShowCargueMasivoPostcontractualRoute: IRoutes = {
  path: '/carguemasivo/postcontractual/show/:id',
  name: 'Visualizar cargue masivo postcontractual',
  component: ShowCargueMasivoPostcontractual,
  grupos: [
    EGroupsNames.SUPER_USUARIO
  ],
  layout: Dashboard,
  requireAuth: true,
};

const CreateCargueMasivoPostcontractualRoute: IRoutes = {
  path: '/carguemasivo/postcontractual/create',
  name: 'Crear cargue masivo postcontractual',
  component: CreateCargueMasivoPostcontractual,
  grupos: [
    EGroupsNames.SUPER_USUARIO
  ],
  layout: Dashboard,
  requireAuth: true
};

const ShowCargueMasivoTareasRoute: IRoutes = {
  path: '/carguemasivo/tareas/show/:id',
  name: 'Visualizar cargue masivo tareas',
  component: ShowCargueMasivoTareas,
  grupos: [
    EGroupsNames.SUPER_USUARIO
  ],
  layout: Dashboard,
  requireAuth: true,
};

const CreateCargueMasivoTareasRoute: IRoutes = {
  path: '/carguemasivo/tareas/create',
  name: 'Crear cargue masivo tareas',
  component: CreateCargueMasivoTareas,
  grupos: [
    EGroupsNames.SUPER_USUARIO
  ],
  layout: Dashboard,
  requireAuth: true
};

const CreatePostcontractualRoute: IRoutes = {
  path: '/postcontractual/create',
  name: 'Crear proceso postcontractual',
  component: CreatePostcontractual,
  grupos: [
    EGroupsNames.SUPER_USUARIO,
    EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION
  ],
  layout: Dashboard,
  requireAuth: true,
};

const EditPostcontractualRoute: IRoutes = {
  path: '/postcontractual/edit/:id',
  name: 'Modificar proceso postcontractual',
  component: EditPostcontractual,
  grupos: [
    EGroupsNames.SUPER_USUARIO,
    EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
    EGroupsNames.EXPERIENCIA_CLIENTE_ASEGURAMIENTO_y_CONTROL,
    EGroupsNames.EXPERIENCIA_CLIENTE_GESTION,
    EGroupsNames.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL,
    EGroupsNames.CUMPLIMIENTO_GESTION,
    EGroupsNames.FORMALIZADOR_GESTION_TAREAS,
    EGroupsNames.CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL,
    EGroupsNames.CUMPLIMIENTO_ESTRUCTURACION,
    EGroupsNames.COORDINADOR,
    EGroupsNames.ABOGADO,
    EGroupsNames.ABOGADO_EXTERNO,
    EGroupsNames.APROBADOR,
    EGroupsNames.TERCERO,
  ],
  layout: Dashboard,
  requireAuth: true,
};

// const ListAsesoriasRoute: IRoutes = {
//   path: '/asesorias/list',
//   name: 'Asesorías',
//   component: ListAsesorias,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
//     EGroupsNames.COORDINADOR,
//     EGroupsNames.ABOGADO,//
//     EGroupsNames.APROBADOR,
//     EGroupsNames.TERCERO,
//   ],
//   layout: Dashboard,
//   icon: 'uil-comment-alt-dots',
//   requireAuth: true,
// };

// const CreateAsesoriasRoute: IRoutes = {
//   path: '/asesorias/create',
//   name: 'Crear asesoría',
//   component: CreateAsesorias,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.ABOGADO,
//   ],
//   layout: Dashboard,
//   requireAuth: true,
// };

// const EditAsesoriasRoute: IRoutes = {
//   path: '/asesorias/edit/:id',
//   name: 'Modificar asesoría',
//   component: EditAsesorias,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
//     EGroupsNames.COORDINADOR,
//     EGroupsNames.ABOGADO,//
//     EGroupsNames.APROBADOR,
//     EGroupsNames.TERCERO,
//   ],
//   layout: Dashboard,
//   requireAuth: true,
// };

// const DetailTerceroRoute: IRoutes = {
//   path: '/terceros/detail/:id',
//   name: 'Detalle de tercero',
//   component: DetailTercero,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//   ],
//   layout: Dashboard,
//   requireAuth: true,
// };

// const KpisTerceroRoute: IRoutes = {
//   path: '/terceros/kpis/:id',
//   name: 'KPIs de tercero',
//   component: KpisTercero,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//   ],
//   layout: Dashboard,
//   requireAuth: true,
// };

// const ContratoTerceroRoute: IRoutes = {
//   path: '/terceros/contrato/:id',
//   name: 'Contrato de tercero',
//   component: ContratoTercero,
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//   ],
//   layout: Dashboard,
//   requireAuth: true,
// };

// const contractualRoutes: IRoutes[] = [
//   {
//     path: '/contractual/create',
//     name: 'Crear Proceso',
//     icon: 'uil-home-alt',
//     component: CreateContractual,
//     grupos: [
//       EGroupsNames.SUPER_USUARIO,
//       EGroupsNames.SUPER_USUARIO,
//       EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
//       EGroupsNames.COORDINADOR,
//       EGroupsNames.ABOGADO,//
//       EGroupsNames.APROBADOR,
//       EGroupsNames.TERCERO,
//     ],
//     layout: Dashboard,
//     requireAuth: true,
//   },
//   {
//     path: '/contractual/edit/:id',
//     name: 'Proceso Contractual',
//     icon: 'uil-home-alt',
//     component: EditContractual,
//     grupos: [
//       EGroupsNames.SUPER_USUARIO,
//       EGroupsNames.SUPER_USUARIO,
//       EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
//       EGroupsNames.COORDINADOR,
//       EGroupsNames.ABOGADO,//
//       EGroupsNames.APROBADOR,
//       EGroupsNames.TERCERO,
//     ],
//     layout: Dashboard,
//     requireAuth: true,
//   }
// ];

// const reportRoutes: IRoutes = {
//   path: '/reportes',
//   header: 'Reportes',
//   name: 'Generales',
//   grupos: [
//     EGroupsNames.SUPER_USUARIO,
//     EGroupsNames.SUPER_USUARIO,
//   ],
//   icon: 'uil-file-download',
//   children: [
//     {
//       path: '/reportes/contractual',
//       name: 'Contractual',
//       component: ReporteContractual,
//       grupos: [
//         EGroupsNames.SUPER_USUARIO,
//         EGroupsNames.SUPER_USUARIO,
//       ],
//       layout: Dashboard,
//       requireAuth: true,
//     },
//     {
//       path: '/reportes/postcontractual',
//       name: 'Postcontractual',
//       component: ReportePostcontractual,
//       grupos: [
//         EGroupsNames.SUPER_USUARIO,
//         EGroupsNames.SUPER_USUARIO,
//       ],
//       layout: Dashboard,
//       requireAuth: true,
//     },
//     {
//       path: '/reportes/terceros',
//       name: 'Terceros',
//       component: ReporteTerceros,
//       grupos: [
//         EGroupsNames.SUPER_USUARIO,
//         EGroupsNames.SUPER_USUARIO,
//       ],
//       layout: Dashboard,
//       requireAuth: true,
//     },
//   ]
// }

const reportsBIRoutes: IRoutes = {
  path: "/reportes-bi",
  header: "Comforce® Reports",
  name: "Comforce® Reports",
  grupos: [EGroupsNames.SUPER_USUARIO, EGroupsNames.REPORTES],
  icon: "uil-file-download",
  children: [
    {
      path: "/reportes-bi/tareas-clientes",
      name: "Tareas Clientes",
      component: ReportesBiTareasClientes,
      grupos: [EGroupsNames.SUPER_USUARIO, EGroupsNames.REPORTES],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: "/reportes-bi/tareas-predeterminadas",
      name: "Tareas Predeterminadas",
      component: ReportesBiTareasPredeterminadas,
      grupos: [EGroupsNames.SUPER_USUARIO, EGroupsNames.REPORTES],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: "/reportes-bi/historial-actividades-rol",
      name: "Historial Actividades Rol",
      component: ReporteBiHistorialActividadesRol,
      grupos: [EGroupsNames.SUPER_USUARIO, EGroupsNames.REPORTES],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: "/reportes-bi/procesos-postcontractuales",
      name: "Procesos Postcontractuales",
      component: ReporteBiProcesosPostcontractuales,
      grupos: [EGroupsNames.SUPER_USUARIO, EGroupsNames.REPORTES],
      layout: Dashboard,
      requireAuth: true,
    } /*,
    {
      path: '/reportes/postcontractual',
      name: 'Postcontractual',
      component: ReportePostcontractual,
      grupos: [
        EGroupsNames.SUPER_USUARIO,
        EGroupsNames.SUPER_USUARIO,
      ],
      layout: Dashboard,
      requireAuth: true,
    },
    {
      path: '/reportes/terceros',
      name: 'Terceros',
      component: ReporteTerceros,
      grupos: [
        EGroupsNames.SUPER_USUARIO,
        EGroupsNames.SUPER_USUARIO,
      ],
      layout: Dashboard,
      requireAuth: true,
    },*/,
  ],
};



const menuRoutes: IRoutes[] = [
  {
    path: '/home',
    name: 'Inicio',
    header: 'Principal',
    icon: 'uil-home-alt',
    grupos: [
      EGroupsNames.SUPER_USUARIO,
      EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
      EGroupsNames.EXPERIENCIA_CLIENTE_ASEGURAMIENTO_y_CONTROL,
      EGroupsNames.EXPERIENCIA_CLIENTE_GESTION,
      EGroupsNames.FORMALIZADOR_ASEGURAMIENTO_Y_CONTROL,
      EGroupsNames.CUMPLIMIENTO_GESTION,
      EGroupsNames.FORMALIZADOR_GESTION_TAREAS,
      EGroupsNames.CUMPLIMIENTO_ASEGURAMIENTOY_CONTROL,
      EGroupsNames.CUMPLIMIENTO_ESTRUCTURACION,
      EGroupsNames.COORDINADOR,
      EGroupsNames.ABOGADO,
      EGroupsNames.ABOGADO_EXTERNO,
      EGroupsNames.APROBADOR,
      EGroupsNames.TERCERO,
    ],
    component: Home,
    layout: Dashboard,
    requireAuth: true,
  },
  // {
  //   path: '/contractual/list',
  //   name: 'Contractual',
  //   header: 'Procesos',
  //   // grupos: [1, 2, 3, 4, 5, 6, 7, 10, 11, 13],
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION,
  //     EGroupsNames.COORDINADOR,
  //     EGroupsNames.ABOGADO,
  //
  //     EGroupsNames.APROBADOR,
  //     EGroupsNames.TERCERO,
  //   ],
  //   component: ListContractual,
  //   layout: Dashboard,
  //   icon: 'uil-file-alt',
  //   requireAuth: true,
  // },
  ListPostcontractualRoute,
  reportsBIRoutes,
  ListCargueMasivo,
  // ListAsesoriasRoute,
  // {
  //   path: '/estructura-org',
  //   name: 'Estructura Organizacional',
  //   requireAuth: true,
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   header: 'Administración',
  //   icon: 'uil-building',
  //   children: [
  //     {
  //       name: 'Filial',
  //       path: '/estructura-org/filial/',
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       requireAuth: true,
  //       children: [{
  //         path: '/estructura-org/filial/list',
  //         name: 'Lista de filiales',
  //         component: Filiales,
  //         grupos: [
  //           EGroupsNames.SUPER_USUARIO,
  //           EGroupsNames.SUPER_USUARIO,
  //         ],
  //         layout: Dashboard,
  //         requireAuth: true,
  //       },]
  //     },
  //     {
  //       name: 'Empresa',
  //       path: '/estructura-org/empresa/',
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       requireAuth: true,
  //       children: [
  //         {
  //           path: '/estructura-org/empresa/list',
  //           name: 'Lista de empresas',
  //           component: Empresas,
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           layout: Dashboard,
  //           requireAuth: true,
  //         }
  //       ]
  //     },
  //     {
  //       name: 'Vicepresidencia',
  //       path: '/estructura-org/vicepresidencia/',
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       requireAuth: true,
  //       children: [
  //         {
  //           path: '/estructura-org/vicepresidencia/list',
  //           name: 'Lista de vicepresidencias',
  //           component: Vicepresidencias,
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           layout: Dashboard,
  //           requireAuth: true,
  //         },
  //         {
  //           name: 'Gerencia',
  //           path: '/estructura-org/vicepresidencia/gerencia',
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           layout: Dashboard,
  //           requireAuth: true,
  //           children: [
  //             {
  //               path: '/estructura-org/vicepresidencia/gerencia/list',
  //               name: 'Lista de gerencias',
  //               component: Gerencias,
  //               grupos: [
  //                 EGroupsNames.SUPER_USUARIO,
  //                 EGroupsNames.SUPER_USUARIO,
  //               ],
  //               layout: Dashboard,
  //               requireAuth: true,
  //             },
  //             {
  //               name: 'Área',
  //               path: '/estructura-org/vicepresidencia/gerencia/area',
  //               grupos: [
  //                 EGroupsNames.SUPER_USUARIO,
  //                 EGroupsNames.SUPER_USUARIO,
  //               ],
  //               requireAuth: true,
  //               children: [
  //                 {
  //                   path: '/estructura-org/vicepresidencia/gerencia/area/list',
  //                   name: 'Lista de áreas',
  //                   component: Areas,
  //                   grupos: [
  //                     EGroupsNames.SUPER_USUARIO,
  //                     EGroupsNames.SUPER_USUARIO,
  //                   ],
  //                   layout: Dashboard,
  //                   requireAuth: true,
  //                 },
  //               ]
  //             },
  //           ]
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   name: 'Estructura geográfica',
  //   path: '/estructura-geo',
  //   icon: 'uil-map',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   requireAuth: true,
  //   children: [
  //     {
  //       name: 'País',
  //       path: '/estructura-geo/pais',
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       requireAuth: true,
  //       children: [
  //         {
  //           name: 'Lista de paises',
  //           path: '/estructura-geo/pais/list',
  //           component: Paises,
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           layout: Dashboard,
  //           requireAuth: true,
  //         },
  //         {
  //           name: 'Departamento',
  //           path: '/estructura-geo/pais/departamento',
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           requireAuth: true,
  //           children: [
  //             {
  //               path: '/estructura-geo/pais/departamento/list',
  //               name: 'Lista de departamentos',
  //               component: Departamentos,
  //               grupos: [
  //                 EGroupsNames.SUPER_USUARIO,
  //                 EGroupsNames.SUPER_USUARIO,
  //               ],
  //               layout: Dashboard,
  //               requireAuth: true,
  //             },
  //             {
  //               name: 'Ciudad',
  //               path: '/estructura-geo/pais/departamento/ciudad',
  //               grupos: [
  //                 EGroupsNames.SUPER_USUARIO,
  //                 EGroupsNames.SUPER_USUARIO,
  //               ],
  //               requireAuth: true,
  //               children: [
  //                 {
  //                   path: '/estructura-geo/pais/departamento/ciudad/list',
  //                   name: 'Lista de ciudades',
  //                   component: Ciudades,
  //                   grupos: [
  //                     EGroupsNames.SUPER_USUARIO,
  //                     EGroupsNames.SUPER_USUARIO,
  //                   ],
  //                   layout: Dashboard,
  //                   requireAuth: true,
  //                 },
  //               ]
  //             },
  //           ]
  //         }]
  //     },
  //   ]
  // },
  // {
  //   name: 'Categoría de Contrato',
  //   path: '/contratos/categorias-contrato',
  //   icon: 'uil-archive',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   requireAuth: true,
  //   children: [
  //     {
  //       path: '/contratos/categorias-contrato/list',
  //       name: 'Lista de categorías',
  //       component: CategoriasContrato,
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       layout: Dashboard,
  //       requireAuth: true,
  //     },
  //     {
  //       name: 'Tipo de Contrato',
  //       path: '/contratos/categorias-contrato/tipo-contrato',
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       requireAuth: true,
  //       children: [
  //         {
  //           path: '/contratos/categorias-contrato/tipo-contrato/list',
  //           name: 'Lista de tipos',
  //           component: TiposContrato,
  //           grupos: [
  //             EGroupsNames.SUPER_USUARIO,
  //             EGroupsNames.SUPER_USUARIO,
  //           ],
  //           layout: Dashboard,
  //           requireAuth: true,
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   name: 'Otros',
  //   path: '/otros',
  //   requireAuth: true,
  //   icon: 'uil-cog',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   children: [
  //     {
  //       path: '/otros/condiciones-pago',
  //       name: 'Condiciones de pago',
  //       component: CondicionesPago,
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       layout: Dashboard,
  //       requireAuth: true,
  //     },
  //     {
  //       path: '/otros/estados',
  //       name: 'Estados',
  //       component: Estados,
  //       grupos: [
  //         EGroupsNames.SUPER_USUARIO,
  //       ],
  //       layout: Dashboard,
  //       requireAuth: true,
  //     },
  //   ]
  // },
  // {
  //   path: '/centro-costos',
  //   name: 'Centro de costos',
  //   component: CentroCostos,
  //   icon: 'uil-box',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   layout: Dashboard,
  //   requireAuth: true,
  // },
  // {
  //   path: '/plantillas',
  //   name: 'Plantillas',
  //   component: Plantillas,
  //   icon: 'uil-file-copy-alt',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   layout: Dashboard,
  //   requireAuth: true,
  // },
  // // ]
  // reportRoutes,
  // {
  //   path: '/terceros',
  //   name: 'Terceros',
  //   header: 'Gestión de terceros',
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   icon: 'uil-briefcase',
  //   component: Terceros,
  //   layout: Dashboard,
  //   requireAuth: true,
  // },
  {
    path: '/usuarios',
    name: 'Usuarios',
    header: 'Gestión de usuarios',
    component: Usuarios,
    grupos: [
      EGroupsNames.SUPER_USUARIO,
      EGroupsNames.EXPERIENCIA_CLIENTE_ESTRUCTURACION
    ],
    layout: Dashboard,
    icon: 'uil-users-alt',
    requireAuth: true,
  },
  // {
  //   path: '/modulos',
  //   name: 'Módulos',
  //   component: Modulos,
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   layout: Dashboard,
  //   icon: 'uil-folder-exclamation',
  //   requireAuth: true,
  // },
  // {
  //   path: '/roles',
  //   name: 'Roles',
  //   component: Roles,
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   layout: Dashboard,
  //   icon: 'uil-user-exclamation',
  //   requireAuth: true,
  // },
  // {
  //   path: '/cargos',
  //   name: 'Cargos',
  //   component: Cargos,
  //   grupos: [
  //     EGroupsNames.SUPER_USUARIO,
  //   ],
  //   layout: Dashboard,
  //   icon: 'uil-cloud-database-tree',
  //   requireAuth: true,
  // },
];

export const dashboardRoutes = [
  ...menuRoutes,
];

export const routes: IRoutes[] = [
  // authRoutes,
  // thirdRoutes,
  ...menuRoutes,
  // ...contractualRoutes,
  CreatePostcontractualRoute,
  ShowCargueMasivoPostcontractualRoute,
  CreateCargueMasivoPostcontractualRoute,
  ShowCargueMasivoTareasRoute,
  CreateCargueMasivoTareasRoute,
  EditPostcontractualRoute,
  // CreateAsesoriasRoute,
  // EditAsesoriasRoute,
  // DetailTerceroRoute,
  // KpisTerceroRoute,
  // ContratoTerceroRoute
];

// interfaces de las rutas
export interface IRoutes {
  path?: string;
  name: string;
  header?: string;
  layout?: any;
  component?: any;
  requireAuth?: boolean;
  icon?: string;
  grupos?: string[];
  children?: this[];
};
export interface IRenderRoutes {
  routes: IRoutes[]
};
