import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaCreacionTercero } from "../../../../core/models/tareas/tareaSolNoMonetariaCreacionTerceroModel";
import { tareaSolNoMonetariaCreacionTerceroListService, tareaSolNoMonetariaCreacionTerceroSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaCreacionTerceroService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad17: FC<IFormEntidad17> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaCreacionTercero|null>(null);
const obtenerData = async () => {
    const data = await tareaSolNoMonetariaCreacionTerceroListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      primernombretercero: data?.primernombretercero || '',
      segundonombretercero: data?.segundonombretercero || '',
      primerapellidotercero: data?.primerapellidotercero || '',
      segundoapellidotercero: data?.segundoapellidotercero || '',
      tipopersonatercero: data?.tipopersonatercero || '',
      tiporegimentercero: data?.tiporegimentercero || '',
      tipoidtercero: data?.tipoidtercero || '',
      numeroidtercero: data?.numeroidtercero || '',
      ciuutercero: data?.ciuutercero || '',
      ciudadtercero: data?.ciudadtercero || '',
      direcciontercero: data?.direcciontercero || '',
      telefonotercero: data?.telefonotercero || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      primernombretercero: Yup.string().required(FormValidation.required),
      segundonombretercero: Yup.string(),
      primerapellidotercero: Yup.string(),
      segundoapellidotercero: Yup.string(),
      tipopersonatercero: Yup.string(),
      tiporegimentercero: Yup.string(),
      tipoidtercero: Yup.string(),
      numeroidtercero: Yup.string(),
      ciuutercero: Yup.string(),
      ciudadtercero: Yup.string(),
      direcciontercero: Yup.string(),
      telefonotercero: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaCreacionTerceroSaveService((values as ITareaSolNoMonetariaCreacionTercero), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad17" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="primernombretercero">{t('Primer nombre')}</Label>
                <Field
                  as={Input}
                  id="primernombretercero"
                  name="primernombretercero"
                  invalid={errors.primernombretercero && touched.primernombretercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.primernombretercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="segundonombretercero">{t('Segundo nombre')}</Label>
                <Field
                  as={Input}
                  id="segundonombretercero"
                  name="segundonombretercero"
                  invalid={errors.segundonombretercero && touched.segundonombretercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.segundonombretercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="primerapellidotercero">{t('Primer apellido')}</Label>
                <Field
                  as={Input}
                  id="primerapellidotercero"
                  name="primerapellidotercero"
                  invalid={errors.primerapellidotercero && touched.primerapellidotercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.primerapellidotercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="segundoapellidotercero">{t('Segundo apellido')}</Label>
                <Field
                  as={Input}
                  id="segundoapellidotercero"
                  name="segundoapellidotercero"
                  invalid={errors.segundoapellidotercero && touched.segundoapellidotercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.segundoapellidotercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipopersonatercero">{t('Tipo de Persona')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipopersonatercero"
                  name="tipopersonatercero"
                  invalid={errors.tipopersonatercero && touched.tipopersonatercero}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(16).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipopersonatercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tiporegimentercero">{t('Tipo de Régimen')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tiporegimentercero"
                  name="tiporegimentercero"
                  invalid={errors.tiporegimentercero && touched.tiporegimentercero}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_REGIMEN).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tiporegimentercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidtercero">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidtercero"
                  name="tipoidtercero"
                  invalid={errors.tipoidtercero && touched.tipoidtercero}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidtercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidtercero">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidtercero"
                  name="numeroidtercero"
                  invalid={errors.numeroidtercero && touched.numeroidtercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidtercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="ciuutercero">{t('Código CIIU')}</Label>
                <Field
                  as={NumberField}
                  id="ciuutercero"
                  name="ciuutercero"
                  invalid={errors.ciuutercero && touched.ciuutercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.ciuutercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="ciudadtercero">{t('Ciudad')}</Label>
                <Field
                  as={Input}
                  id="ciudadtercero"
                  name="ciudadtercero"
                  invalid={errors.ciudadtercero && touched.ciudadtercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.ciudadtercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="direcciontercero">{t('Dirección')}</Label>
                <Field
                  as={Input}
                  id="direcciontercero"
                  name="direcciontercero"
                  invalid={errors.direcciontercero && touched.direcciontercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.direcciontercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="telefonotercero">{t('Teléfono')}</Label>
                <Field
                  as={NumberField}
                  id="telefonotercero"
                  name="telefonotercero"
                  invalid={errors.telefonotercero && touched.telefonotercero}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.telefonotercero as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad17"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad17 {
  habilitar: boolean;
}

export default FormEntidad17;