import React, { useEffect } from 'react';
import Router from './router/index';
// Contextos
import { UserProvider, MainProvider, ContractualProvider } from './shared/contexts';
import { SnackbarProvider } from 'notistack';
// Estilos
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/scss/_app.scss";
import { NotificationProvider } from './shared/contexts/notificationContext';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import { DndProvider } from 'react-dnd-multi-backend';
import { PostcontractualProvider } from './shared/contexts/postcontractualContext';
import { CargueMasivoProvider } from './shared/contexts/cargueMasivoContext';
import { AsesoriasProvider } from './shared/contexts/asesoriasContext';
import { FirmaProvider } from './shared/contexts/firmaContext';
import { TareasProvider } from './shared/contexts/tareasContext';
import { ModalProvider } from './shared/contexts/modalContext';

const App = () => {
  useEffect(() => {
    const doc: Element | null = document.querySelector('.splash');
    !doc || doc.classList.remove("active");
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      hideIconVariant
    >
      <UserProvider>
        <MainProvider>
          <FirmaProvider>
            <NotificationProvider>
              <ContractualProvider>
                <PostcontractualProvider>
                  <AsesoriasProvider>
                    <TareasProvider>
                      <ModalProvider>
                        <CargueMasivoProvider>
                          <DndProvider options={HTML5toTouch}>
                              {/* <DndProvider backend={HTML5Backend}> */}
                            <Router />
                          </DndProvider>
                        </CargueMasivoProvider>
                      </ModalProvider>
                    </TareasProvider>
                  </AsesoriasProvider>
                </PostcontractualProvider>
              </ContractualProvider>
            </NotificationProvider>
          </FirmaProvider>
        </MainProvider>
      </UserProvider>
    </SnackbarProvider>
  );
}

export default App;
