import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaSoporte } from "../../../../core/models/tareas/tareaSolNoMonetariaSoporteModel";
import { tareaSolNoMonetariaSoporteListService, tareaSolNoMonetariaSoporteSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaSoporteService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { formatStringtoDateString } from '../../../../shared/helpers/Fecha';

const FormEntidad25: FC<IFormEntidad25> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaSoporte | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolNoMonetariaSoporteListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tiposoporte: data?.tiposoporte || '',
      fechasoporte: data?.fechasoporte ? formatStringtoDateString(data?.fechasoporte) : null || '',
      monto: data?.monto || '',
      tipomoneda: data?.tipomoneda || '',
      nombrebeneficiario: data?.nombrebeneficiario || '',
      tipoidentificacion: data?.tipoidentificacion || '',
      numeroidentificacion: data?.numeroidentificacion || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tiposoporte: Yup.string().required(FormValidation.required),
      fechasoporte: Yup.string(),
      monto: Yup.string(),
      tipomoneda: Yup.string(),
      nombrebeneficiario: Yup.string(),
      tipoidentificacion: Yup.string(),
      numeroidentificacion: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaSoporteSaveService((values as ITareaSolNoMonetariaSoporte), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad25" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tiposoporte">{t('Tipo de soporte')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tiposoporte"
                  name="tiposoporte"
                  invalid={errors.tiposoporte && touched.tiposoporte}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_SOPORTE).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tiposoporte as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="fechasoporte">{t('Fecha del soporte')}</Label>
                <Field
                  as={DateField}
                  id="fechasoporte"
                  name="fechasoporte"
                  invalid={errors.fechasoporte && touched.fechasoporte}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.fechasoporte as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monto">{t('Monto')}</Label>
                <Field
                  as={NumberField}
                  id="monto"
                  name="monto"
                  invalid={errors.monto && touched.monto}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.monto as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipomoneda">{t('Moneda')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipomoneda"
                  name="tipomoneda"
                  invalid={errors.tipomoneda && touched.tipomoneda}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipomoneda as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrebeneficiario">{t('Nombre del beneficiario del pago')}</Label>
                <Field
                  as={Input}
                  id="nombrebeneficiario"
                  name="nombrebeneficiario"
                  invalid={errors.nombrebeneficiario && touched.nombrebeneficiario}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrebeneficiario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidentificacion">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidentificacion"
                  name="tipoidentificacion"
                  invalid={errors.tipoidentificacion && touched.tipoidentificacion}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidentificacion as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidentificacion">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidentificacion"
                  name="numeroidentificacion"
                  invalid={errors.numeroidentificacion && touched.numeroidentificacion}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidentificacion as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad25"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad25 {
  habilitar: boolean;
}

export default FormEntidad25;