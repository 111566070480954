import React, { createContext, useState } from "react";
import { listarFirmasService, obtenerFirmaInternaService, obtenerFirmaTerceroService } from "../../core/services/firmas/firmaService";

export interface IFirmaContext {
  loadedFirma: boolean;
  loading: boolean;
  firma: any;
  activeStep: number;
  values: any;
  codigo: number;
  imagen: string;
  coordenadas: {
    x: number
    y: number
  };
  firmas: any;
  getFirmaTercero: (token: string) => void;
  getFirmaInterna: (id: number) => void;
  resetFirma: () => void;
  setLoading: (loading: boolean) => void;
  setLoadedFirma: (state: boolean) => void;
  setActiveStep: (step: number) => void;
  setValues: (values: any) => void;
  setCodigo: (codigo: any) => void;
  setImagen: (imagen: string) => void;
  setCoordenadas: (coordenadas: {
    x: number
    y: number
  }) => void;
  listarFirmas: (filtername: string, filtervalue: string|number) => void;
  obtenerFirmas: (filtername: string, filtervalue: string|number) => any;
};

export const firmaContext = createContext<IFirmaContext | undefined>(undefined);

export const FirmaProvider = ({ children }) => {

  // State
  const [ loadedFirma, setLoadedFirma ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ values, setValues ] = useState<any>(undefined);
  const [ firma, setFirma ] = useState<any>(undefined);
  const [ documentpath, setDocumentpath ] = useState<any>(undefined);
  const [ codigo, setCodigo ] = useState<any>(undefined);
  const [ imagen, setImagen ] = useState<string>('');
  const [ coordenadas, setCoordenadas ] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 });
  const [ activeStep, setActiveStep ] = useState<number>(1);
  const [ firmas, setFirmas ] = useState<any>(undefined);

  

  // GET FIRMA
  const getFirmaTercero = async (token: string) => {

    // Firma
    const firma = await obtenerFirmaTerceroService(token);
    setFirma(firma || null);

    setLoadedFirma(true);
  }

  // GET FIRMA
  const getFirmaInterna = async (id: number) => {

    // Firma
    const firma = await obtenerFirmaInternaService(id);
    setFirma(firma || null);

    setLoadedFirma(true);
  }

  // GET FIRMAS
  const listarFirmas = async (filtername: string, filtervalue: string|number) => {

    // Firma
    const firmas = await listarFirmasService(filtername, filtervalue);
    setFirmas(firmas || []);

  }

  // GET FIRMAS
  const obtenerFirmas = async (filtername: string, filtervalue: string|number) => {

    const firmas = await listarFirmasService(filtername, filtervalue);
    return firmas || [];

  }

  // RESET
  const resetFirma = () => {
    setFirma(undefined);
    setLoadedFirma(true);
  }

  return (
    <firmaContext.Provider
      value={{
        loadedFirma,
        loading,
        firma,
        activeStep,
        values,
        codigo,
        imagen,
        coordenadas,
        firmas,
        getFirmaTercero,
        getFirmaInterna,
        resetFirma,
        setLoadedFirma,
        setLoading,
        setActiveStep,
        setValues,
        setCodigo,
        setImagen,
        setCoordenadas,
        listarFirmas,
        obtenerFirmas
      }}
    >
      {children}
    </firmaContext.Provider>
  );
}

export const useFirmaContext = () => {
  const context = React.useContext(firmaContext)
  if (context === undefined) {
    throw new Error('useFirmaContext must be used within a FirmaProvider')
  }
  return context
};
