import { getRequest, postRequest, putRequest, updateItem } from '../peticionesService';
import urlApi from '../../api';
import { IFormPostcontractual, IForo, IPostcontractual } from '../../models/postcontractual/postcontractualModel';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import moment from 'moment';
import { ILiquidacion } from '../../models/postcontractual/liquidacionModel';
import { ISuspension } from '../../models/postcontractual/suspensionModel';
import { IUsuarios } from '../../models/usuarios/usuariosModel';
import { IContraCondicionesPago } from '../../models/contractual/condicionesPagoModel';
import { IContraUsuarios, IFormContraUsuarios } from '../../models/contractual/usuariosModel';
import { formatStringtoDateString } from '../../../shared/helpers/Fecha';
import { IAprobaciones } from '../../models/contractual/contractualModel';

/**
 * Permite obtener los registros en la bd
 */
export const obtenerProcesosPostcontratuales = async (): Promise<IPostcontractual[]> => {
  let data: IPostcontractual[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POSTCONTRACTUAL);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite chequear la unicidad del codigo del contrato 
 */
export const checkCodigo = async (
  values,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
  ): Promise<any> => {
  let data: IPostcontractual[] = [];
  try {
    const resp = await postRequest(urlApi.CHECK_CODIGO_POSTCONTRACTUAL,values);
    if (resp.data.code === 200) {
      data = resp?.data?.record || resp?.data?.records;
      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
    
    data = resp.data;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por criterio en la bd
 */
export const obtenerPostUsuarioByCriterio = async (id: number, params?: any): Promise<any> => {
  let data: any = null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_POST_USUARIOS}${id}`, params);
    data = resp.data.record;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerPostcontractualUsuarios = async (filter?: string, valuefilter?: string): Promise<IContraUsuarios[]> => {
  let data: IContraUsuarios[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_POSTCONTRACTUAL_USUARIOS, filter ? { filter: filter, valuefilter: valuefilter } : {});
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerProcesoPostcontractual = async (id: number): Promise<any> => {
  let data: any = undefined;
  try {
    const resp = await getRequest(`${urlApi.SHOW_POSTCONTRACTUAL}${id}`);
    data = {
      ...resp.data.record,
      liquidaciones: resp.data?.liquidacion.map((liquidacion: ILiquidacion) => ({
        ...liquidacion,
        fecha: liquidacion?.fecha,
      })),
      adendas: resp.data?.adendas,
      suspensiones: resp.data?.suspension.map((suspension: ISuspension): ISuspension => ({
        ...suspension,
        idsolicitante: (suspension?.idsolicitante) ? `${(suspension.idsolicitante as IUsuarios)?.nombres} ${(suspension.idsolicitante as IUsuarios)?.apellidos}` : '',
        inicio: formatStringtoDateString(suspension?.inicio).toString(),
        final: formatStringtoDateString(suspension?.final).toString(),
        solicitud: formatStringtoDateString(suspension?.solicitud).toString(),
      })),
      renovaciones: resp.data?.renovacion,
      polizas: resp?.data?.polizas || [],
      reinicios: resp?.data?.reinicio || [],
      pathadenda: resp?.data?.pathadenda ? `${resp?.data?.pathadenda}?${moment().unix()}` : null || null,
      pathacta: `${resp?.data?.pathacta}?${moment().unix()}` || null,
      pathliquidacion: `${resp?.data?.pathliquidacion}?${moment().unix()}` || null,
    };

    data = {
      ...data,
      path: resp?.data?.pathpdf
    }
  } catch (error) {
    console.error(error);
  }
  // console.log(data);

  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentariosForo = async (params?: any): Promise<IForo[]> => {
  let data: IForo[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_COMMENTS_POSTCONTRACTUAL, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarComentarioForo = async (
  values: IForo,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMMENT_POSTCONTRACTUAL;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearProcesoPoscontractual = async (
  values: IFormPostcontractual,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<IFormPostcontractual | null> => {
  const url = urlApi.CREAR_POSTCONTRACTUAL;
  let data: any = null;
  try {
    const resp = await postRequest(url, values);
    if (resp.data.code === 200) {
      data = resp?.data?.record || resp?.data?.records;
      notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const editarProcesoPoscontractual = async (
  values: IPostcontractual,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true,
  requestData = false,
): Promise<boolean> => {
  const url = `${urlApi.EDIT_POSTCONTRACTUAL}${values.id}`
  return await updateItem(url, values, notification, file, sendNotification, requestData);
};

export const aprobarAprobador = async (
  data: any,
  id: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = `${urlApi.APROBAR_POST_APROBADOR}${id}`;
  let success = false;
  try {
    const resp: any = await putRequest(url, data);
    if (resp?.data?.code === 200) {
      success = true;
      sendNotification && notification(resp.data.message, mainTypeMsj.SUCCESS);
    } else {
      notification(resp.data.message, mainTypeMsj.ERROR);
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

/**
* Permite crear y editar un registro
* @param values valores del formulario
* @param notification funcion que permite generar una notificacion en el sistema 
*/
export const aprobarActa = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = urlApi.POST_APROBAR_ACTA
  return await updateItem(url, values, notification, sendNotification);
};

export const aprobarProceso = async (
  values: IAprobaciones,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
): Promise<boolean> => {
  const url = (values?.id) ? `${urlApi.APROBAR_PROCESO_POST_EDIT}${values?.id}` : `${urlApi.APROBAR_PROCESO_POST}`;
  let success = false;
  try {
    if (await updateItem(url, values, notification)) {
      success = true;
    }
  } catch (error) {
    console.error(error);
    notification('Error inesperado', mainTypeMsj.ERROR);
  }
  return success;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerListaUsuariosAprob = async (id: string | number): Promise<any[]> => {
  let data: any[] = [];
  try {
    const resp = await getRequest(`${urlApi.LIST_USUARIOS_APROBACIONES_POST}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentarios = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.COMENT_POSTCONTRACTUAL;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear un comentario
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const crearComentario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMENT_POSTCONTRACTUAL;
  return await updateItem(url, values, notification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarPostcontractualUsuarios = async (
  values: IFormContraUsuarios,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREAR_POSTCONTRACTUAL_USUARIOS : `${urlApi.EDIT_POSTCONTRACTUAL_USUARIOS}${values.id}`
  return await updateItem(url, values, notification, false, sendNotification);
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema
 */
export const guardarPostcontractualUsuariosMulti = async (
  values: {users: object[]},
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true
): Promise<boolean> => {
  const url = urlApi.CREAR_POSTCONTRACTUAL_USUARIOS_MULTI
  return await updateItem(url, values, notification, false, sendNotification);
};


export const continuarProcesoPostcontractual = async (
  idproceso: number,
  tipo: "guardar_y_avanzar" | "guardar" = "guardar_y_avanzar",
  acta: number | null = null,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  sendNotification = true,
  requestData = false,
): Promise<boolean> => {
  const values = !!acta ? { id: idproceso, actainicio: acta, _tipo_edicion: tipo } : { id: idproceso, _tipo_edicion: tipo }
  const url = `${urlApi.EDIT_POSTCONTRACTUAL}${values.id}`
  return await updateItem(url, values, notification, false, sendNotification, requestData);
}