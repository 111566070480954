import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolMonetariaAclaracion } from "../../../../core/models/tareas/tareaSolMonetariaAclaracionModel";
import { tareaSolMonetariaAclaracionListService, tareaSolMonetariaAclaracionSaveService } from "../../../../core/services/tareas/tareaSolMonetariaAclaracionService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad9: FC<IFormEntidad9> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaAclaracion | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolMonetariaAclaracionListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      numerocuentaoencargo: data?.numerocuentaoencargo || '',
      fechamovimiento: data?.fechamovimiento || '',
      monto: data?.monto || '',
      monedamonto: data?.monedamonto || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      numerocuentaoencargo: Yup.string().required(FormValidation.required),
      fechamovimiento: Yup.string(),
      monto: Yup.string(),
      monedamonto: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaAclaracionSaveService((values as ITareaSolMonetariaAclaracion), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad9" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentaoencargo">{t('Número de cuenta y/o Encargo')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentaoencargo"
                  name="numerocuentaoencargo"
                  invalid={errors.numerocuentaoencargo && touched.numerocuentaoencargo}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerocuentaoencargo as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="fechamovimiento">{t('Fecha del movimiento')}</Label>
                <Field
                  as={DateField}
                  id="fechamovimiento"
                  name="fechamovimiento"
                  invalid={errors.fechamovimiento && touched.fechamovimiento}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.fechamovimiento as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monto">{t('Monto')}</Label>
                <Field
                  as={NumberField}
                  id="monto"
                  name="monto"
                  invalid={errors.monto && touched.monto}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.monto as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedamonto">{t('Moneda')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedamonto"
                  name="monedamonto"
                  invalid={errors.monedamonto && touched.monedamonto}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedamonto as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad9"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad9 {
  habilitar: boolean;
}

export default FormEntidad9;