import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { ITareaSolNoMonetariaEstadosFinancieros } from "../../../../core/models/tareas/tareaSolNoMonetariaEstadosFinancierosModel";
import { tareaSolNoMonetariaEstadosFinancierosListService, tareaSolNoMonetariaEstadosFinancierosSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaEstadosFinancierosService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { formatStringtoDateString } from '../../../../shared/helpers/Fecha';

const FormEntidad14: FC<IFormEntidad14> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaEstadosFinancieros | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolNoMonetariaEstadosFinancierosListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  console.log("DATA",data);

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      fechacortecertificado: data?.fechacortecertificado ? formatStringtoDateString(data.fechacortecertificado) : '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      fechacortecertificado: Yup.string().required(FormValidation.required),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaEstadosFinancierosSaveService((values as ITareaSolNoMonetariaEstadosFinancieros), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad14" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="fechacortecertificado">{t('Generar certificado con corte a')}</Label>
                <Field
                  as={DateField}
                  id="fechacortecertificado"
                  name="fechacortecertificado"
                  invalid={errors.fechacortecertificado && touched.fechacortecertificado}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.fechacortecertificado as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad14"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad14 {
  habilitar: boolean;
}

export default FormEntidad14;