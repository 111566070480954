import { useFormikContext } from 'formik';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'reactstrap';
import { mainContext } from '../contexts';
import { closeModal } from '../contexts/actions/mainActions';
import ButtonCustom from './ButtonCustom';

const ModalsFooter: FC<IModalsFooter> = ({ idForm, disabled = false }) => {
  // contextos
  const { mainState, mainDispatch } = useContext(mainContext);
  const { t } = useTranslation();
  const contextForm = useFormikContext();

  return (
    <ModalFooter>
      {!disabled &&
          (
          <ButtonCustom
            form={idForm}
            className="d-flex align-items-center"
            type="submit"
            color="primary"
            loading={contextForm?.isSubmitting || mainState.loading}
            disabled={contextForm?.isSubmitting || mainState.loading}
          >
            {t('Guardar')}
          </ButtonCustom>
        )
      }
      <ButtonCustom
        className="d-flex align-items-center"
        color="danger"
        disabled={contextForm?.isSubmitting || mainState.loading}
        onClick={() => mainDispatch(closeModal())}
      >
        {
          disabled ? t('Cerrar') : t('Cancelar')
        }
      </ButtonCustom>
    </ModalFooter>
  );
};
interface IModalsFooter {
  idForm: string;
  disabled?: any
};
export default ModalsFooter;
