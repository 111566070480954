import React, { FC, useState, useContext, useMemo, useReducer } from "react";
import {
  Button,
  ButtonGroup,
  Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import Main from '../components/Main';
import Wrapper from '../components/Wrapper';
import Content from '../components/Content';
import { ILayout } from "../utilities";
import Footer from "../components/Footer";
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import { mainContext } from "../contexts";
import logo from '../../assets/img/logos/logo.png';
import logoItau from '../../assets/img/logos/itau_logoM.png';
import enFlag from '../../assets/flags/us.jpg';
import colFlag from '../../assets/flags/col.png';
import { changeLanguage, openModal } from "../contexts/actions/mainActions";
import { useTranslation } from "react-i18next";
import mainReducer, { mainInitialState } from "../contexts/reducers/mainReducer";

const Languages = [
  {
    name: 'Español',
    flag: colFlag,
    language: 'es'
  },
  {
    name: 'English',
    flag: enFlag,
    language: 'en'
  },
];

const Third: FC<ILayout> = ({ children }) => {

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const [state, dispatch] = useReducer(mainReducer, mainInitialState());
  const { mainState, mainDispatch } = useContext(mainContext);
  const [langSelected, setLanguage] = useState<any>(Languages.find(lang => lang.language === mainState?.language) || Languages[0]);
  const ListLanguages = useMemo(() => Languages.filter(lang => langSelected?.name !== lang.name), [langSelected]);

  console.log('i18n-->', i18n.language);

  const handleLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('locale', lang);
  }

  return (
    (
      <Wrapper>
        <Main className="d-flex flex-column justify-content-between">
          <div>
            <Content >
              <Container>
                <Header className="d-flex flex-md-row justify-content-md-between align-items-center mb-4">
                  {!!mainState.header?.title && <HeaderTitle tag="h2">{mainState.header?.title}</HeaderTitle>}
                  <ButtonGroup size="sm" className="align-items-center">
                    {Languages.map((lng, i) => (
                      <Button key={i} outline={lng.language !== i18n.language} color="primary" onClick={() => handleLang(lng.language)}>
                        <img src={lng.flag} alt={lng.name} className="mr-1" height="12" />
                        <span>{lng.name}</span>
                      </Button>
                    ))}
                  </ButtonGroup>
                  <div>
                    <img src={logoItau} alt="Comforce" width="80" />
                    <img src={logo} alt="Comforce" className="img-fluid" width="150" height="44" />
                  </div>
                </Header>
                {children}
              </Container>
            </Content>
          </div>
          <Footer />
        </Main>
      </Wrapper>
    )
  )
};
export default Third;
