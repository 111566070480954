import { getRequest, updateItem, deleteItem, postRequest } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ITareaSolMonetariaGirosPagosMasivos } from '../../models/tareas/tareaSolMonetariaGirosPagosMasivosModel';

/**
 * Permite actualizar / crear un registro en la bd
 */
export const tareaSolMonetariaGirosPagosMasivosSaveService = async (
  values: ITareaSolMonetariaGirosPagosMasivos,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<ITareaSolMonetariaGirosPagosMasivos | undefined> => {
  const url = values.id ? `${urlApi.EDIT_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS}${values.id}` : `${urlApi.CREATE_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS}`;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data?.record) return resp?.data?.record;
  else return resp;
};

/**
 * Permite obtener los registros en la bd
 */
export const tareaSolMonetariaGirosPagosMasivosListService = async (params: any = {}): Promise<ITareaSolMonetariaGirosPagosMasivos[]> => {
  let data: ITareaSolMonetariaGirosPagosMasivos[] = [];
  try {
    const resp = !params._busqueda ? await getRequest(urlApi.LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS, params) 
      : await postRequest(urlApi.LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const tareaSolMonetariaGirosPagosMasivosShowService = async (id: number): Promise<ITareaSolMonetariaGirosPagosMasivos | null> => {
  let data: ITareaSolMonetariaGirosPagosMasivos | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};