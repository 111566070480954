import React, { FC } from 'react';
import Iframe from 'react-iframe';
// Reactstrap
import {
  Row,
  Col,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';

const ReporteProcesosPostcontractuales: FC =  () => {

  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <h1>{t('Reporte de Procesos Postcontractuales')}</h1>
        <Iframe
          url={'https://lookerstudio.google.com/embed/reporting/cef95528-3364-4e6d-b4dd-8bf976f962c0/page/tEnnC'}
          width={'100%'}
          height={'900'}
          frameBorder={0}
          allowFullScreen={true}
          sandbox={['allow-storage-access-by-user-activation','allow-scripts','allow-same-origin','allow-popups','allow-popups-to-escape-sandbox','allow-downloads'] as any}
        >
          Debe habilitar los Iframes para poder ver éste contenido</Iframe>
        </Col>
    </Row>
  )
}

export default ReporteProcesosPostcontractuales;

