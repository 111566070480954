import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolMonetariaGirosPagosMasivos } from "../../../../core/models/tareas/tareaSolMonetariaGirosPagosMasivosModel";
import { tareaSolMonetariaGirosPagosMasivosListService, tareaSolMonetariaGirosPagosMasivosSaveService } from "../../../../core/services/tareas/tareaSolMonetariaGirosPagosMasivosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad5: FC<IFormEntidad5> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaGirosPagosMasivos|null>(null);
const obtenerData = async () => {
    const data = await tareaSolMonetariaGirosPagosMasivosListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      tipolote: data?.tipolote || '',
      numerolote: data?.numerolote || '',
      nombrelote: data?.nombrelote || '',
      fechaaplicacion: data?.fechaaplicacion || '',
      valorlote: data?.valorlote || '',
      monedalote: data?.monedalote || '',
      cantidadregistroslote: data?.cantidadregistroslote || '',
      tipoorigen: data?.tipoorigen || '',
      numeroorigen: data?.numeroorigen || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      tipolote: Yup.string().required(FormValidation.required),
      numerolote: Yup.string(),
      nombrelote: Yup.string(),
      fechaaplicacion: Yup.string(),
      valorlote: Yup.string(),
      monedalote: Yup.string(),
      cantidadregistroslote: Yup.string(),
      tipoorigen: Yup.string(),
      numeroorigen: Yup.string(),

    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaGirosPagosMasivosSaveService((values as ITareaSolMonetariaGirosPagosMasivos), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad5" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipolote">{t('Tipo de lote')}</Label>
                <Field
                  as={Input}
                  id="tipolote"
                  name="tipolote"
                  invalid={errors.tipolote && touched.tipolote}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.tipolote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerolote">{t('Número de lote')}</Label>
                <Field
                  as={NumberField}
                  id="numerolote"
                  name="numerolote"
                  invalid={errors.numerolote && touched.numerolote}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerolote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrelote">{t('Nombre del lote')}</Label>
                <Field
                  as={Input}
                  id="nombrelote"
                  name="nombrelote"
                  invalid={errors.nombrelote && touched.nombrelote}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrelote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="fechaaplicacion">{t('Fecha de aplicación')}</Label>
                <Field
                  as={DateField}
                  id="fechaaplicacion"
                  name="fechaaplicacion"
                  invalid={errors.fechaaplicacion && touched.fechaaplicacion}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.fechaaplicacion as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valorlote">{t('Valor del lote')}</Label>
                <Field
                  as={NumberField}
                  id="valorlote"
                  name="valorlote"
                  invalid={errors.valorlote && touched.valorlote}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valorlote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedalote">{t('Moneda del lote')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedalote"
                  name="monedalote"
                  invalid={errors.monedalote && touched.monedalote}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedalote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="cantidadregistroslote">{t('Cantidad de registros del lote')}</Label>
                <Field
                  as={NumberField}
                  id="cantidadregistroslote"
                  name="cantidadregistroslote"
                  invalid={errors.cantidadregistroslote && touched.cantidadregistroslote}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.cantidadregistroslote as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoorigen">{t('Tipo de origen')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoorigen"
                  name="tipoorigen"
                  invalid={errors.tipoorigen && touched.tipoorigen}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_DE_ORIGEN).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroorigen">{t('Número (Cuenta / Encargo) Origen')}</Label>
                <Field
                  as={NumberField}
                  id="numeroorigen"
                  name="numeroorigen"
                  invalid={errors.numeroorigen && touched.numeroorigen}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad5"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad5 {
  habilitar: boolean;
}

export default FormEntidad5;