import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { CustomInput, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select, { GroupedOptionsType, OptionTypeBase, OptionsType } from 'react-select';

const MultiSelectField: FC<IMultiSelectField> = ({ 
  invalid = false,
  disabled = false,
  options,
  ...props
}) => {
  const { setFieldValue, getFieldProps, setFieldTouched } = useFormikContext();
  const { t } = useTranslation();

  const getValues = () => {
    const defaultValues = getFieldProps(props.name)?.value
    return defaultValues?.map(item => ({
      label: options.find(opt => opt.value === item)?.label,
      value: options.find(opt => opt.value === item)?.value,
    }));
  }

  const [selectValue, setSelectValue] = useState<any>(getValues() || []);

  useEffect(() => {
    setSelectValue(getValues())
  }, [options])
  
  return (
    <Select
      closeMenuOnSelect={false}
      defaultValue={getFieldProps(props.name)?.value}
      isMulti
      isSearchable
      placeholder={t('Seleccione')}
      noOptionsMessage={() => t('No hay opciones')}
      classNamePrefix="select-field"
      className={invalid ? "multi-select-field is-invalid" : "multi-select-field"}
      options={options.filter(option => !selectValue?.includes(option.value))}
      {...props}
      isDisabled={disabled}
      onChange={(e) => {
        setSelectValue(e);
        setFieldValue(props.name, e?.map(item => item.value) || []);
      }}
      onBlur={() => {
        setFieldTouched(props.name, true);
      }}
      value={selectValue}
    />
  )
}

interface IMultiSelectField {
  field: any;
  options: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase>;
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
  id?: string;
}

export default MultiSelectField;