
export interface IItemsLista {
    id: number
    actualizado: string | null
    creado: string | null
    itemlista: string | number
    listadesplegable: object
    informacionadicional?: IInfoAdicional | null
    nombreitem: string
}

export interface IInfoAdicional {
    clasificacion_nivel_riesgo?: string
    clasificacion_operativa?: string
}

export interface IListasItau {
    id: number
    actualizado: string | null
    creado: string | null
    habilitada: boolean | number | null
    itemslista: IItemsLista[]
    itemlistapadre: IItemsLista | null
    nombreitem: string | number
    numerolista: number
}

export enum EListaDesplegables {
    OBJETO_ADENDA = 1,
    AREAS = 2,
    POLIZAS_GARANTIAS = 3,
    TIPO_POLIZAS = 4,
    TIPOS_DE_MONEDA = 5,
    TIPOS_PLAZO = 6,
    ESQUEMAS_FORMALIZACION = 7,
    TIPO_DE_FIDUCIA = 8,
    TIPO_DE_TERCEROS = 9,
    SUBTIPO_DE_FIDUCIA = 10,
    TIPO_DE_BIEN = 11,
    SUBTIPO_DE_FIDUCIA_DETALLADO = 12,
    PERIODICIDAD = 13,
    TIPO_DE_SOLICITUD = 14,
    TIPO_DE_PERSONA = 16,
    TIPO_IDENTIFICACION = 17,
    TIPO_DOCUMENTO_A_PAGAR = 18,
    MEDIO_DE_PAGO = 19,
    TIPO_CUENTA_DESTINO = 20,
    TIPO_DE_ORIGEN = 21,
    TIPO_DE_CERTIFICADO = 22,
    TIPO_DE_TRAMITE = 23,
    TIPO_DE_REGIMEN = 24,
    TIPO_DE_PODER = 25,
    CLASE_DE_ACTO = 26,
    TIPO_DE_REUNION = 27,
    MODALIDAD_REUNION = 28,
    TIPO_DE_SOPORTE = 29,
    NOMBRE_DE_TAREA = 30,
    CLASIFICACION_OPERATIVA = 31,
    CLASIFICACION_SEGUN_NIVEL_RIESGO = 32,
    PERIODICIDAD_2 = 33,
    ABOGADOS_INTERNOS = 34,
    ABOGADOS_EXTERNOS = 35,
    CLASIFICACIONES_SUBTIPOLOGIA_NO_MONETARIAS = 36,
    CLASIFICACIONES_SUBTIPOLOGIA_MONETARIAS = 38,
    COMISION_FIDUCIARIA = 100000,
    PRODUCTOS_APERTURAR = 100001,
    AMPARO_CUMPLIMIENTO_CONTRATO = 301,
    AMPARO_RESPONSABILIDAD_CIVIL = 303,
    AMPARO_SERIEDAD_OFERTA = 306,
    AMPARO_ACCIDENTES_PERSONALES = 307,
    AMPARO_TRANSPORTE_MERCANCIAS = 308
}