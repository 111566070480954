import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables, IItemsLista } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolMonetariaGirosPagosTerceros } from "../../../../core/models/tareas/tareaSolMonetariaGirosPagosTercerosModel";
import { tareaSolMonetariaGirosPagosTercerosListService, tareaSolMonetariaGirosPagosTercerosSaveService } from "../../../../core/services/tareas/tareaSolMonetariaGirosPagosTercerosService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";
import { IPostcontractual } from '../../../../core/models/postcontractual/postcontractualModel';
import { obtenerProcesoPostcontractual } from '../../../../core/services/postcontractual/postcontractualService';
import { IProductsAperturar } from '../../../../core/models/postcontractual/productosAperturarModel';
import { SyntheticInputEvent } from 'react-number-format';

const FormEntidad6: FC<IFormEntidad6> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaGirosPagosTerceros | null>(null);
  const [proceso, setProceso] = useState<IPostcontractual|null>(null);
  const [productos, setProductos] = useState<IProductsAperturar[] | null>(null);
  const [listaCuentaDestino, setListaCuentaDestino] = useState<IItemsLista[]>([]);
  const obtenerData = async () => {
    const data = await tareaSolMonetariaGirosPagosTercerosListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  const obtenerProceso = async () => {
    const data = await obtenerProcesoPostcontractual((tarea?.idpostcontractual as IPostcontractual)?.id || 0);
    setProceso(data);
  }

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
      obtenerProceso();
    }
  }, [tarea])

  const cuentaAutomatica = useMemo(() => {
    console.log('tarea', tarea)
    return (
      tarea?.tiposolicitud === 'monetaria' &&
      (tarea?.subtiposolicitud === 'pagosaterceros' || tarea?.subtiposolicitud === 'giros') &&
      tarea?.clasificacionsubtipologia === 'masdeunregistroplanilla'
    );
  }, [tarea])

  useEffect(() => {
    if (cuentaAutomatica) {
      const productos = proceso?.productosaperturar;
      setProductos(productos || []);
      const lista = obtenerLista(EListaDesplegables.TIPO_CUENTA_DESTINO);
      const listaFiltrada = lista.filter(item => productos?.find(prod => prod.tipo === item.itemlista));
      setListaCuentaDestino(listaFiltrada);
    } else {
      const lista = obtenerLista(EListaDesplegables.TIPO_CUENTA_DESTINO);
      setListaCuentaDestino(lista);
    }
  }, [tarea, proceso])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      numeroplanilla: data?.numeroplanilla || '',
      cantidadregistrosplanilla: data?.cantidadregistrosplanilla || '',
      valorplanilla: data?.valorplanilla || '',
      tipocuentaorigen: data?.tipocuentaorigen || '',
      numero: data?.numero || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      numeroplanilla: Yup.string().required(FormValidation.required),
      cantidadregistrosplanilla: Yup.string(),
      valorplanilla: Yup.string(),
      tipocuentaorigen: Yup.string(),
      numero: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaGirosPagosTercerosSaveService((values as ITareaSolMonetariaGirosPagosTerceros), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  return (
    <>
      <Form id="FormEntidad6" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroplanilla">{t('Número Planilla')}</Label>
                <Field
                  as={Input}
                  id="numeroplanilla"
                  name="numeroplanilla"
                  invalid={errors.numeroplanilla && touched.numeroplanilla}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroplanilla as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="cantidadregistrosplanilla">{t('Cantidad Registros Planilla')}</Label>
                <Field
                  as={NumberField}
                  id="cantidadregistrosplanilla"
                  name="cantidadregistrosplanilla"
                  invalid={errors.cantidadregistrosplanilla && touched.cantidadregistrosplanilla}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.cantidadregistrosplanilla as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valorplanilla">{t('Valor Planilla')}</Label>
                <Field
                  as={NumberField}
                  id="valorplanilla"
                  name="valorplanilla"
                  invalid={errors.valorplanilla && touched.valorplanilla}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valorplanilla as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocuentaorigen">{t('Tipo de Cuenta de Orígen')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocuentaorigen"
                  name="tipocuentaorigen"
                  invalid={errors.tipocuentaorigen && touched.tipocuentaorigen}
                  disabled={!habilitar}
                  onChange={(e: SyntheticInputEvent) => {
                    setFieldValue(e.target.name, e.target.value);
                    if (cuentaAutomatica) {
                      const prod = productos?.find(prod => prod.tipo === e.target.value);
                      setFieldValue('numero', prod?.numeroproducto || '');
                    }
                  }}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    listaCuentaDestino.map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocuentaorigen as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numero">{t('Número')}</Label>
                <Field
                  as={NumberField}
                  id="numero"
                  name="numero"
                  invalid={errors.numero && touched.numero}
                  disabled={!habilitar || cuentaAutomatica}
                />
                <FormFeedback>{t(errors.numero as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad6"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad6 {
  habilitar: boolean;
}

export default FormEntidad6;