import { getRequest, updateItem, deleteItem, postRequest } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ITareaSolMonetariaGirosPagosTerceros } from '../../models/tareas/tareaSolMonetariaGirosPagosTercerosModel';

/**
 * Permite actualizar / crear un registro en la bd
 */
export const tareaSolMonetariaGirosPagosTercerosSaveService = async (
  values: ITareaSolMonetariaGirosPagosTerceros,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<ITareaSolMonetariaGirosPagosTerceros | undefined> => {
  const url = values.id ? `${urlApi.EDIT_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS}${values.id}` : `${urlApi.CREATE_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS}`;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data?.record) return resp?.data?.record;
  else return resp;
};

/**
 * Permite obtener los registros en la bd
 */
export const tareaSolMonetariaGirosPagosTercerosListService = async (params: any = {}): Promise<ITareaSolMonetariaGirosPagosTerceros[]> => {
  let data: ITareaSolMonetariaGirosPagosTerceros[] = [];
  try {
    const resp = !params._busqueda ? await getRequest(urlApi.LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS, params) 
      : await postRequest(urlApi.LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const tareaSolMonetariaGirosPagosTercerosShowService = async (id: number): Promise<ITareaSolMonetariaGirosPagosTerceros | null> => {
  let data: ITareaSolMonetariaGirosPagosTerceros | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS}${id}`);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};