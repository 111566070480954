import { mainTypeMsj } from "../../../shared/contexts/interfaces/mainInterfaces";
import urlApi from "../../api";
import { ICrearToken, ITareas, ITareasDashboard } from "../../models/procesos/tareasModel";
import { ITokens } from '../../models/usuarios/tokensModel';
import { getRequest, updateItem } from "../peticionesService";

/**
 * Permite obtener los registros en la bd
 */
export const obtenerTareas = async (params: any = undefined): Promise<ITareas[]> => {
  let data: ITareas[] = [];
  try {
    const url = urlApi.LIST_TAREAS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearTarea = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = (!values.id) ? urlApi.CREATE_TAREAS : `${urlApi.EDIT_TAREAS}${values.id}`;
  return await updateItem(url, values, notification);
};


/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearTokenCliente = async (
  values: ICrearToken,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<ITokens> => {
  const url = urlApi.CREATE_TOKEN_TAREA_CLIENTE;
  return await updateItem(url, values, notification, undefined, false, true);
};


/**
 * Permite obtener los registros en la bd
 */
export const obtenerComentarios = async (params: any = undefined): Promise<any[]> => {
  let data: any[] = [];
  try {
    const url = urlApi.LIST_COMMENT_TAREAS;
    const resp = await getRequest(url, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite crear y editar un registro
 * @param values valores del formulario
 * @param notification funcion que permite generar una notificacion en el sistema 
 */
export const crearComentario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = urlApi.CREATE_COMMENT_TAREAS;
  return await updateItem(url, values, notification);
};

export const editarComentario = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<boolean> => {
  const url = `${urlApi.EDIT_COMMENT_TAREAS}${values.id}`;
  return await updateItem(url, values, notification);
};

export const validacionGenerarOTP = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<any> => {
  const url = urlApi.VALIDACION_GENERAR_OTP;
  return await updateItem(url, values, notification);
}

export const validacionConfirmarOTP = async (
  values: any,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
): Promise<any> => {
  const url = urlApi.VALIDACION_CONFIRMAR_OTP;
  return await updateItem(url, values, notification, false, false, true);
}

/**
 * Permite obtener los registros en la bd
 */
export const dashboardTareas = async (params: any = undefined): Promise<ITareasDashboard> => {
  let data: ITareasDashboard = {cliente: [], predeterminadas: [], negocios: [], total: {cliente: 0, predeterminadas: 0, negocios: 0}};
  const url = urlApi.DASHBOARD_TAREAS;
  const resp = await getRequest(url, params);
  data = resp.data.records;
  return data;
};
