import urlApi from "../../api";
import { IItemsLista, IListasItau } from "../../models/administracion/listasItauModels";
import { getRequest } from "../peticionesService";

export const getlista = async (idlista: number) => {
  let data: IItemsLista[] = [];
  try {
    const resp = await getRequest(`${urlApi.SHOW_LISTAS}${idlista}`);
    data = resp?.data?.records?.itemslista;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener los registros en la bd
 */
export const listasDesplegablesListService = async (
  params = {}
): Promise<IListasItau[]> => {
  let data: IListasItau[] = [];
  try {
    const resp = await getRequest(urlApi.LIST_LISTAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};
