import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import es from "date-fns/locale/es";
import { formatStringtoDateString } from '../helpers/Fecha';
registerLocale("es", es);

const DateField: FC<IDateField> = ({ invalid = false, disabled = false, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const value = props.value?.length === 10 ? `${props.value}T00:00:00` : props.value;
  return (
    <ReactDatePicker
      name={props.name}
      dateFormat="yyyy-MM-dd"
      dropdownMode="select"      
      autoComplete='off'
      selected={props.value ? new Date(value) : null}
      className={invalid ? "form-control is-invalid" : "form-control"}
      wrapperClassName={invalid ? "wrapper-is-invalid is-invalid" : ""}
      popperPlacement="top-start"
      disabled={disabled}
      locale="es"
      {...props}
      onChange={(date: Date) => {
        if (date instanceof Date) {
          setFieldValue(props.name, formatStringtoDateString(date, "YYYY-MM-DD"))
        } else {
          setFieldValue(props.name, '')
        }
      }}
    />
  )
}

interface IDateField {
  field: any;
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
}

export default DateField;
