import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaPromesaCompraventa } from "../../../../core/models/tareas/tareaSolNoMonetariaPromesaCompraventaModel";
import { tareaSolNoMonetariaPromesaCompraventaListService, tareaSolNoMonetariaPromesaCompraventaSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaPromesaCompraventaService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad21: FC<IFormEntidad21> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaPromesaCompraventa | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolNoMonetariaPromesaCompraventaListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      nombrecomprador: data?.nombrecomprador || '',
      tipoidcomprador: data?.tipoidcomprador || '',
      numeroidcomprador: data?.numeroidcomprador || '',
      inmueblecompraventa: data?.inmueblecompraventa || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      nombrecomprador: Yup.string().required(FormValidation.required),
      tipoidcomprador: Yup.string(),
      numeroidcomprador: Yup.string(),
      inmueblecompraventa: Yup.string(),

    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaPromesaCompraventaSaveService((values as ITareaSolNoMonetariaPromesaCompraventa), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad21" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrecomprador">{t('Nombre del comprador')}</Label>
                <Field
                  as={Input}
                  id="nombrecomprador"
                  name="nombrecomprador"
                  invalid={errors.nombrecomprador && touched.nombrecomprador}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrecomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidcomprador">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidcomprador"
                  name="tipoidcomprador"
                  invalid={errors.tipoidcomprador && touched.tipoidcomprador}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidcomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidcomprador">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidcomprador"
                  name="numeroidcomprador"
                  invalid={errors.numeroidcomprador && touched.numeroidcomprador}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidcomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="inmueblecompraventa">{t('Inmueble objeto de la compra-venta')}</Label>
                <Field
                  as={Input}
                  id="inmueblecompraventa"
                  name="inmueblecompraventa"
                  invalid={errors.inmueblecompraventa && touched.inmueblecompraventa}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.inmueblecompraventa as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad21"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad21 {
  habilitar: boolean;
}

export default FormEntidad21;