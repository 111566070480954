import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Collapse, Row } from 'reactstrap';
import { IFideicominentesBeneficiarios } from '../../../../core/models/postcontractual/fideicomitentesModel';
import { useTareasContext } from '../../../../shared/contexts/tareasContext';
import { useUserContext } from '../../../../shared/contexts/userContext';
import FormTareasPredeterminadas from './FormTareasPredeterminadas';
import { ITareas } from '../../../../core/models/procesos/tareasModel';
import { ECodeFabrica } from '../../../../shared/utilities';
import TareasDocumentos from '../../../../shared/components/TareasDocumentos';
import TareasComentarios from '../../../../shared/components/TareasComentarios';
import { falseLoading, trueLoading } from '../../../../shared/contexts/actions/mainActions';
import { useMainContext } from '../../../../shared/contexts/mainContext';
import ButtonCustom from '../../../../shared/components/ButtonCustom';

const TokenTareasPredeterminadas: FC<ITokenTareasPredeterminadas> = ({ fideicomitente, idtarea }) => {
  // Context
  const { mainDispatch, mainState } = useMainContext();
  const { tarea, obtenerTarea, cambiarEstado } = useTareasContext();
  const { userState } = useUserContext();
  const { t } = useTranslation();

  // State
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);

  // Memo
  const habilitar = useMemo(() => {
    if (!tarea) return true;
    else if (tarea?.idestados?.codigofabrica === 'CliRadicacionSolicitud') return true;
    return false;
  }, [tarea, userState]);

  const habilitarValidacionCliente = useMemo(() => {
    return (tarea?.idestados?.codigofabrica === ECodeFabrica.TR_PRE_VALIDACION_CLIENTE) ;
  }, [tarea]);

  useEffect(() => {
    if (idtarea?.id) {
      obtenerTarea(idtarea.id);
    }
  }, [idtarea])

  const handleCambiarEstado = async () => {
    mainDispatch(trueLoading());
    await cambiarEstado(tarea?.id || 0);
    await obtenerTarea(tarea?.id || 0);
    mainDispatch(falseLoading());
  }

  return (
    <>
      <FormTareasPredeterminadas fideicomitente={fideicomitente} habilitar={!tarea} inComforceView={false} />
      <>
        <div className="callout">
          <Button onClick={() => setIsOpen2(!isOpen2)} color="link">
            {t('Documentos y comentarios')?.toUpperCase()}
          </Button>
        </div>
        <Collapse isOpen={isOpen2}>
          <Row>
            <Col sm={12} md={6}>
              <TareasDocumentos
                idCargadoPor={1}
                disabled={!habilitarValidacionCliente}
                textButton={t('Documentos evidencia de la realización de la solicitud')}
              />
            </Col>
            <Col sm={12} md={6}>
              <TareasComentarios disabled={!habilitarValidacionCliente} />
            </Col>
            {!!tarea?.comentariosinformacioncompleta &&
              <Col sm={12} md={6}>
                <h5>Comentarios devolución</h5>
                {tarea?.comentariosinformacioncompleta}
              </Col>
            }
            {habilitarValidacionCliente &&
              <Col sm={{size: 8, offset: 2}}>
                <ButtonCustom
                  block
                  color="secondary"
                  className="float-right mt-5"
                  type="button"
                  onClick={handleCambiarEstado}
                  loading={mainState.loading}
                  disabled={mainState.loading}
                >
                  {t('Guardar')}
                </ButtonCustom>
              </Col>
            }
          </Row>
        </Collapse>
      </>
    </>
  );
}

interface ITokenTareasPredeterminadas {
  fideicomitente: IFideicominentesBeneficiarios;
  idtarea: ITareas;
}

export default TokenTareasPredeterminadas;