import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaCesionesPreventa } from "../../../../core/models/tareas/tareaSolNoMonetariaCesionesPreventaModel";
import { tareaSolNoMonetariaCesionesPreventaListService, tareaSolNoMonetariaCesionesPreventaSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaCesionesPreventaService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad20: FC<IFormEntidad20> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaCesionesPreventa | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolNoMonetariaCesionesPreventaListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      nombrecedente: data?.nombrecedente || '',
      tipoidcedente: data?.tipoidcedente || '',
      numeroidcedente: data?.numeroidcedente || '',
      nombrecesionario: data?.nombrecesionario || '',
      tipoidcesionario: data?.tipoidcesionario || '',
      numeroidcesionario: data?.numeroidcesionario || '',
      inmueblecesion: data?.inmueblecesion || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      nombrecedente: Yup.string().required(FormValidation.required),
      tipoidcedente: Yup.string(),
      numeroidcedente: Yup.string(),
      nombrecesionario: Yup.string(),
      tipoidcesionario: Yup.string(),
      numeroidcesionario: Yup.string(),
      inmueblecesion: Yup.string(),

    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaCesionesPreventaSaveService((values as ITareaSolNoMonetariaCesionesPreventa), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad20" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrecedente">{t('Nombre completo del cedente')}</Label>
                <Field
                  as={Input}
                  id="nombrecedente"
                  name="nombrecedente"
                  invalid={errors.nombrecedente && touched.nombrecedente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrecedente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidcedente">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidcedente"
                  name="tipoidcedente"
                  invalid={errors.tipoidcedente && touched.tipoidcedente}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidcedente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidcedente">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidcedente"
                  name="numeroidcedente"
                  invalid={errors.numeroidcedente && touched.numeroidcedente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidcedente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrecesionario">{t('Nombre del cesionario')}</Label>
                <Field
                  as={Input}
                  id="nombrecesionario"
                  name="nombrecesionario"
                  invalid={errors.nombrecesionario && touched.nombrecesionario}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrecesionario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidcesionario">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidcesionario"
                  name="tipoidcesionario"
                  invalid={errors.tipoidcesionario && touched.tipoidcesionario}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidcesionario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidcesionario">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidcesionario"
                  name="numeroidcesionario"
                  invalid={errors.numeroidcesionario && touched.numeroidcesionario}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidcesionario as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="inmueblecesion">{t('Inmueble objeto de la cesión')}</Label>
                <Field
                  as={Input}
                  id="inmueblecesion"
                  name="inmueblecesion"
                  invalid={errors.inmueblecesion && touched.inmueblecesion}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.inmueblecesion as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad20"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad20 {
  habilitar: boolean;
}

export default FormEntidad20;