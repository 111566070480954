import React, { FC, useState } from 'react';
import dev from '../core/enviroments';
import { Button } from 'reactstrap';
import { listasDesplegablesListService } from '../core/services/administracion/listasItauService';
import { random } from 'lodash';

const SucuriTest: FC = () => {
  const [count, setCount] = useState(0);
  const [finished, setFinished] = useState(0);

  const exampleGetEndpoint = async () => {
    setCount((value) => {
      return value + 1
    });
    await listasDesplegablesListService({ random: Math.random() });
    setFinished((value) => {
      return value + 1
    });
  }

  const makeSimpleRequest = async () => {
    exampleGetEndpoint();
  }

  const makeSimultaneousRequests = async () => {
    for (var i = 0; i < 15; i++) {
      exampleGetEndpoint();
    }
  }

  const makeSequentialRequests = async () => {
    for (var i = 0; i < 15; i++) {
      await exampleGetEndpoint();
    }
  }

  const makeAllTests = () => {
    makeSimpleRequest();
    makeSimultaneousRequests();
    makeSequentialRequests();
  }

  return (
    <>
      <h3>Performance test</h3>
      <p>Please make click in the next buttons to test multiple request to backend IP ({dev.URL_SERVER})</p>
      <p>Total requests initiated: {count}</p>
      <p>Total requests completed: {finished}</p>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={makeSimpleRequest}>Make simple request</Button>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={makeSimultaneousRequests}>Make simultaneous requests</Button>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Button onClick={makeSequentialRequests}>Make sequential requests</Button>
      </div>
      <div style={{ marginBottom: 10 }} color="warning">
        <Button onClick={makeAllTests}>Make all test</Button>
      </div>
    </>
  )
}

export default SucuriTest;
