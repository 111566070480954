import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { CustomInput, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CheckboxField: FC<ICheckboxField> = ({ 
  invalid = false,
  disabled = false,
  ...props
}) => {
  const { setFieldValue, values: any , getFieldProps } = useFormikContext();
  const { t } = useTranslation();
  return (
    
    <div className={invalid ? "d-flex is-invalid" : "d-flex"}>
      <Label className="ml-4">
        <CustomInput
          type="checkbox"
          name={props.name}
          disabled={disabled}
          invalid={invalid}
          checked={getFieldProps(props.name)?.value === true}
          {...props}
          onChange={() => setFieldValue(props.name, getFieldProps(props.name)?.value === undefined ? true: !getFieldProps(props.name)?.value )}
          id={`${props.name}`}
          value={undefined}
        />
      </Label>
    </div>
  )
}

interface ICheckboxField {
  field: any;
  invalid?: boolean;
  disabled?: boolean;
  name?: any;
  value?: any;
}

export default CheckboxField;