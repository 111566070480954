import { getRequest, updateItem, postRequest } from '../peticionesService';
import urlApi from '../../api';
import { mainTypeMsj } from '../../../shared/contexts/interfaces/mainInterfaces';
import { ITareas } from '../../models/procesos/tareasModel';

/**
 * Permite actualizar / crear un registro en la bd
 */
export const tareasClienteSaveService = async (
  values: ITareas,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void,
  file = false,
  sendNotification = true
): Promise<ITareas | undefined> => {
  const url = values.id ? `${urlApi.EDIT_TAREAS}${values.id}` : `${urlApi.CREATE_TAREAS}`;
  const resp = await updateItem(url, values, notification, file, sendNotification, true);
  if (resp?.data?.record) return resp?.data?.record;
  else return resp;
};

/**
 * Permite obtener los registros en la bd
 */
export const tareasClienteListService = async (params: any = {}): Promise<ITareas[]> => {
  let data: ITareas[] = [];
  try {
    const resp = !params?._busqueda ? await getRequest(urlApi.LIST_TAREAS, params) 
      : await postRequest(urlApi.LIST_TAREAS, params);
    data = resp.data.records;
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * Permite obtener el registro por id en la bd
 */
export const tareasClienteShowService = async (id: number): Promise<ITareas | null> => {
  let data: ITareas | null;
  try {
    const resp = await getRequest(`${urlApi.SHOW_TAREAS}${id}`);
    data = resp?.data?.records;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite obtener el listado de tareas cliente con base en el contrato y el usuario
 */
export const tareasClienteFilterService = async (email: string, contract:string): Promise<ITareas | null> => {
  let data: ITareas | null;
  try {
    const resp = await postRequest(`${urlApi.LIST_TAREA_CLI_FILTERED}`,
    {correoasociado: email, 
      condigonegocio: contract
    });
    data = resp?.data?.records;
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

/**
 * Permite bloquear una tarea y cambiar su estado a cancelado
 */
export const tareasCancelService = async (id:number,
  notification: (
    msj: string,
    type: mainTypeMsj
  ) => void
  ): Promise<ITareas | null> => {
  let data: ITareas | null
  try {
    const resp = await getRequest(`${urlApi.CANCEL_TAREA_PRE}${id}`);
    data = resp?.data;
    notification(resp.data.message, mainTypeMsj.SUCCESS);
  } catch (error) {
    console.error(error);
    data = null;
  }
  return data;
};

