enum urlApi {
  // Auth
  LOGIN = '/login',
  // Perfiles
  LIST_PERFILES = '/usuarios/groups/list',
  LIST_PERFILES_BY_CODE = '/usuarios/groups/fetch',
  LIST_SOME_PERFILES_BY_CODE = '/usuarios/groups/fetch_some',
  // RECUPERAR CONTRASEÑA
  RECOVERY_PASSWORD = '/usuarios/users/recover',
  // RECUPERAR CONTRASEÑA
  CHANGE_PASSWORD = '/usuarios/users/change/',
  // LEER NOTIFICACIÓN
  NOTIFICATION_READ = '/noti/seguimiento/edit/',
  // Usuarios
  LIST_USUARIOS = '/usuarios/users/list',
  LIST_FILTER_USUARIOS = '/usuarios/users/list/filter',
  CREAR_USUARIO = '/usuarios/users/create',
  EDIT_USUARIO = '/usuarios/users/edit/',
  BORRAR_USUARIO = '/usuarios/users/remove/',
  SHOW_USUARIO = '/usuarios/users/show/',
  DASHBOARD_USUARIO = '/usuarios/users/dashboard',
  DASHBOARD_TAREAS = '/usuarios/users/dashboard_tareas',
  // Paises
  LIST_PAISES = '/admin/paises/list',
  CREAR_PAIS = '/admin/paises/create',
  EDIT_PAIS = '/admin/paises/edit/',
  BORRAR_PAIS = '/admin/paises/remove/',
  // Paises
  LIST_DEPARTAMENTOS = '/admin/departamentos/list',
  CREAR_DEPARTAMENTO = '/admin/departamentos/create',
  EDIT_DEPARTAMENTO = '/admin/departamentos/edit/',
  BORRAR_DEPARTAMENTO = '/admin/departamentos/remove/',
  // Ciudades
  LIST_CIUDADES = '/admin/ciudades/list',
  CREAR_CIUDAD = '/admin/ciudades/create',
  EDIT_CIUDAD = '/admin/ciudades/edit/',
  BORRAR_CIUDAD = '/admin/ciudades/remove/',
  // Categorías de Contrato
  LIST_CATEGORIAS_CONRATO = '/admin/categorias/list',
  CREAR_CATEGORIA_CONTRATOS = '/admin/categorias/create',
  EDIT_CATEGORIA_CONTRATOS = '/admin/categorias/edit/',
  BORRAR_CATEGORIA_CONTRATOS = '/admin/categorias/remove/',
  // Tipos de Contrato
  LIST_TIPOS_CONRATO = '/admin/tipocontrato/list',
  CREAR_TIPO_CONTRATO = '/admin/tipocontrato/create',
  EDIT_TIPO_CONTRATO = '/admin/tipocontrato/edit/',
  BORRAR_TIPO_CONTRATO = '/admin/tipocontrato/remove/',
  // Filiales
  LIST_FILIALES = '/admin/filiales/list',
  CREAR_FILIAL = '/admin/filiales/create',
  EDIT_FILIAL = '/admin/filiales/edit/',
  BORRAR_FILIAL = '/admin/filiales/remove/',
  // Condiciones de pago
  LIST_CONDICIONES_PAGO = '/admin/condicionpago/list',
  CREAR_CONDICION_PAGO = '/admin/condicionpago/create',
  EDIT_CONDICION_PAGO = '/admin/condicionpago/edit/',
  BORRAR_CONDICION_PAGO = '/admin/condicionpago/remove/',
  // Estados
  LIST_ESTADOS = '/admin/estados/list',
  CREAR_ESTADO = '/admin/estados/create',
  EDIT_ESTADO = '/admin/estados/edit/',
  BORRAR_ESTADO = '/admin/estados/remove/',
  // Centro de costos
  LIST_CENTRO_COSTOS = '/admin/centrocostos/list',
  CREAR_CENTRO_COSTO = '/admin/centrocostos/create',
  EDIT_CENTRO_COSTO = '/admin/centrocostos/edit/',
  BORRAR_CENTRO_COSTO = '/admin/centrocostos/remove/',
  // Plantillas
  LIST_PLANTILLAS = '/admin/plantillas/list',
  CREAR_PLANTILLA = '/admin/plantillas/create',
  EDIT_PLANTILLA = '/admin/plantillas/edit/',
  BORRAR_PLANTILLA = '/admin/plantillas/remove/',
  // Empresas
  LIST_EMPRESAS = '/admin/empresas/list',
  LIST_FILTER_EMPRESAS = '/admin/empresas/list/filter',
  CREAR_EMPRESA = '/admin/empresas/create',
  EDIT_EMPRESA = '/admin/empresas/edit/',
  BORRAR_EMPRESA = '/admin/empresas/remove/',
  // Terceros
  LIST_TERCEROS = '/terce/proveedorcliente/list',
  LIST_FILTER_TERCEROS = '/terce/proveedorcliente/list/filter',
  CREAR_TERCERO = '/terce/proveedorcliente/create',
  EDIT_TERCERO = '/terce/proveedorcliente/edit/',
  BORRAR_TERCERO = '/terce/proveedorcliente/remove/',
  // Vicepresidencias
  LIST_VICEPRESIDENCIAS = '/admin/vicepresidencias/list',
  CREAR_VICEPRESIDENCIA = '/admin/vicepresidencias/create',
  EDIT_VICEPRESIDENCIA = '/admin/vicepresidencias/edit/',
  BORRAR_VICEPRESIDENCIA = '/admin/vicepresidencias/remove/',
  // Gerencias
  LIST_GERENCIAS = '/admin/gerencias/list',
  CREAR_GERENCIA = '/admin/gerencias/create',
  EDIT_GERENCIA = '/admin/gerencias/edit/',
  BORRAR_GERENCIA = '/admin/gerencias/remove/',
  // Areas
  LIST_AREAS = '/admin/areas/list',
  CREAR_AREA = '/admin/areas/create',
  EDIT_AREA = '/admin/areas/edit/',
  BORRAR_AREA = '/admin/areas/remove/',
  // Proceso postcontractual
  CREAR_POSTCONTRACTUAL = '/post/postcontractual/create',
  EDIT_POSTCONTRACTUAL = '/post/postcontractual/edit/',
  LIST_POSTCONTRACTUAL = '/post/postcontractual/list',
  SHOW_POSTCONTRACTUAL = '/post/postcontractual/show/',
  CHECK_CODIGO_POSTCONTRACTUAL = '/post/postcontractual/check',
  // Adendas Postcontractual
  LIST_POST_ADENDAS = '/post/adendas/list',
  CREAR_POST_ADENDA = '/post/adendas/create',
  EDIT_POST_ADENDA = '/post/adendas/edit/',
  // Contractual
  LIST_CONTRACTUAL = '/contra/contractual/list',
  BORRAR_CONTRACTUAL = '/contra/contractual/remove/',
  SHOW_CONTRACTUAL = '/contra/contractual/show/',
  EDIT_CONTRACTUAL = '/contra/contractual/edit/',
  //Step 1
  CREAR_CONTRACTUAL = '/contra/contractual/create',
  EDIT_CONTRACTUAL_STEP1 = '/contra/contractualstep1/edit/',
  //Step 2
  EDIT_CONTRACTUAL_STEP2 = '/contra/contractualstep2/edit/',
  SHOW_CONTRACTUAL_STEP2 = '/contra/contractualstep2/show/',
  //Step 3
  EDIT_CONTRACTUAL_STEP3 = '/contra/contractualstep3/edit/',
  SHOW_CONTRACTUAL_STEP3 = '/contra/contractualstep3/show/',
  //ContractualUsuarios
  LIST_USUARIOS_APROBACIONES = '/contra/usuarios/list_aprobaciones/',
  LIST_CONTRACTUAL_USUARIOS = '/contra/usuarios/list',
  CREAR_CONTRACTUAL_USUARIOS = '/contra/usuarios/create',
  EDIT_CONTRACTUAL_USUARIOS = '/contra/usuarios/edit/',
  BORRAR_CONTRACTUAL_USUARIOS = '/contra/usuarios/remove/',
  SHOW_CONTRACTUAL_USUARIOS = '/contra/usuarios/show/',
  SHOW_POST_USUARIOS = '/post/usuarios/show/',
  //ContractualUsuarios
  LIST_POSTCONTRACTUAL_USUARIOS = '/post/usuarios/list',
  CREAR_POSTCONTRACTUAL_USUARIOS = '/post/usuarios/create',
  CREAR_POSTCONTRACTUAL_USUARIOS_MULTI = '/post/usuarios/multi_create',
  EDIT_POSTCONTRACTUAL_USUARIOS = '/post/usuarios/edit/',
  LIST_USUARIOS_APROBACIONES_POST = '/post/usuarios/list_aprobaciones/',
  //ContractualCondicionesPago
  LIST_CONTRACTUAL_CONDICIONES_PAGO = '/contra/condicionespago/list',
  CREAR_CONTRACTUAL_CONDICIONES_PAGO = '/contra/condicionespago/create',
  EDIT_CONTRACTUAL_CONDICIONES_PAGO = '/contra/condicionespago/edit/',
  BORRAR_CONTRACTUAL_CONDICIONES_PAGO = '/contra/condicionespago/remove/',
  // Suspensiones Postcontractual
  LIST_POST_SUSPENSIONES = '/post/suspension/list',
  CREAR_POST_SUSPENSION = '/post/suspension/create',
  EDIT_POST_SUSPENSION = '/post/suspension/edit/',
  CREAR_POST_REINICIO = '/post/reinicio/create',
  EDIT_POST_REINICIO = '/post/reinicio/edit/',
  // Liquidaciones Postcontractual
  LIST_POST_LIQUIDACIONES = '/post/liquidacion/list',
  CREAR_POST_LIQUIDACION = '/post/liquidacion/create',
  EDIT_POST_LIQUIDACION = '/post/liquidacion/edit/',
  // Renovacion Postcontractual
  LIST_POST_RENOVACIONES = '/post/renovacion/list',
  CREAR_POST_RENOVACION = '/post/renovacion/create',
  EDIT_POST_RENOVACION = '/post/renovacion/edit/',
  // DOCUMENTOS TAREAS
  LIST_TAREA_DOCUMENTOS = '/tareas/documentos/list',
  CREAR_TAREA_DOCUMENTOS = '/tareas/documentos/create',
  EDITAR_TAREA_DOCUMENTOS = '/tareas/documentos/edit/',
  // DOCUMENTOS CONTRACTUAL
  LIST_CONTRA_DOCUMENTOS = '/contra/documentos/list',
  CREAR_CONTRA_DOCUMENTO = '/contra/documentos/create',
  EDIT_CONTRA_DOCUMENTO = '/contra/documentos/edit/',
  DOWN_CONTRA_DOCUMENTO = '/contra/documentos/download/',
  // DOCUMENTOS CONTRACTUAL
  LIST_TAREAS = '/tareas/tareas/list',
  CREATE_TAREAS = '/tareas/tareas/create',
  EDIT_TAREAS = '/tareas/tareas/edit/',
  SHOW_TAREAS = '/tareas/tareas/show/',
  VALIDACION_GENERAR_OTP = '/tareas/validacionotp/creacion/enviar',
  VALIDACION_CONFIRMAR_OTP = '/tareas/validacionotp/confirmar',
  // COMENTARIOS TAREAS CONTRACTUAL
  LIST_COMMENT_TAREAS = '/tareas/foro/list',
  CREATE_COMMENT_TAREAS = '/tareas/foro/create',
  EDIT_COMMENT_TAREAS = '/tareas/foro/edit/',
  // DOCUMENTOS POSTCONTRACTUAL
  LIST_POST_DOCUMENTOS = '/post/documentos/list',
  CREAR_POST_DOCUMENTO = '/post/documentos/create',
  EDIT_POST_DOCUMENTO = '/post/documentos/edit/',
  // APROBACION LEGAL CONTRACTUAL
  INFO_COMPLETA_CONTRACTUAL = '/contra/informacioncompleta/edit/',
  // COMENTARIOS CONTRACTUAL
  COMENT_CONTRACTUAL = '/contra/comentarios/list',
  CREATE_COMENT_CONTRACTUAL = '/contra/comentarios/create',
  // FORO MINUTA
  CONSULTAR_TRM = '/api/v1/trm',
  ARCHIVO_PROXY = '/api/v1/file_proxy',
  // FORO MINUTA
  CREATE_COMMENT_CONTRACTUAL = '/contra/foro/create',
  LIST_COMMENTS_CONTRACTUAL = '/contra/foro/list',
  // FORO POSTCONTRACTUAL
  CREATE_COMMENT_POSTCONTRACTUAL = '/post/foro/create',
  LIST_COMMENTS_POSTCONTRACTUAL = '/post/foro/list',
  // APROBAR SOLICITANTE
  APROBAR_CONTRA_SOLICITANTE = '/contra/aprobarsolicitante/edit/',
  // APROBAR TERCERO
  APROBAR_CONTRA_TERCERO = '/contra/aprobarminuta/edit/',
  // APROBAR PROCESO CONTRACTUAL
  APROBAR_PROCESO_CONTRA = '/contra/aprobaciones/create',
  APROBAR_PROCESO_CONTRA_LIST = '/contra/aprobaciones/list',
  APROBAR_PROCESO_CONTRA_EDIT = '/contra/aprobaciones/edit/',
  // APROBAR APROBADOR
  APROBAR_CONTRA_APROBADOR = '/contra/usuarios/edit/',
  APROBAR_POST_APROBADOR = '/post/usuarios/edit/',
  // REQUIERE POLIZA O GARANTIA
  REQUIEREPOLIZA_CONTRA = '/contra/requierepolizas/edit/',
  // CREETE POLIZA GARANTIA
  CREATE_POLIZAGARANTIA = '/contra/polizagarantia/create',
  LIST_POLIZAGARANTIA = '/contra/polizagarantia/list',
  EDIT_POLIZAGARANTIA = '/contra/polizagarantia/edit/',
  // REQUERIMIENTOS CONTRACTUAL
  SHOW_REQUERIMIENTO = '/contra/requerimientos/show/',
  LIST_REQUERIMIENTOS = '/contra/requerimientos/list',
  CREATE_REQUERIMIENTO = '/contra/requerimientos/create',
  EDIT_REQUERIMIENTOS = '/contra/requerimientos/edit/',
  // AMPARO CONTRACTUAL
  CONTRA_AMPARO_CREATE = '/contra/amparos/create',
  CONTRA_AMPARO_EDIT = '/contra/amparos/edit/',
  CONTRA_AMPARO_LIST = '/contra/amparos/list',
  //Aprobar Acta
  POST_APROBAR_ACTA = '/post/aprobaracta/create',
  // Zoho editar
  ZOHO_EDIT = '/service/zoho/editar/',
  // Reportes
  REPORTE_CONTRACTUAL = '/reporte/contractual/list',
  REPORTE_POSTCONTRACTUAL = '/reporte/postcontractual/list',
  REPORTE_TERCEROS = '/reporte/proveedorcliente/list',
  // Notificaciones
  NOTIFICACIONES_LIST = '/noti/notificaciones/list/',
  // Financiero
  SHOW_CHART_FINANCIERO = '/finan/pagos/showchart/',
  SHOW_DATAPROCESO_FINANCIERO = '/finan/pagos/dataproceso/',
  SHOW_PAGOS_FINANCIERO = '/finan/pagos/listbypostcontractual/',
  CHANGE_STATUS_PAGO = '/finan/pagos/changestatus/',
  CREATE_PLAN_PAGO = '/finan/planpagos/create',
  CREATE_PAGO = '/finan/pagos/create',
  EDIT_PAGO = '/finan/pagos/edit/',
  SHOW_FINANCIERO_BY_POSTCONTRACTUAL = '/finan/financiero/showbypostcontractual/',
  CREATE_FINANCIERO = '/finan/financiero/create',
  EDIT_FINANCIERO = '/finan/financiero/edit/',
  // Comentarios postcontractual
  COMENT_POSTCONTRACTUAL = '/post/comentarios/list',
  CREATE_COMENT_POSTCONTRACTUAL = '/post/comentarios/create',
  BORRAR_PAGO = '/finan/pagos/remove/',
  // APROBAR PROCESO POSTCONTRACTUAL
  APROBAR_PROCESO_POST = '/post/aprobaciones/create',
  APROBAR_PROCESO_POST_LIST = '/post/aprobaciones/list',
  APROBAR_PROCESO_POST_EDIT = '/post/aprobaciones/edit/',
  // TERCEROS REPRESENTANTES LEGALES
  LIST_REPRESENTANTES = '/terce/representanteslegales/list',
  CREAR_REPRESENTANTE = '/terce/representanteslegales/create',
  EDIT_REPRESENTANTE = '/terce/representanteslegales/edit/',
  BORRAR_REPRESENTANTE = '/terce/representanteslegales/remove/',
  // TERCEROS DOCUMENTOS 
  LIST_TERCE_DOCUMENTOS = '/terce/documentos/list',
  CREAR_TERCE_DOCUMENTOS = '/terce/documentos/create',
  EDIT_TERCE_DOCUMENTOS = '/terce/documentos/edit/',
  DOWN_TERCE_DOCUMENTOS = '/terce/documentos/download/',
  // Validación financiera
  LIST_VALID_FINANCIERA = '/contra/financiero/list',
  CREAR_VALID_FINANCIERA = '/contra/financiero/create',
  EDIT_VALID_FINANCIERA = '/contra/financiero/edit/',
  // Códigos
  CREATE_CODIGO = '/usuarios/codigos/generateforsign',
  CREATE_CODIGO_THIRD = '/usuarios/codigos/generateforsignthird',
  VALIDATE_CODIGO = '/usuarios/codigos/validatecode',
  VALIDATE_CODIGO_THIRD = '/usuarios/codigos/validatecodethird',
  // Firmas
  SHOW_FIRMA = '/usuarios/firmas/show/',
  SHOW_FIRMA_THIRD = '/usuarios/firmas/showthird/',
  SIGN_FIRMA = '/usuarios/firmas/sign',
  LIST_FIRMA = '/usuarios/firmas/list',
  // Asesorias
  CREATE_ASESORIAS = '/ase/asesorias/create',
  EDIT_ASESORIAS = '/ase/asesorias/edit/',
  LIST_ASESORIAS = '/ase/asesorias/list',
  SHOW_ASESORIAS = '/ase/asesorias/show/',
  STATES_ASESORIAS = '/ase/asesorias/states/',
  LIST_ASESORIAS_USUARIOS = '/ase/usuarios/list',
  LIST_ASESORIAS_DOCUMENTOS = '/ase/documentos/list',
  LIST_ASESORIAS_ABOGADOS = '/ase/usuarios/list_abogados',
  LIST_PREGUNTAS = '/ase/preguntas/list',
  SEARCH_PREGUNTAS = '/ase/preguntas/search',
  LIST_ASESORIAS_LOGS = '/ase/logs/list',
  LIST_ASESORIAS_ANS = '/ase/ans/list',
  // Permisos
  LIST_PERMISOS = '/usuarios/permission/list',
  CREAR_PERMISO = '/usuarios/permission/create',
  EDIT_PERMISO = '/usuarios/permission/edit/',
  BORRAR_PERMISO = '/usuarios/permission/remove/',
  // Modules
  LIST_MODULES = '/usuarios/modules/list',
  CREAR_MODULE = '/usuarios/modules/create',
  EDIT_MODULE = '/usuarios/modules/edit/',
  BORRAR_MODULE = '/usuarios/modules/remove/',
  // Roles
  LIST_ROLES = '/usuarios/groups/list',
  CREAR_ROL = '/usuarios/groups/create',
  EDIT_ROL = '/usuarios/groups/edit/',
  BORRAR_ROL = '/usuarios/groups/remove/',
  // Cargos
  LIST_CARGOS = '/admin/cargos/list',
  CREAR_CARGO = '/admin/cargos/create',
  EDIT_CARGO = '/admin/cargos/edit/',
  BORRAR_CARGO = '/admin/cargos/remove/',
  // listas itau
  SHOW_LISTAS = "/admin/listadesplegable/show/",
  LIST_LISTAS = "/admin/listadesplegable/list",
  // fideicomitentes
  CREATE_FIDEICOMITENTE = "/post/postfideicomitente/create",
  EDITAR_FIDEICOMITENTE = "/post/postfideicomitente/edit/",
  LISTAR_FIDEICOMITENTES = "/post/postfideicomitente/list",
  SHOW_FIDEICOMITENTES = "/post/postfideicomitente/show/",
  DELETE_FIDEICOMITENTES = "/post/postfideicomitente/delete/",
  // beneficiarios
  CREATE_BENEFICIARIO = "/post/postbeneficiario/create",
  EDITAR_BENEFICIARIO = "/post/postbeneficiario/edit/",
  LISTAR_BENEFICIARIOS = "/post/postbeneficiario/list",
  SHOW_BENEFICIARIO = "/post/postbeneficiario/show/",
  DELETE_BENEFICIARIO = "/post/postbeneficiario/delete/",
  // postTerceros
  CREATE_POST_TERCEROS = "/post/posttercero/create",
  EDITAR_POST_TERCEROS = "/post/posttercero/edit/",
  LISTAR_POST_TERCEROSS = "/post/posttercero/list",
  SHOW_POST_TERCEROS = "/post/posttercero/show/",
  DELETE_POST_TERCEROS = "/post/posttercero/delete/",
  // firmas autorizadas
  CREATE_POST_FIRMAS_AUTORIZADAS = "/post/postfirmasautorizadas/create",
  EDITAR_POST_FIRMAS_AUTORIZADAS = "/post/postfirmasautorizadas/edit/",
  LISTAR_POST_FIRMAS_AUTORIZADAS = "/post/postfirmasautorizadas/list",
  SHOW_POST_FIRMAS_AUTORIZADAS = "/post/postfirmasautorizadas/show/",
  DELETE_POST_FIRMAS_AUTORIZADAS = "/post/postfirmasautorizadas/delete/",
  // comision fiduciaria
  CREATE_COMISION_FIDUCIARIA = "/post/postcomisionfiduciaria/create",
  EDITAR_COMISION_FIDUCIARIA = "/post/postcomisionfiduciaria/edit/",
  LISTAR_COMISION_FIDUCIARIA = "/post/postcomisionfiduciaria/list",
  SHOW_COMISION_FIDUCIARIA = "/post/postcomisionfiduciaria/show/",
  DELETE_COMISION_FIDUCIARIA = "/post/postcomisionfiduciaria/delete/",
  // productos aperturar
  CREATE_PRODUCTOS_APERTURAR = "/post/postproductosaperturar/create",
  EDITAR_PRODUCTOS_APERTURAR = "/post/postproductosaperturar/edit/",
  LISTAR_PRODUCTOS_APERTURAR = "/post/postproductosaperturar/list",
  SHOW_PRODUCTOS_APERTURAR = "/post/postproductosaperturar/show/",
  DELETE_PRODUCTOS_APERTURAR = "/post/postproductosaperturar/delete/",
  // Token cliente
  CREATE_TOKEN_TAREA_CLIENTE = '/tareas/tareas/tokencliente',
  // Tokens
  LIST_TOKENS = '/administracion/apptoken/list',
  DELETE_TOKENS = '/administracion/apptoken/remove/',
  SHOW_TOKENS = '/administracion/apptoken/show/',
  EDIT_TOKENS = '/administracion/apptoken/edit/',
  CREATE_TOKENS = '/administracion/apptoken/create',
  RECUPERAR_TOKENS1 = '/tareas/tareas/recuperar/codigonegocio',
  RECUPERAR_TOKENS2 = '/tareas/tareas/recuperar/correo',
  // Cargues masivos
  LISTAR_CARGUE_MASIVO = '/admin/carguemasivo/list',
  CREATE_CARGUE_MASIVO = '/admin/carguemasivo/create',
  SHOW_CARGUE_MASIVO = '/admin/carguemasivo/show/',
  //
  LIST_TAREA_SOL_MONETARIA_ACLARACION = "/tareas/tareasolmonetariaaclaracion/list",
  SHOW_TAREA_SOL_MONETARIA_ACLARACION = "/tareas/tareasolmonetariaaclaracion/show/",
  CREATE_TAREA_SOL_MONETARIA_ACLARACION = "/tareas/tareasolmonetariaaclaracion/create",
  EDIT_TAREA_SOL_MONETARIA_ACLARACION = "/tareas/tareasolmonetariaaclaracion/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_CAUSACION = "/tareas/tareasolmonetariacausacion/list",
  SHOW_TAREA_SOL_MONETARIA_CAUSACION = "/tareas/tareasolmonetariacausacion/show/",
  CREATE_TAREA_SOL_MONETARIA_CAUSACION = "/tareas/tareasolmonetariacausacion/create",
  EDIT_TAREA_SOL_MONETARIA_CAUSACION = "/tareas/tareasolmonetariacausacion/edit/",
  // 
  LIST_TAREA_SOL_MONETARIA_GIROS = "/tareas/tareasolmonetariagiros/list",
  SHOW_TAREA_SOL_MONETARIA_GIROS = "/tareas/tareasolmonetariagiros/show/",
  CREATE_TAREA_SOL_MONETARIA_GIROS = "/tareas/tareasolmonetariagiros/create",
  EDIT_TAREA_SOL_MONETARIA_GIROS = "/tareas/tareasolmonetariagiros/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS = "/tareas/tareasolmonetariagirospagosmasivos/list",
  SHOW_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS = "/tareas/tareasolmonetariagirospagosmasivos/show/",
  CREATE_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS = "/tareas/tareasolmonetariagirospagosmasivos/create",
  EDIT_TAREA_SOL_MONETARIA_GIROS_PAGOS_MASIVOS = "/tareas/tareasolmonetariagirospagosmasivos/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS = "/tareas/tareasolmonetariagirospagosterceros/list",
  SHOW_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS = "/tareas/tareasolmonetariagirospagosterceros/show/",
  CREATE_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS = "/tareas/tareasolmonetariagirospagosterceros/create",
  EDIT_TAREA_SOL_MONETARIA_GIROS_PAGOS_TERCEROS = "/tareas/tareasolmonetariagirospagosterceros/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADO_TRIBUTARIO = "/tareas/tareasolnomonetariacertificadotributario/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CERTIFICADO_TRIBUTARIO = "/tareas/tareasolnomonetariacertificadotributario/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CERTIFICADO_TRIBUTARIO = "/tareas/tareasolnomonetariacertificadotributario/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CERTIFICADO_TRIBUTARIO = "/tareas/tareasolnomonetariacertificadotributario/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA = "/tareas/tareasolnomonetariacertificadogarantia/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA = "/tareas/tareasolnomonetariacertificadogarantia/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA = "/tareas/tareasolnomonetariacertificadogarantia/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CERTIFICADOS_GARANTIA = "/tareas/tareasolnomonetariacertificadogarantia/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADO_EXISTENCIA = "/tareas/tareasolnomonetariacertificadoexistencia/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CERTIFICADO_EXISTENCIA = "/tareas/tareasolnomonetariacertificadoexistencia/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CERTIFICADO_EXISTENCIA = "/tareas/tareasolnomonetariacertificadoexistencia/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CERTIFICADO_EXISTENCIA = "/tareas/tareasolnomonetariacertificadoexistencia/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CERTIFICADO_SALDOS = "/tareas/tareasolnomonetariacertificadosaldos/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CERTIFICADO_SALDOS = "/tareas/tareasolnomonetariacertificadosaldos/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CERTIFICADO_SALDOS = "/tareas/tareasolnomonetariacertificadosaldos/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CERTIFICADO_SALDOS = "/tareas/tareasolnomonetariacertificadosaldos/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_PAGO_TERCEROS = "/tareas/tareasolmonetariapagoterceros/list",
  SHOW_TAREA_SOL_MONETARIA_PAGO_TERCEROS = "/tareas/tareasolmonetariapagoterceros/show/",
  CREATE_TAREA_SOL_MONETARIA_PAGO_TERCEROS = "/tareas/tareasolmonetariapagoterceros/create",
  EDIT_TAREA_SOL_MONETARIA_PAGO_TERCEROS = "/tareas/tareasolmonetariapagoterceros/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_RESTITUCION_APORTES = "/tareas/tareasolmonetariarestitucionaportes/list",
  SHOW_TAREA_SOL_MONETARIA_RESTITUCION_APORTES = "/tareas/tareasolmonetariarestitucionaportes/show/",
  CREATE_TAREA_SOL_MONETARIA_RESTITUCION_APORTES = "/tareas/tareasolmonetariarestitucionaportes/create",
  EDIT_TAREA_SOL_MONETARIA_RESTITUCION_APORTES = "/tareas/tareasolmonetariarestitucionaportes/edit/",
  //
  LIST_TAREA_SOL_MONETARIA_TRASLADOS = "/tareas/tareasolmonetariatraslados/list",
  SHOW_TAREA_SOL_MONETARIA_TRASLADOS = "/tareas/tareasolmonetariatraslados/show/",
  CREATE_TAREA_SOL_MONETARIA_TRASLADOS = "/tareas/tareasolmonetariatraslados/create",
  EDIT_TAREA_SOL_MONETARIA_TRASLADOS = "/tareas/tareasolmonetariatraslados/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_AGENDAMIENTO_REUNION = "/tareas/tareasolnomonetariaagendamientoreunion/list",
  SHOW_TAREA_SOL_NO_MONETARIA_AGENDAMIENTO_REUNION = "/tareas/tareasolnomonetariaagendamientoreunion/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_AGENDAMIENTO_REUNION = "/tareas/tareasolnomonetariaagendamientoreunion/create",
  EDIT_TAREA_SOL_NO_MONETARIA_AGENDAMIENTO_REUNION = "/tareas/tareasolnomonetariaagendamientoreunion/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CESIONES_PREVENTA = "/tareas/tareasolnomonetariacesionespreventa/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CESIONES_PREVENTA = "/tareas/tareasolnomonetariacesionespreventa/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CESIONES_PREVENTA = "/tareas/tareasolnomonetariacesionespreventa/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CESIONES_PREVENTA = "/tareas/tareasolnomonetariacesionespreventa/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_CREACION_TERCERO = "/tareas/tareasolnomonetariacreaciontercero/list",
  SHOW_TAREA_SOL_NO_MONETARIA_CREACION_TERCERO = "/tareas/tareasolnomonetariacreaciontercero/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_CREACION_TERCERO = "/tareas/tareasolnomonetariacreaciontercero/create",
  EDIT_TAREA_SOL_NO_MONETARIA_CREACION_TERCERO = "/tareas/tareasolnomonetariacreaciontercero/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_DESISTIMIENTO_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariadesistimientoproyectoinmobiliario/list",
  SHOW_TAREA_SOL_NO_MONETARIA_DESISTIMIENTO_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariadesistimientoproyectoinmobiliario/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_DESISTIMIENTO_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariadesistimientoproyectoinmobiliario/create",
  EDIT_TAREA_SOL_NO_MONETARIA_DESISTIMIENTO_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariadesistimientoproyectoinmobiliario/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_ESCRITURA_PUBLICA = "/tareas/tareasolnomonetariaescriturapublica/list",
  SHOW_TAREA_SOL_NO_MONETARIA_ESCRITURA_PUBLICA = "/tareas/tareasolnomonetariaescriturapublica/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_ESCRITURA_PUBLICA = "/tareas/tareasolnomonetariaescriturapublica/create",
  EDIT_TAREA_SOL_NO_MONETARIA_ESCRITURA_PUBLICA = "/tareas/tareasolnomonetariaescriturapublica/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_ESTADOS_FINANCIEROS = "/tareas/tareasolnomonetariaestadosfinancieros/list",
  SHOW_TAREA_SOL_NO_MONETARIA_ESTADOS_FINANCIEROS = "/tareas/tareasolnomonetariaestadosfinancieros/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_ESTADOS_FINANCIEROS = "/tareas/tareasolnomonetariaestadosfinancieros/create",
  EDIT_TAREA_SOL_NO_MONETARIA_ESTADOS_FINANCIEROS = "/tareas/tareasolnomonetariaestadosfinancieros/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_EXTRACTO = "/tareas/tareasolnomonetariaextracto/list",
  SHOW_TAREA_SOL_NO_MONETARIA_EXTRACTO = "/tareas/tareasolnomonetariaextracto/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_EXTRACTO = "/tareas/tareasolnomonetariaextracto/create",
  EDIT_TAREA_SOL_NO_MONETARIA_EXTRACTO = "/tareas/tareasolnomonetariaextracto/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_PODER = "/tareas/tareasolnomonetariapoder/list",
  SHOW_TAREA_SOL_NO_MONETARIA_PODER = "/tareas/tareasolnomonetariapoder/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_PODER = "/tareas/tareasolnomonetariapoder/create",
  EDIT_TAREA_SOL_NO_MONETARIA_PODER = "/tareas/tareasolnomonetariapoder/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_PROMESA_COMPRAVENTA = "/tareas/tareasolnomonetariapromesacompraventa/list",
  SHOW_TAREA_SOL_NO_MONETARIA_PROMESA_COMPRAVENTA = "/tareas/tareasolnomonetariapromesacompraventa/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_PROMESA_COMPRAVENTA = "/tareas/tareasolnomonetariapromesacompraventa/create",
  EDIT_TAREA_SOL_NO_MONETARIA_PROMESA_COMPRAVENTA = "/tareas/tareasolnomonetariapromesacompraventa/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_SOLICITUD_AGENTES_CREDITO = "/tareas/tareasolnomonetariasolicitudagentescredito/list",
  SHOW_TAREA_SOL_NO_MONETARIA_SOLICITUD_AGENTES_CREDITO = "/tareas/tareasolnomonetariasolicitudagentescredito/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_SOLICITUD_AGENTES_CREDITO = "/tareas/tareasolnomonetariasolicitudagentescredito/create",
  EDIT_TAREA_SOL_NO_MONETARIA_SOLICITUD_AGENTES_CREDITO = "/tareas/tareasolnomonetariasolicitudagentescredito/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_SOPORTE = "/tareas/tareasolnomonetariasoporte/list",
  SHOW_TAREA_SOL_NO_MONETARIA_SOPORTE = "/tareas/tareasolnomonetariasoporte/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_SOPORTE = "/tareas/tareasolnomonetariasoporte/create",
  EDIT_TAREA_SOL_NO_MONETARIA_SOPORTE = "/tareas/tareasolnomonetariasoporte/edit/",
  //
  LIST_TAREA_SOL_NO_MONETARIA_VINCULACION_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariavinculacionproyectoinmobiliario/list",
  SHOW_TAREA_SOL_NO_MONETARIA_VINCULACION_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariavinculacionproyectoinmobiliario/show/",
  CREATE_TAREA_SOL_NO_MONETARIA_VINCULACION_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariavinculacionproyectoinmobiliario/create",
  EDIT_TAREA_SOL_NO_MONETARIA_VINCULACION_PROYECTO_INMOBILIARIO = "/tareas/tareasolnomonetariavinculacionproyectoinmobiliario/edit/",
  LIST_TAREA_CLI_FILTERED = "/tareas/obtenerinformacion",
  //
  LIST_SUBTAREA_PRE = "/tareas/tareachecksubtareas/list",
  SHOW_SUBTAREA_PRE = "/tareas/tareachecksubtareas/show/",
  CREATE_SUBTAREA_PRE = "/tareas/tareachecksubtareas/create",
  EDIT_SUBTAREA_PRE = "/tareas/tareachecksubtareas/edit/",
  CANCEL_TAREA_PRE = "/tareas/cancelar/"
  //
  // LIST_.......... = "/tareas/__________________________/list",
  // SHOW_.......... = "/tareas/__________________________/show/",
  // CREATE_........ = "/tareas/__________________________/create",
  // EDIT_.......... = "/tareas/__________________________/edit/",
};

export default urlApi;
