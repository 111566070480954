import { cargueMasivoTypeActions } from "..";
import { ICargueMasivo } from "../../../core/models/postcontractual/carguemasivoModel";
import { ICargueMasivoActions } from "../interfaces/cargueMasivoInterface";

export function updateId(payload: string): ICargueMasivoActions {
    return {
        type: cargueMasivoTypeActions.ID,
        payload
    };
}

export function updateProceso(payload: ICargueMasivo) {
    return {
        type: cargueMasivoTypeActions.PROCESO,
        payload
    };
}