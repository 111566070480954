import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from "reactstrap";
import * as Yup from 'yup';
import { ICrearToken } from "../../../core/models/procesos/tareasModel";
import { crearTokenCliente } from "../../../core/services/procesos/tareasService";
import ButtonCustom from "../../../shared/components/ButtonCustom";
import { falseLoading, openModal, trueLoading } from "../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../shared/contexts/mainContext";
import { FormValidation } from "../../../shared/utilities";
import { useHistory } from 'react-router-dom';
import FormRecuperar from './FormRecuperar';

const politica = "https://www.itau.co/documents/10282/1023825/MG1146_Pol%C3%ADticas_tratamiento_informaci%C3%B3n.pdf";
const FormTareasClientes: FC = () => {
  // Context
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const history = useHistory();

  // State
  const [msj, setMsj] = useState<string|null>(null);

  const handleHelp = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Ayuda'),
        body: () => (
          <ModalBody>
            {t('Para cualquier inquietud comunícate con nosotros al tel {{phone}} o al correo {{email}}', 
            {
              phone: '3336025608',
              email: 'operaciones.fiduciaria@itau.co',
            })}
          </ModalBody>
        )
      }
    }))
  };

  const handleRecover = (type: number) => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: type === 2 ? t('Olvidé mi correo') : t('Olvidé mi código'),
        body: () => (
          <ModalBody>
            <FormRecuperar type={type} />
          </ModalBody>
        )
      }
    }))
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      numeronegocio: '',
      politica: false,
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      email: Yup.string().required(FormValidation.required),
      numeronegocio: Yup.string().required(FormValidation.required),
      politica: Yup.boolean().oneOf([true], FormValidation.required),
    })),
    onSubmit: async (values: ICrearToken, { resetForm }) => {
      mainDispatch(trueLoading());
      const resp = await crearTokenCliente(values, handleNotification)
      if (resp) {
        setMsj(t('Por favor ingrese la información detallada de la solicitud'));
        history.push(`/token/${resp.id}/${resp.token}`)
        resetForm();
      }
      mainDispatch(falseLoading());
    }
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    !!msj ?
      <Alert color="success" className="mb-0">{msj}</Alert>
    :
      <>
        <div>
          <Form id="FormTareasClientes" onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Row>
                <Col sm={12} md={12} >
                  <FormGroup>
                    <Label for="email">{t('Correo electrónico')}</Label>
                    <Field
                      as={Input}
                      name="email"
                      invalid={errors.email && touched.email}
                      disabled={false}
                    />
                    <FormFeedback>{t(errors.email as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label for="numeronegocio">{t('Código del negocio')}</Label>
                    <Field
                      as={Input}
                      name="numeronegocio"
                      invalid={errors.numeronegocio && touched.numeronegocio}
                      disabled={false}
                    />
                    <FormFeedback>{t(errors.numeronegocio as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <Button color="link" block onClick={() => handleRecover(2)}>
                    {t('Olvidé mi correo electrónico asociado')}
                  </Button>
                </Col>
                <Col sm={12} md={12}>
                  <Button color="link" block onClick={() => handleRecover(1)}>
                    {t('Olvidé mi código de negocio')}
                  </Button>
                </Col>
                <Col sm={12} md={12}>
                  <hr />
                  <Trans i18nKey="politicaConEnlace">Al marcar esta casilla, acepto que las solicitudes radicadas por este medio, están sujetas a verificación y podrán ser aceptadas o rechazadas. Autorizo me contacten a los datos personales que he entregado y adicionalmente, autorizo el tratamiento de dichos datos conforme a la <a href={politica} target="_blank" rel="noopener noreferrer">política de tratamiento de datos personales.</a></Trans>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label for="politica">
                      <strong>{t('Acepto')}</strong>
                    </Label>
                    <Field
                      as={Input}
                      type="checkbox"
                      id="politica"
                      name="politica"
                      invalid={errors.politica && touched.politica}
                      disabled={false}
                    />
                    <FormFeedback>{t(errors.politica as string)}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Button color="link" onClick={handleHelp}>
                {t('¿Necesitas ayuda?')}
              </Button>
              <ButtonCustom
                form="FormTareasClientes"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Continuar')}
              </ButtonCustom>
            </FormikProvider>
          </Form>
        </div>
      </>
  );
}

export default FormTareasClientes;
