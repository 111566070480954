import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "reactstrap";
// Contextos
import { useUserContext } from '../contexts/userContext';
import { userLogout } from '../contexts/actions/userActions';
import { changeLoading, closeModal, openModal } from '../contexts/actions/mainActions';
import ListNotificaciones from './ListNotificaciones';
import CambiarContrasena from './CambiarContrasena';
import { leerNotificacion, obtenerNotificaciones } from '../../core/services/notificaciones/notificacionesService';
import { cambiarContrasena } from '../../core/services/usuarios/usuariosService';
import { useNotificacionContext } from '../contexts/notificationContext';
import { clearNotification, updateNotifications } from '../contexts/actions/notificationsActions';
import { listRoles } from '../../core/services/listasDesplegables';
import LanguageDropdown from './LanguageDropdown';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../contexts/mainContext';
import { EPostModules } from '../utilities';
import dev from '../../core/enviroments';

const NavbarToggle = ({ toggleSidebar }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        toggleSidebar();
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
};

const NavbarDropdown: FC<INavbarDropdown> = ({
  children,
  count,
  header,
  footer,
  icon,
  active,
  indicator,
  modal,
}) => {
  const { t } = useTranslation();
  // contextos
  const { mainDispatch } = useMainContext();

  const history = useHistory();

  return (
    <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
      <DropdownToggle nav className="nav-icon dropdown-toggle position-relative mr-2">
        <div>
          <i className={`${icon}`} style={{ fontSize: '1.2rem' }}></i>
          {indicator ? <span className="indicator" style={{ marginTop: '-10px' }} /> : ""}
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg py-0">
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
        <ListGroup>{children}</ListGroup>
        <DropdownItem header className="dropdown-menu-footer">
          {modal == 'notificaciones' && (
            <a
              className="mr-1 mb-1"
              onClick={() => mainDispatch(openModal({
                modal: {
                  show: true,
                  size: 'lg',
                  title: t('Notificaciones'),
                  body: () => (
                    <ListNotificaciones {...history} />
                  )
                }
              }))}
            >
              {footer}
            </a>
          )}
          {!modal && (
            <span className="text-muted">{footer}</span>
          )}

        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )

};
interface INavbarDropdown {
  header: string;
  footer: string;
  icon: string;
  count: number;
  indicator?: boolean;
  active?: boolean;
  modal?: string;
}

const NavbarDropdownItem: FC<any> = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={(spacing) && "pl-2"}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const NavbarDropdowns: FC<any> = () => {
  const history = useHistory();
  const { userState, userDispatch } = useUserContext();
  const { mainDispatch, handleNotification } = useMainContext();
  const { notificationState, notificationDispatch } = useNotificacionContext();
  const { t } = useTranslation();
  const [notificaciones, setNotificaciones] = useState<any[]>([]);
  const [rol, setRole] = useState('');

  const onSubmitCambiarContrasena = async (values: any) => {
    const dataSend = {
      ...values,
      password: encodeURIComponent(values.password),
      password_repeat: encodeURIComponent(values.password_repeat),
      id: userState?.data?.sub
    }
    mainDispatch(changeLoading());
    if (await cambiarContrasena(dataSend, handleNotification)) {
      if (userState?.data?.cambiocontrasena && userState?.data?.cambiocontrasena == 1) {
        logOut();
      }
      mainDispatch(closeModal());
    }
  };

  /**
   * Permite acceder a un registro
   * @param id 
   */
  const handleContraseña = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Cambio de contraseña'),
        body: () => (
          <>
            <CambiarContrasena
              onSubmit={onSubmitCambiarContrasena}
            />
          </>
        )
      }
    }))
  };

  const logOut = () => {
    notificationDispatch(clearNotification());
    userDispatch(userLogout());
    history.push('/login');
  }

  const handleShowNotification = (id, procesoId, modulo) => {
    const url = (modulo === EPostModules.POSTCONTRACTUAL.nombre) ? 'postcontractual' : 'nodataidmodulo';
    const notificaciones = notificationState.notifications.filter(notificacion => notificacion.idseguimiento !== id);

    notificationDispatch(updateNotifications(notificaciones));
    leerNotificacion(id);
    history.push(`/${url}/edit/`, { id: procesoId });
  };

  useEffect(() => {
    if (notificationState.notifications.length > 0) {
      setNotificaciones(notificationState.notifications.slice(0, 5))
    } else {
      setNotificaciones([]);
    }
  }, [notificationState]);

  useEffect(() => {
    // if (userState.data?.roles) {
    //   for_main:
    //   for (const rol of listRoles) {
    //     for (const rolUser of userState.data?.roles) {
    //       if (rolUser === rol.value) {
    //         setRole(rol.label);
    //         break for_main;
    //       }
    //     }
    //   }
    // }

    if (userState?.data?.cambiocontrasena && userState?.data?.cambiocontrasena == 1) {
      handleContraseña();
    }
  }, [userState])

  return (
    <Collapse navbar>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <LanguageDropdown history={history}/>
        </UncontrolledDropdown>
        <NavbarDropdown
          header={t('Notificaciones no leidas')}
          footer={t('Mostrar todas las notificaciones')}
          icon={'uil-bell'}
          count={notificationState.notifications?.length}
          indicator={notificationState.notifications?.length > 0}
          modal="notificaciones"
        >
          {notificaciones?.map((item) => {
            let icon = <i className="uil-file-info-alt"></i>
            // let icon = item?.idmodulos === 1 ? (
            //   <i className="uil-file-alt"></i>
            // ) : (
            //   <i className="uil-file-info-alt"></i>
            // );
            const module = () => {
              // if (item.idmodulos === 1) return 'contractual';
              if (item?.idmodulos === EPostModules.POSTCONTRACTUAL.nombre) return 'postcontractual';
              else return '';
            }
            return (
              <Link key={item?.idnotificacion || item?.idseguimiento} to={`/${module()}/edit/${item?.idproceso}`}
                onClick={() => handleShowNotification(item?.idseguimiento, item?.idproceso, item?.idmodulos)}>
                <NavbarDropdownItem
                  icon={icon}
                  // title={(item.idmodulo === EModulos.CONTRACTUAL) ? 'Contractual' : 'Postcontractual'}
                  title={item?.consecutivo}
                  description={item?.mensaje}
                />
              </Link>
            );
          })}
        </NavbarDropdown>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1 border-left">
          <DropdownToggle nav caret>
            <div className="mr-0 mr-md-3 ml-2">
              <i className="uil-user" style={{ fontSize: '1.2rem' }}></i>
            </div>
            <div className="d-none d-md-flex flex-column">
              <span>{userState.data?.firstname} {userState.data?.lastname}</span>
              <span className="role-profile">{t(rol)}</span>
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            <div className="d-flex flex-column d-md-none p-2 text-center">
              <span><strong>{userState.data?.firstname} {userState.data?.lastname}</strong></span>
              <span className="role-profile">{t(rol)}</span>
            </div>
            <DropdownItem
              onClick={() => {
                window.open('https://soporte.comforce.co/', '_blank');
              }}
            >
              <i className="uil-life-ring uil-mr"></i>
              {t('Soporte')}
            </DropdownItem>
            <DropdownItem
              onClick={handleContraseña}
            >
              <i className="uil-keyhole-circle uil-mr"></i>
              {t('Cambiar contraseña')}
            </DropdownItem>
            <DropdownItem
              onClick={logOut}
            >
              <i className="uil-mr uil-sign-out-alt"></i>
              {t('Salir')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
}

const NavbarComponent = ({ toggle, isOpen }) => {
  const { userState, userDispatch } = useUserContext();
  const { notificationState, notificationDispatch } = useNotificacionContext();

  const toggleSidebar = () => {
    toggle(!isOpen);
  };

  const listarNotificaciones = async () => {
    const noti = await obtenerNotificaciones(userState?.data?.sub, 1);
    notificationDispatch(updateNotifications(noti));
  }

  useEffect(() => {
    listarNotificaciones();
    // };
    const Interval = setInterval(() => {
      listarNotificaciones();
    }, 60000);
    return () => {
      // eventSource.close();
      clearInterval(Interval);
    }
  }, []);

  return (
    <Navbar expand className="navbar-theme">
      <NavbarToggle
        toggleSidebar={toggleSidebar}
      />
      <NavbarDropdowns />
    </Navbar>
  );
}
export default NavbarComponent;
