import React, { createContext, useReducer, FC, useState, useContext } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { closeModal } from './actions/mainActions';
import { mainContext } from './mainContext';


export const modalContext = createContext<IModalContext>({});

export const ModalProvider = ({ children }) => {
  const { mainState: state, mainDispatch: dispatch } = useContext(mainContext);
  const { modal } = state;
  const BodyModal: FC<any> = (modal?.body) ? modal.body : () => (<></>);

  return (
    <modalContext.Provider
      value={{
      }}
    >
      {children}
      <Modal
        isOpen={modal?.show}
        size={modal?.size || 'md'}
      // toggle={closeModal}
      >
        <ModalHeader toggle={() => dispatch(closeModal())}>
          {modal?.title}
        </ModalHeader>
        {modal?.body && <BodyModal />}
      </Modal>
    </modalContext.Provider>
  );
};

interface IModalContext {

}

export const useModalContext = () => {
  const context = React.useContext(modalContext)
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
};

