import React, { FC } from 'react';
import Iframe from 'react-iframe';
// Reactstrap
import {
  Row,
  Col,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';

const ReporteHistorialActividadesRol: FC =  () => {

  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <h1>{t('Reporte de Historial de Actividades Rol')}</h1>
        <Iframe
          url={'https://lookerstudio.google.com/embed/reporting/70d52777-4433-44bc-a35d-a9f415fed4ee/page/wv2kD'}
          width={'100%'}
          height={'900'}
          frameBorder={0}
          allowFullScreen={true}
          sandbox={['allow-storage-access-by-user-activation','allow-scripts','allow-same-origin','allow-popups','allow-popups-to-escape-sandbox','allow-downloads'] as any}
        >
          Debe habilitar los Iframes para poder ver éste contenido</Iframe>
        </Col>
    </Row>
  )
}

export default ReporteHistorialActividadesRol

