import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, Row } from "reactstrap";
import * as Yup from 'yup';
import { ITareas } from "../../../core/models/procesos/tareasModel";
import { tareasClienteFilterService } from "../../../core/services/tareas/tareasClienteService";
import ButtonCustom from "../../../shared/components/ButtonCustom";
import { falseLoading, openModal, trueLoading } from "../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../shared/contexts/mainContext";
import { FormValidation } from "../../../shared/utilities";
import { useHistory } from 'react-router-dom';
import FormRecuperar from '../tareasClientes/FormRecuperar';

const FormTareasClientesFilter: FC = () => {
  // Context
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const history = useHistory();

  // State
  const [msj, setMsj] = useState<string|null>(null);

  const handleHelp = () => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: t('Ayuda'),
        body: () => (
          <ModalBody>
            {t('Para cualquier inquietud comunícate con nosotros al tel {{phone}} o al correo {{email}}', 
            {
              phone: '3336025608',
              email: 'operaciones.fiduciaria@itau.co',
            })}
          </ModalBody>
        )
      }
    }))
  };

  const handleRecover = (type: number) => {
    mainDispatch(openModal({
      modal: {
        show: true,
        title: type === 2 ? t('Olvidé mi correo') : t('Olvidé mi código'),
        body: () => (
          <ModalBody>
            <FormRecuperar type={type} />
          </ModalBody>
        )
      }
    }))
  };

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailfideicomitente: '',
      numeronegocio: '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      emailfideicomitente: Yup.string().required(FormValidation.required),
      numeronegocio: Yup.string().required(FormValidation.required),
    })),
    onSubmit: async (values, { resetForm }) => {
      mainDispatch(trueLoading());
      let data: ITareas | null;
      data = await tareasClienteFilterService(values.emailfideicomitente, values.numeronegocio)
      if (data) {
        history.push('/tareas/clientes/filter', data )
        resetForm();
      }
      mainDispatch(falseLoading());
    }
  });

  const { errors, touched, handleSubmit } = formik;

  return (
    !!msj ?
      <Alert color="success" className="mb-0">{msj}</Alert>
    :
      <>
        <div>
          <Form id="FormTareasClientesFilter" onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Row>
                <Col sm={12} md={12} >
                  <FormGroup>
                    <Label for="emailfideicomitente">{t('Correo electrónico')}</Label>
                    <Field
                      as={Input}
                      name="emailfideicomitente"
                      invalid={errors.emailfideicomitente && touched.emailfideicomitente}
                      disabled={false}
                    />
                    <FormFeedback>{t(errors.emailfideicomitente as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Label for="numeronegocio">{t('Código del negocio')}</Label>
                    <Field
                      as={Input}
                      name="numeronegocio"
                      invalid={errors.numeronegocio && touched.numeronegocio}
                      disabled={false}
                    />
                    <FormFeedback>{t(errors.numeronegocio as string)}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <Button color="link" block onClick={() => handleRecover(2)}>
                    {t('Olvidé mi correo electrónico asociado')}
                  </Button>
                </Col>
                <Col sm={12} md={12}>
                  <Button color="link" block onClick={() => handleRecover(1)}>
                    {t('Olvidé mi código de negocio')}
                  </Button>
                </Col>
              </Row>
              <hr />
              <Button color="link" onClick={handleHelp}>
                {t('¿Necesitas ayuda?')}
              </Button>
              <ButtonCustom
                form="FormTareasClientesFilter"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {t('Continuar')}
              </ButtonCustom>
            </FormikProvider>
          </Form>
        </div>
      </>
  );
}

export default FormTareasClientesFilter;
