import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolMonetariaRestitucionAportes } from "../../../../core/models/tareas/tareaSolMonetariaRestitucionAportesModel";
import { tareaSolMonetariaRestitucionAportesListService, tareaSolMonetariaRestitucionAportesSaveService } from "../../../../core/services/tareas/tareaSolMonetariaRestitucionAportesService";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad7: FC<IFormEntidad7> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolMonetariaRestitucionAportes | null>(null);
  const obtenerData = async () => {
    const data = await tareaSolMonetariaRestitucionAportesListService({ _busqueda: { tarea: tarea?.id }, _registro_unico: false });
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      nombrefideicomitente: data?.nombrefideicomitente || '',
      tipoidfideicomitente: data?.tipoidfideicomitente || '',
      numeroidfideicomitente: data?.numeroidfideicomitente || '',
      valorrestitucion: data?.valorrestitucion || '',
      monedarestitucion: data?.monedarestitucion || '',
      mediopago: data?.mediopago || '',
      tipocuentadestino: data?.tipocuentadestino || '',
      entidadfinancieradestino: data?.entidadfinancieradestino || '',
      numerocuentadestino: data?.numerocuentadestino || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      nombrefideicomitente: Yup.string().required(FormValidation.required),
      tipoidfideicomitente: Yup.string(),
      numeroidfideicomitente: Yup.string(),
      valorrestitucion: Yup.string(),
      monedarestitucion: Yup.string(),
      mediopago: Yup.string(),
      tipocuentadestino: Yup.string(),
      entidadfinancieradestino: Yup.string(),
      numerocuentadestino: Yup.string(),
    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolMonetariaRestitucionAportesSaveService((values as ITareaSolMonetariaRestitucionAportes), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad7" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrefideicomitente">{t('Nombre del fideicomitente')}</Label>
                <Field
                  as={Input}
                  id="nombrefideicomitente"
                  name="nombrefideicomitente"
                  invalid={errors.nombrefideicomitente && touched.nombrefideicomitente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrefideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidfideicomitente">{t('Tipo Identificación del Fideicomitente')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidfideicomitente"
                  name="tipoidfideicomitente"
                  invalid={errors.tipoidfideicomitente && touched.tipoidfideicomitente}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidfideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidfideicomitente">{t('Número Identificación del Fideicomitente')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidfideicomitente"
                  name="numeroidfideicomitente"
                  invalid={errors.numeroidfideicomitente && touched.numeroidfideicomitente}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidfideicomitente as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="valorrestitucion">{t('Valor')}</Label>
                <Field
                  as={NumberField}
                  id="valorrestitucion"
                  name="valorrestitucion"
                  invalid={errors.valorrestitucion && touched.valorrestitucion}
                  disabled={!habilitar}
                  currency
                />
                <FormFeedback>{t(errors.valorrestitucion as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="monedarestitucion">{t('Moneda')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="monedarestitucion"
                  name="monedarestitucion"
                  invalid={errors.monedarestitucion && touched.monedarestitucion}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPOS_DE_MONEDA).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.monedarestitucion as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="mediopago">{t('Medio de pago')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="mediopago"
                  name="mediopago"
                  invalid={errors.mediopago && touched.mediopago}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.MEDIO_DE_PAGO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.mediopago as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocuentadestino">{t('Tipo de cuenta destino')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocuentadestino"
                  name="tipocuentadestino"
                  invalid={errors.tipocuentadestino && touched.tipocuentadestino}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_CUENTA_DESTINO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="entidadfinancieradestino">{t('Entidad Financiera Destino')}</Label>
                <Field
                  as={Input}
                  id="entidadfinancieradestino"
                  name="entidadfinancieradestino"
                  invalid={errors.entidadfinancieradestino && touched.entidadfinancieradestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.entidadfinancieradestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentadestino">{t('Número de cuenta destino')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentadestino"
                  name="numerocuentadestino"
                  invalid={errors.numerocuentadestino && touched.numerocuentadestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad7"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad7 {
  habilitar: boolean;
}

export default FormEntidad7;