import { Field, FormikProvider, useFormik } from "formik";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from 'yup';
import { EListaDesplegables } from "../../../../core/models/administracion/listasItauModels";
import { ITareaSolNoMonetariaDesistimientoProyectoInmobiliario } from "../../../../core/models/tareas/tareaSolNoMonetariaDesistimientoProyectoInmobiliarioModel";
import { tareaSolNoMonetariaDesistimientoProyectoInmobiliarioListService, tareaSolNoMonetariaDesistimientoProyectoInmobiliarioSaveService } from "../../../../core/services/tareas/tareaSolNoMonetariaDesistimientoProyectoInmobiliarioService";
import BooleanField from "../../../../shared/components/BooleanField";
import ButtonCustom from "../../../../shared/components/ButtonCustom";
import DateField from "../../../../shared/components/DateField";
import NumberField from "../../../../shared/components/NumberField";
import { falseLoading, trueLoading } from "../../../../shared/contexts/actions/mainActions";
import { useMainContext } from "../../../../shared/contexts/mainContext";
import { useTareasContext } from "../../../../shared/contexts/tareasContext";
import { useAllListas } from "../../../../shared/hooks/useAllListas";
import { FormValidation } from "../../../../shared/utilities";

const FormEntidad20: FC<IFormEntidad20> = ({ habilitar = false }) => {  // Context
  const { tarea, cambiarEstado, obtenerTarea } = useTareasContext();
  const { mainState, mainDispatch, handleNotification } = useMainContext();
  const { t } = useTranslation();
  const { obtenerLista } = useAllListas();

  // State
  const [data, setData] = useState<ITareaSolNoMonetariaDesistimientoProyectoInmobiliario|null>(null);
const obtenerData = async () => {
    const data = await tareaSolNoMonetariaDesistimientoProyectoInmobiliarioListService({_busqueda: {tarea: tarea?.id}, _registro_unico: false});
    if (data.length) {
      setData(data[0]);
    }
  };

  useEffect(() => {
    if (tarea && data === null) {
      obtenerData();
    }
  }, [tarea])

  // Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: data?.id || undefined,
      tarea: tarea?.id || 0,
      nombreproyecto: data?.nombreproyecto || '',
      inmuebledesistimiento: data?.inmuebledesistimiento || '',
      nombrecomprador: data?.nombrecomprador || '',
      tipoidcomprador: data?.tipoidcomprador || '',
      numeroidcomprador: data?.numeroidcomprador || '',
      tipocuentadestino: data?.tipocuentadestino || '',
      entidadfinancieradestino: data?.entidadfinancieradestino || '',
      numerocuentadestino: data?.numerocuentadestino || '',
    },
    validationSchema: Yup.lazy(() => Yup.object().shape({
      nombreproyecto: Yup.string().required(FormValidation.required),
      inmuebledesistimiento: Yup.string(),
      nombrecomprador: Yup.string(),
      tipoidcomprador: Yup.string(),
      numeroidcomprador: Yup.string(),
      tipocuentadestino: Yup.string(),
      entidadfinancieradestino: Yup.string(),
      numerocuentadestino: Yup.string(),

    })),
    onSubmit: async (values) => {
      mainDispatch(trueLoading());
      const data = await tareaSolNoMonetariaDesistimientoProyectoInmobiliarioSaveService((values as ITareaSolNoMonetariaDesistimientoProyectoInmobiliario), handleNotification);
      if (data?.id) {
        setData(data);
        await cambiarEstado(tarea?.id || 0);
        await obtenerTarea(tarea?.id || 0);
      }
      mainDispatch(falseLoading());
    }
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <>
      <Form id="FormEntidad20" onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Row>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombreproyecto">{t('Nombre del proyecto')}</Label>
                <Field
                  as={Input}
                  id="nombreproyecto"
                  name="nombreproyecto"
                  invalid={errors.nombreproyecto && touched.nombreproyecto}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombreproyecto as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="inmuebledesistimiento">{t('Inmueble(s) objeto del desistimiento')}</Label>
                <Field
                  as={Input}
                  id="inmuebledesistimiento"
                  name="inmuebledesistimiento"
                  invalid={errors.inmuebledesistimiento && touched.inmuebledesistimiento}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.inmuebledesistimiento as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="nombrecomprador">{t('Nombre del comprador')}</Label>
                <Field
                  as={Input}
                  id="nombrecomprador"
                  name="nombrecomprador"
                  invalid={errors.nombrecomprador && touched.nombrecomprador}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.nombrecomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipoidcomprador">{t('Tipo Identificación')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipoidcomprador"
                  name="tipoidcomprador"
                  invalid={errors.tipoidcomprador && touched.tipoidcomprador}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_IDENTIFICACION).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipoidcomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numeroidcomprador">{t('Número Identificación')}</Label>
                <Field
                  as={NumberField}
                  id="numeroidcomprador"
                  name="numeroidcomprador"
                  invalid={errors.numeroidcomprador && touched.numeroidcomprador}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numeroidcomprador as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="tipocuentadestino">{t('Tipo de cuenta destino')}</Label>
                <Field
                  as={Input}
                  type="select"
                  id="tipocuentadestino"
                  name="tipocuentadestino"
                  invalid={errors.tipocuentadestino && touched.tipocuentadestino}
                  disabled={!habilitar}
                >
                  <option value="">{t('Seleccione')}</option>
                  {
                    obtenerLista(EListaDesplegables.TIPO_CUENTA_DESTINO).map(({ itemlista, nombreitem }, index) => (
                      <option key={index} value={itemlista}>{t(nombreitem)}</option>
                    ))
                  }
                </Field>
                <FormFeedback>{t(errors.tipocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="entidadfinancieradestino">{t('Entidad Financiera Destino')}</Label>
                <Field
                  as={Input}
                  id="entidadfinancieradestino"
                  name="entidadfinancieradestino"
                  invalid={errors.entidadfinancieradestino && touched.entidadfinancieradestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.entidadfinancieradestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={12} md={4}>
              <FormGroup>
                <Label for="numerocuentadestino">{t('Número de cuenta destino')}</Label>
                <Field
                  as={NumberField}
                  id="numerocuentadestino"
                  name="numerocuentadestino"
                  invalid={errors.numerocuentadestino && touched.numerocuentadestino}
                  disabled={!habilitar}
                />
                <FormFeedback>{t(errors.numerocuentadestino as string)}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          {habilitar &&
            <div className="clearfix">
              <hr />
              <ButtonCustom
                form="FormEntidad20"
                type="submit"
                color="primary"
                className="float-right"
                loading={mainState.loading}
                disabled={mainState.loading}
              >
                {data ? t('Actualizar datos') : t('Guardar datos')}
              </ButtonCustom>
            </div>
          }
        </FormikProvider>
      </Form>
    </>
  );
}

interface IFormEntidad20 {
  habilitar: boolean;
}

export default FormEntidad20;